import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";

import "./style.css";
import EmissionComments from "../../../chats/EmissionComments";
import { findAllByAltText } from "@testing-library/react";

function EmissDisaggreType({ findOneData }) {
  return (
    <div className="emisDisggCon">
      <div className="directOwned">
        <div className="directOwnedRow">
          <div className="directOwnedCol1">
            <h1>Scope 1: Direct Emissions from Owned/Controlled Operations</h1>
          </div>
          <div className="addCmtRow">
            <EmissionComments
              className="addCmtRow"
              mapping="infoOnEmission2.directEmission"
              issueId={
                findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                  .issueId
              }
              declarationId={findOneData?.data?.declaration?._id}
              secName="INFORMATION ON EMISSIONS 2"
              question="Scope 1: Direct Emissions from Owned/Controlled Operations"
            />
          </div>
        </div>
        <div className="infoEmisRow">
          <div className="infoEmis">
            <div>
              <h1>Direct Emissions from Stationary Combustion</h1>
              <p>Not Declared</p>
            </div>
            <div>
              <h1>Reason</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur. Viverra consequat at
                proin tincidunt. Ut nulla vitae eget tincidunt donec. Viverra
                consequat at proin tincidunt.
              </p>
            </div>
          </div>
          <div className="infoEmis">
            <h1>Direct Emissions from Mobile Combustion</h1>
            <p>123445</p>
          </div>
          <div className="infoEmis">
            <div>
              <h1>Direct Emissions from Stationary Combustion</h1>
              <p>Not Declared</p>
            </div>
            <div>
              <h1>Reason</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur. Viverra consequat at
                proin tincidunt. Ut nulla vitae eget tincidunt donec. Viverra
                consequat at proin tincidunt.
              </p>
            </div>
          </div>
          <div className="directEmisRow">
            <div className="infoEmis wd-50">
              <h1>Direct Emissions from Process Sources</h1>
              <p>123445</p>
            </div>
            <div className="infoEmis wd-50">
              <h1>Direct Emissions from Fugitive Sources</h1>
              <p>123445</p>
            </div>
          </div>
        </div>
      </div>

      <div className="directOwned">
        <div className="directOwnedRow">
          <div className="scopeCooling1">
            <h1>
              Scope 2: Indirect Emissions from the Use of Purchased Electricity,
              Steam, Heating & Cooling
            </h1>
          </div>
          <EmissionComments
            className="addCmtRow"
            mapping="infoOnEmission2.indirectEmission"
            issueId={
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                .issueId
            }
            declarationId={findOneData?.data?.declaration?._id}
            secName="INFORMATION ON EMISSIONS 2"
            question="Scope 2: Indirect Emissions from the Use of Purchased Electricity, Steam, Heating & Cooling"
          />
        </div>
        <div className="infoEmisRow">
          <div className="infoEmis">
            <div>
              <h1>Direct Emissions from Stationary Combustion</h1>
              <p>Not Declared</p>
            </div>
            <div>
              <h1>Reason</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur. Viverra consequat at
                proin tincidunt. Ut nulla vitae eget tincidunt donec. Viverra
                consequat at proin tincidunt.
              </p>
            </div>
          </div>
          <div className="directEmisRow">
            <div className="infoEmis wd-50">
              <h1>Direct Emissions from Process Sources</h1>
              <p>123445</p>
            </div>
            <div className="infoEmis wd-50">
              <h1>Direct Emissions from Fugitive Sources</h1>
              <p>123445</p>
            </div>
          </div>
          <div className="infoEmis">
            <h1>Direct Emissions from Stationary Combustion</h1>
            <p>123445</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmissDisaggreType;
