import React from 'react';

import "./style.css";

import AppleIcon from "../../../assets/icons/appleIcon.svg";
import GoogleIcon from "../../../assets/icons/googleComIcon.svg";
import MicrosoftIcon from "../../../assets/icons/microsoftComIcon.svg";
import AtlassianIcon from "../../../assets/icons/atlassianComIcon.svg";
import PayPalIcon from "../../../assets/icons/payPalIcon.svg";
import Airtable from "../../../assets/icons/airtableComIcon.svg";
import Tesla from "../../../assets/icons/teslaComIcon.svg";


const netEmissionData = [
    {
      image: AppleIcon,
      companyName: "Apple",
      netEmissions: "EEE+"
    },
    {
      image: GoogleIcon,
      companyName: "Google Inc.",
      netEmissions: "EEE+"
    },
    {
      image: MicrosoftIcon,
      companyName: "Microsoft Inc.",
      netEmissions: "EEE"
    },
    {
      image: AtlassianIcon,
      companyName: "Atlassian",
      netEmissions: "EEE"
    },
    {
      image: PayPalIcon,
      companyName: "PayPal Inc.",
      netEmissions: "EEE"
    },
    {
      image: Airtable,
      companyName: "Airtable",
      netEmissions: "EEE"
    },
    {
      image: Tesla,
      companyName: "Tesla",
      netEmissions: "EEE-"
    },
]

function BestRatedBI() {
  return (
    <div className='userProgressCard'>
        <span>Best Rated B&Is</span>

        <div className='netEmissionSection'>
            <p className='netEmissTitle'>B&I</p>
            <p className='netEmissTitle'>Net Emissions</p>
        </div>
       {netEmissionData?.map((item) =>(
        <div className='netEmissionSection'>
            <div className='companyDataRow'>
                <img src={item.image} alt='companyLogo'/>
                <p>{item.companyName}</p>
            </div>
            <h1>{item.netEmissions}</h1>
        </div>
        )) }

    </div>
  )
}

export default BestRatedBI;