import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useLoginMutation } from "../../../features/auth/authApiSlice";
import { loginHandler } from "../../../features/auth/authActions";
import { setUserHandler } from "../../../features/user/userAction";

import InputBox from "../../../components/InputBox/InputBox";
import FormButton from "../../../components/button/FormButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import { showError } from "../../../features/snackbar/snackbarAction";
import { authActions } from "../../../features/auth/authSlice";
import PageLoader from "../../../components/Loader/PageLoader";

const loginSchema = Yup.object({
  email: Yup.string()
    .required("Required")
    .trim()
    .lowercase()
    .min(5, "Enter valid email id")
    .test(
      "valid-email",
      "Incorrect Email ID. Please check your email ID",
      (value) => {
        if (!value) return false;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(value);
      }
    ),
  password: Yup.string()
    .min(8, "Password must include one capital alphabet and 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),
});

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [
    login,
    {
      data: loginData,
      isLoading: loginIsLoading,
      isSuccess: loginIsSuccess,
      error: loginError,
      isError: loginIsError,
    },
  ] = useLoginMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: loginSchema,

    onSubmit: (values) => {
      console.log("Inside Submit", values);
      login(values)
        .unwrap()
        .then((res) => {
          console.log("responseFromLogin", res);
          dispatch(
            loginHandler({
              accessToken: res?.data?.authToken,
              refreshToken: "",
            })
          );
          dispatch(
            setUserHandler({
              userType: res?.data?.user?.userType,
              userId: res?.data?.user?._id,
              authToken: res?.data?.authToken,
              userName: res?.data?.user?.fullName,
            })
          );
          if (res?.data?.user?.userType === "admin") {
            navigate("/adminDashboard");
          } else {
            navigate("/dashboard");
          }
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    },
  });

  // useEffect(() => {
  //   if (loginIsError) {
  //     if (loginError?.data?.message) {
  //       console.log(loginError?.data?.message, "loginIsError?.message");
  //       alert("Wrong Credentials")
  //     } else {
  //       // dispatch(showError({ message: "Oops! Failed to create customer. Please try again." }));
  //     }
  //   }

  //   if (loginIsSuccess) {
  //     const {
  //       data: {
  //         user: { _id : userId, email, fullName: userName, userType },
  //         token: accessToken
  //       }
  //     } = loginData;
  //     dispatch(loginHandler({ accessToken, refreshToken: "" }));
  //     dispatch(setUserHandler({ userId, email, userName, userType }));
  //     navigate("/dashboard", { replace: true });
  //   }
  // }, [loginIsError, loginIsSuccess, dispatch, loginData, navigate, loginError]);

  const signUpFuc = () => {
    navigate("/auth/signup");
  };
  const dashboardFunc = () => {
    navigate("/dashboard");
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const forgotPassFunc = () => {
    navigate("/auth/resetPasswordLink");
  };

  // console.log({
  //   isSuccess : loginIsSuccess,
  //   loginIsError: loginIsError,
  //   loginError : loginError
  // }, "LoginApiResponse")

  return (
    <div className="container login_section">
      {loginIsLoading && <PageLoader />}
      <div className="loginSec_Col1">
        <img className="login_topBg" src={SignUpTopBg} alt="" />
        <img className="login_bottomBg" src={SignUpBottomBg} alt="" />
        <img className="login_leftBg" src={SignUpLeftBg} alt="" />
        <div className="login_logoDiv">
          <img src={RegistrationLogo} alt="" />
        </div>
        <div className="login_divContents">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>
        <div className="loginCopy">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>
      <div className="login_Col2">
        <h1>Log In</h1>
        <p>Login in to your account and help bring an impact</p>
        <form className="login_form" noValidate onSubmit={formik.handleSubmit}>
          <div className="login_fullname">
            <InputBox
              headName={"Email"}
              placeholder="Enter your email here"
              required
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={!!formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="signIn_password">
            <div className="relativePosition">
              <InputBox
                headName={"Password"}
                placeholder="Password"
                required
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                value={formik.values.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.password && formik.errors.password}
              />
              <div className="login_Eyeicon_position">
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                  onClick={() => togglePasswordVisibility()}
                  className="login_Eye_icon eye_color"
                />
              </div>
            </div>
          </div>
          <div className="cursorPointer">
            <FormButton
              buttonName={"Log In"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
          <div className="alreadyHave cursorPointer">
            <p>
              Don’t have an account? <span onClick={signUpFuc}> Sign Up</span>
            </p>
            <p
              className="forgotPassword_text cursorPointer"
              onClick={forgotPassFunc}
            >
              Forgot Password?
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
