import React from 'react'
import FormButton from '../../../components/button/FormButton'
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyFailure = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const identifier = searchParams.get("identifier");
    const userType = searchParams.get("userType");
    const email = searchParams.get("email");
    const fullName = searchParams.get("fullName");

    const handleRedirectBackToOtpVerifyScreen = ()=>{
        navigate({    
            pathname: "/auth/otpVerify",
            search : `?identifier=${identifier}&userType=${userType}&email=${email}&fullName=${fullName}`
        });
    }
  return (
    <div className="container signUp_section">
    <div className="signSec_Col1">
      <img className="signUp_topBg" src={SignUpTopBg} />
      <img className="signUp_bottomBg" src={SignUpBottomBg} />
      <img className="signUp_leftBg" src={SignUpLeftBg} />
      <div className="signUp_logoDiv">
        <img src={RegistrationLogo} />
      </div>
      <div className="signUp_divContents">
        <h1>Let’s bring an impact! </h1>
        <h2>Welcome to SeeRating!</h2>
        <p className="signUp_leftContent">
          We are looking for business and industry that have taken action to
          declare their GHG emissions, set science-based net zero targets and
          development and implemented practical action plans to achieve these
          goals. Please fill in further details about yourself and the
          organization that you are representing.
        </p>
      </div>

      <div className="copyRight_div">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
      </div>
    </div>

    <div className="signSec_Col2">
        <div className="verifiFailedSec">
          <h1>Uh oh! Verification Failed</h1>
          <p>
            Looks like something went wrong while verifying your email ID.
            Please retry verification to proceed
          </p>
          <div
            className="cursorPointer verifiOtpBtn"
            onClick={handleRedirectBackToOtpVerifyScreen}
          >
            <FormButton
              buttonName={"Retry Verification"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </div>
    </div>
  </div>
  )
}

export default VerifyFailure