import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import EmissionComments from "../../../chats/EmissionComments";

function AuditAdditionalInfo({ findOneData }) {
  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>ADDITIONAL INFORMATION</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditAddContracContainer">
            <div>
              <div className="auditAddContractRow">
                <div className="auditAddContractCol1">
                  <h1>
                    Information on any contractual provisions addressing
                    GHG-related risks and obligations
                  </h1>
                </div>
                <div className="auditAddContractCol2">
                  <p>
                    {findOneData?.data?.declaration?.additionalInfo
                      ?.ghgContractualProvisionsInfo?.isApplicable
                      ? findOneData?.data.declaration?.additionalInfo
                          ?.ghgContractualProvisionsInfo?.specify
                      : "Not Applicable"}
                  </p>
                </div>
              </div>
              <div className="addCmtRow">
                <EmissionComments
                  className="addCmtRow"
                  mapping="additionalInfo.ghgContractualProvisionsInfo"
                  secName={"Additional Information"}
                  question={
                    "Information on any contractual provisions addressing GHG-related risks and obligations"
                  }
                  answer={
                    findOneData?.data?.declaration?.additionalInfo
                      ?.ghgContractualProvisionsInfo?.isApplicable
                      ? findOneData?.data.declaration?.additionalInfo
                          ?.ghgContractualProvisionsInfo?.specify
                      : "Not Applicable"
                  }
                  issueId={
                    findOneData?.data?.declaration?.additionalInfo
                      ?.ghgContractualProvisionsInfo?.issueId
                  }
                  declarationId={findOneData?.data?.declaration?._id}
                />
              </div>
            </div>
            <div>
              <div className="auditAddContractRow">
                <div className="auditAddContractCol1">
                  <h1>Information on any GHG sequestration</h1>
                </div>
                <div className="auditAddContractCol2">
                  <p>
                    {findOneData?.data?.declaration?.additionalInfo
                      ?.ghgSequestrationInfo?.isApplicable
                      ? findOneData?.data?.declaration?.additionalInfo
                          ?.ghgSequestrationInfo?.specify
                      : "Not Applicable"}
                  </p>
                </div>
              </div>
              <div className="addCmtRow">
                <EmissionComments
                  className="addCmtRow"
                  mapping="additionalInfo.ghgSequestrationInfo"
                  secName={"Additional Information"}
                  question={"Information on any GHG sequestration"}
                  answer={
                    findOneData?.data?.declaration?.additionalInfo
                      ?.ghgSequestrationInfo?.isApplicable
                      ? findOneData?.data?.declaration?.additionalInfo
                          ?.ghgSequestrationInfo?.specify
                      : "Not Applicable"
                  }
                  issueId={
                    findOneData?.data?.declaration?.additionalInfo
                      ?.ghgSequestrationInfo?.issueId
                  }
                  declarationId={findOneData?.data?.declaration?._id}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditAdditionalInfo;
