import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import About from "../home/homeComponents/aboutUs/About";
import OurAim from "../home/homeComponents/ourAim/OurAim";
import CompanyLogo from "../home/homeComponents/companyLogo/CompanyLogo";
import SituationToday from "../home/homeComponents/situationToday/SituationToday";
import WhereAreWe from "../home/homeComponents/whereAreWe/WhereAreWe";
import ClimateImpact from "../home/homeComponents/climateImpact/ClimateImpact";
import Emission from "../home/homeComponents/emission/Emission";
import FrameWork from "../home/homeComponents/frameWork/FrameWork";
import Rating from "../home/homeComponents/rating/Rating";
import WhoIs from "../home/homeComponents/whoIsIt/WhoIs";
import OurProcess from "../home/homeComponents/ourProcess/OurProcess";
import EEEBuilding from "../home/homeComponents/eBuilding/EEEBuilding";
import Chairman from "../home/homeComponents/chairman/Chairman";
import GetInTouch from "../home/homeComponents/getInTouch/GetInTouch";

import "./style.css";

import empower_img from "../../assets/emissionBgNew.png";
import empowerVideo from "../../assets/video/video_of_windmills.mp4";
import videoRightImg from "../../assets/homeImages/videoRightImg.svg";
import videoBottomImg from "../../assets/homeImages/videoBottomImg.svg";

function Home() {
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);

  // for scrolling
  const aboutSectionRef = useRef(null);
  const whereWeAreSectionRef = useRef(null);
  const whoIsSectionRef = useRef(null);
  const ourProcessSectionRef = useRef(null);
  const chairmanSectionRef = useRef(null);
  const contactSectionRef = useRef(null);

  const signUpFunc = () => {
    navigate("/auth/signup");
  };

  const APIkey = "5b1ac3354b554852a3cef7f8477b4d97"

  function getLocationInfo(latitude, longitude) {
    const url =`https://api.geoapify.com/v1/geocode/reverse?lat=${latitude}&lon=${longitude}&apiKey=${APIkey}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
          console.log("Country : ", data?.features[0]?.properties?.country);
          setLocation(data?.features[0]?.properties?.country);
      })
      .catch((error) => console.error("error", error));
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    getLocationInfo(crd.latitude, crd.longitude);
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);

    getLocationInfo(crd.latitude, crd.longitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div>
      <Header
        aboutSectionRef={aboutSectionRef}
        whereWeAreSectionRef={whereWeAreSectionRef}
        whoIsSectionRef={whoIsSectionRef}
        ourProcessSectionRef={ourProcessSectionRef}
        chairmanSectionRef={chairmanSectionRef}
        contactSectionRef={contactSectionRef}
      />
      <div className="container mobTopContainer">
        <div className="empowerSection">
          <div style={{ display: "flex", height: "100%" }}>
            <img
              src={videoRightImg}
              alt="videoRightImg"
              className="videoRightImg"
            />
            <img
              src={videoBottomImg}
              alt="videoBottomImg"
              className="videoBottomImg"
            />
            <video
              src={empowerVideo}
              // controls
              autoPlay
              loop
              muted
              className="empowerVideo"
            ></video>
          </div>

          <div className="empowerContent">
            <h1>
              Rewarding Your
              <br /> Journey To Net Zero
            </h1>
            <p className="DemostrateText">
              Demonstrate the impact of your actions to achieve net zero, all
              enabled by Artificial Intelligence and the power of Blockchain
              Tecnhology for a seamless journey.
            </p>
            <div className="getStart_btn" onClick={signUpFunc}>
              <p>Get Started!</p>
            </div>
          </div>
        </div>
      </div>

      <CompanyLogo />
      <About aboutSectionRef={aboutSectionRef} />
      <OurAim />
      <SituationToday whereWeAreSectionRef={whereWeAreSectionRef} />
      <ClimateImpact />
      <Emission />
      <FrameWork />
      <Rating />
      <WhoIs whoIsSectionRef={whoIsSectionRef} />
      <OurProcess ourProcessSectionRef={ourProcessSectionRef} />
      <EEEBuilding />
      <Chairman chairmanSectionRef={chairmanSectionRef} />
      <GetInTouch contactSectionRef={contactSectionRef} />
      <div>
        <Footer
          aboutSectionRef={aboutSectionRef}
          whoIsSectionRef={whoIsSectionRef}
          ourProcessSectionRef={ourProcessSectionRef}
          chairmanSectionRef={chairmanSectionRef}
          contactSectionRef={contactSectionRef}
        />
      </div>
    </div>
  );
}

export default Home;
