import React from 'react';

import "./style.css";

import ImgUploadIcon from "../../../assets/images/uploadImg.svg";

function ImgUploadBtn() {
  return (
    <div className='imgUploadCon cursorPointer'>
      <img src={ImgUploadIcon} alt='ImgUploadIcon' />
      <p>Upload Image</p>
    </div>
  )
}

export default ImgUploadBtn;