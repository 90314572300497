import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutHandler } from "../../features/auth/authActions";
import { getAuthFromLocal } from "../../utils/storage";

const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:3000/dev",
  // baseUrl: "https://0e55-125-63-112-150.ngrok-free.app/dev",
  baseUrl: process.env.REACT_APP_BASE_URL,

  prepareHeaders: (headers, { getState }) => {
    const token =
      getState().auth.accessToken || getAuthFromLocal()?.accessToken;
    token && headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(logoutHandler());
  }

  return result;
};

const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    // "Categories",
    // "SubCategories",
    // "Products",
    "Dashboard",
    "Admin",
    "PendingUserListCount",
    "Issues",
    "Chats",
    "findOne",
    "stageInfo",
    "dashBoardStatus",
    "AdminFindOne",
    "AdminFindAll",
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
