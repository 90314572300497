import { useState } from "react";
import "./style.css";

import TimerIcon from "../../assets/icons/Timer.svg";
import RightCheck from "../../assets/icons/Check.svg";
import AddIcon from "../../assets/icons/AddPlusBtn.svg";

import { useNavigate } from "react-router-dom";
import StatusTab from "../tabs/StatusTab";
import ContainedButton from "../button/ContainedButton";
import AddConsultantAndAuditorPopUp from "../PopUp/consAuditorPopUp.js/AddConsultantAndAuditorPopUp";
import DeclerationTimeline from "../dashTimeline/DeclerationTimline";
import ViewAllDetailsBtn from "../button/dashboardBtn/ViewAllDetailsBtn";
import OutlinedButton from "../button/OutlinedButton";

function AddConsultantAndAuditor2({ status, stageData }) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Add Consultant");
  const navigate = useNavigate();

  const handleAddConsultantPopUpOpen = () => {
    setPopupTitle("Add Consultant");
    setPopUpOpen(true);
  };

  const handleAddAuditorPopUpOpen = () => {
    setPopupTitle("Add Auditor");
    setPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setPopUpOpen(false);
  };

  return (
    <div className="orgDashTimeContainer">
      <div className="orgRatingStatus">
        <div className="orgRatingCo1">
          <div className="updateBtnSec">
            {(() => {
              if (status?.data?.organization?.organizationStatus === "active") {
                return <p>Manage You Team</p>;
              } else if (
                status?.data?.organization?.organizationStatus === "registered"
              ) {
                return (
                  <>
                    <p>Account Verification Status</p>
                    <StatusTab text="Update Required" type="pending" />
                  </>
                );
              } else if (
                status?.data?.organization?.organizationStatus === "rejected"
              ) {
                return (
                  <>
                    <p>Account Verification Status</p>
                    <StatusTab text="Rejected" type="rejected" />
                  </>
                );
              }
              return null;
            })()}
          </div>

          {status?.data?.organization?.organizationStatus === "active" && (
            <p className="orgDeclarText">
              {status?.data?.consultant?.inviteUserStatus === "sent" &&
              status?.data?.auditor?.inviteUserStatus === "sent"
                ? "Your consultant and auditor are yet to register themselves on the platform. Please wait for some time or contact them to know more!"
                : status?.data?.consultant?.inviteUserStatus === "sent" &&
                    status?.data?.auditor?.inviteUserStatus === "Not Invited"
                  ? "Your Consultant is yet to register themselves on the platform and please add your auditor. Please wait for some time or contact them to know more!"
                  : status?.data?.auditor?.inviteUserStatus === "sent" &&
                      status?.data?.consultant?.inviteUserStatus ===
                        "Not Invited"
                    ? "Your Auditor is yet to register themselves on the platform and please add your consultant. Please wait for some time or contact them to know more!"
                    : status?.data?.auditor?.inviteUserStatus ===
                          "Not Invited" &&
                        status?.data?.consultant?.inviteUserStatus ===
                          "Not Invited"
                      ? "You have not added any consultant or auditor yet. Get started with your rating by adding them now!"
                      : ""}
            </p>
          )}
          {(status?.data?.organization?.organizationStatus === "registered" ||
            status?.data?.organization?.organizationStatus === "rejected") && (
            <p className="orgDeclarText">
              {status?.data?.organization?.organizationStatus === "registered"
                ? "Your profile is yet to be verified by the admin. Please check back in some time."
                : status?.data?.organization?.organizationStatus === "rejected"
                  ? "Uh oh! You’re verification was rejected by the admin. Please try uploading your details again."
                  : ""}
            </p>
          )}
        </div>

        {status?.data?.organization?.organizationStatus === "rejected" && (
          <div className="orgRatingCo2">
            <ContainedButton
              text="Register Again"
              bgColor="bgBlue"
              type="button"
            />
          </div>
        )}

        {status?.data?.organization?.organizationStatus === "active" && (
          <div className="orgRatingCo2">
            {status?.data?.auditor?.inviteUserStatus === "Not Invited" && (
              <div>
                <ContainedButton
                  text="Add Auditor"
                  bgColor="bgBlue"
                  leftIcon={AddIcon}
                  type="button"
                  onClick={handleAddAuditorPopUpOpen}
                />
              </div>
            )}
            {status?.data?.consultant?.inviteUserStatus === "Not Invited" && (
              <div>
                <ContainedButton
                  text="Add Consultant"
                  bgColor="bgBlue"
                  leftIcon={AddIcon}
                  type="button"
                  onClick={handleAddConsultantPopUpOpen}
                />
              </div>
            )}
            {status?.data?.consultant?.inviteUserStatus === "sent" &&
              status?.data?.auditor?.inviteUserStatus === "sent" && (
                <div>
                  <ContainedButton
                    text="Send Reminder"
                    bgColor="bgBlack"
                    leftIcon={TimerIcon}
                    type="button"
                  />
                </div>
              )}
          </div>
        )}
      </div>

      {status?.data?.organization?.organizationStatus === "active" && (
        <div className="regDoneSec">
          {status?.data?.consultant?.inviteUserStatus === "accepted" ? (
            <div className="conReg">
              <img src={RightCheck} />
              <p className="conRegText">Consultant Registration</p>
            </div>
          ) : status?.data?.consultant?.inviteUserStatus === "sent" ? (
            <div className="audReg">
              <img src={TimerIcon} />
              <p className="audRegText">Consultant Registration</p>
            </div>
          ) : null}
          {status?.data?.auditor?.inviteUserStatus === "accepted" ? (
            <div className="conReg">
              <img src={RightCheck} />
              <p className="conRegText">Auditor Registration</p>
            </div>
          ) : status?.data?.auditor?.inviteUserStatus === "sent" ? (
            <div className="audReg">
              <img src={TimerIcon} />
              <p className="audRegText">Auditor Registration</p>
            </div>
          ) : null}
        </div>
      )}

      <AddConsultantAndAuditorPopUp
        open={popUpOpen}
        onClose={handlePopUpClose}
        title={popupTitle}
      />
    </div>
  );
}

export default AddConsultantAndAuditor2;
