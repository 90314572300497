import React from 'react';

import "./style.css"

function NewCommentsBtn({ btnTitle }) {
  return (
    <div className='issueBtn cursorPointer'>
      <p>{btnTitle}</p>
    </div>
  )
}

export default NewCommentsBtn;