import React from 'react';

import SelectInputBox, { SelectCountryInputBox, SelectStateInputBox } from '../../InputBox/selectBox/SelectInputBox';
import InputBox from '../../InputBox/InputBox';

import PhoneInputBox from '../../InputBox/phoneInputBox/PhoneInputBox';

import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import ImgUploadIcon from "../../../assets/images/uploadImg.svg";
import ImgUploadBtn from '../../button/imgUploadBtn/ImgUploadBtn';


const countryStateMasterData = {
    India: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
    UAE: [
      "Abu Dhabi",
      "Ajman",
      "Dubai",
      "Fujairah",
      "Ras Al Khaimah",
      "Sharjah",
      "Umm Al Quwain",
    ],
    "Saudi Arabia": [
      "'Asir",
      "Al Bahah",
      "Al Jawf",
      "Al Madinah",
      "Al Qasim",
      "Eastern Province",
      "Ha'il",
      "Jazan",
      "Mecca",
      "Najran",
      "Northern Borders",
      "Riyadh",
      "Tabuk",
    ],
  };

const titleList = [
    { value: "Mr", name: "Mr." },
    { value: "Mrs", name: "Mrs." },
    { value: "Ms", name: "Ms." },
  ];

function AdminBasicDetails({formik}) {
    
    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      };

  return (
    <div className='adminBasicCon'>    
        <h1>Basic Information</h1>
        <div className='adminImgUpload'>
          <div className='imgUploadBox'>
            <img src={ImgUploadIcon} alt='ImgUploadIcon' />
          </div>
          <div className='imgUploadSec'>
            <ImgUploadBtn />
            <div className='imgFormate'>
              <span>Image formats allowed are .jpeg and .jpg</span>
              <div className='rightBorder'></div>
              <span>Image size must be less than 1Mb</span>
            </div>
          </div>
        </div>

        <div className='adminHorizontalLine'/>

        <div className='adminBasicInfoSec'>
        <form
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={onKeyDown}
            className='adminBsForm'
          >
            <div className="adminBsInputRow">
              <div className="adminBsTitle">
                <SelectInputBox
                  data={titleList}
                  headName={"Title"}
                  selectPlaceholder={"Select"}
                  name="title"
                  value={formik?.values?.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.title && formik.errors.title}
                  required
                />
              </div>
              <div className="adminBsFirstN">
                <InputBox
                  headName={"First Name"}
                  placeholder="Please Enter your first name"
                  name="firstName"
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.firstName && formik.errors.firstName}
                  required
                />
              </div>
              <div className="adminBsLastN">
                <InputBox
                  headName={"Last Name"}
                  placeholder="Please Enter your last name"
                  name="lastName"
                  value={formik.values.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.lastName && formik.errors.lastName}
                  required
                />
              </div>
            </div>

            <div className="adminBsInputRow">
              <div className="adminBsInpCols">
                <InputBox
                  headName={"Email ID"}
                  placeholder="Enter your email here"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.email && formik.errors.email}
                  required
                  readOnly
                />
              </div>
              <div className="adminBsInpCols">
                <PhoneInputBox
                  mobileNo={true}
                  headName={"Office Number"}
                  placeholder="Office Number here"
                  name="phoneNo"
                  value={formik.values.phoneNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.phoneNo && formik.errors.phoneNo}
                  countryCodeName={"phoneNoCountryCode"}
                  countryCodeValue={formik?.values?.phoneNoCountryCode}
                  defaultCountry="AE"
                  formik={formik}
                />
              </div>
              <div className="adminBsInpCols">
                <PhoneInputBox
                  mobileNo={true}
                  headName={"Mobile Number"}
                  placeholder="Mobile Number here"
                  name="mobileNo"
                  value={formik.values.mobileNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.mobileNo && formik.errors.mobileNo}
                  countryCodeName={"mobileNoCountryCode"}
                  countryCodeValue={formik?.values?.mobileNoCountryCode}
                  countryCodeError={
                    !!formik.touched.mobileNoCountryCode &&
                    formik.errors.mobileNoCountryCode
                  }
                  defaultCountry="AE"
                  formik={formik}
                  required
                />
              </div>
            </div>

            <div className='adminHorizontalLine'/>

            <div className="adminBsInputRow">
              <div className="adminBsAddress">
                <InputBox
                  headName={"Your Address"}
                  placeholder="Enter your address here"
                  name="address.fullAddress"
                  value={formik.values?.address?.fullAddress}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.fullAddress &&
                    formik.errors.address?.fullAddress
                  }
                  required
                />
              </div>
            </div>

             <div className="adminBsInputRow">
             <div className="adminBsInpCols">
                <InputBox
                  headName={"City / Town / Suburb"}
                  placeholder="Enter City / Town / Suburb"
                  name="address.city"
                  value={formik.values?.address?.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.city &&
                    formik.errors.address?.city
                  }
                  required
                />
              </div>
              <div className="adminBsInpCols">
                <SelectCountryInputBox
                  headName={"Country"}
                  selectPlaceholder={"Country"}
                  data={countryStateMasterData}
                  name="address.country"
                  value={formik?.values?.address?.country}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik?.handleChange(e);
                    formik.setFieldValue("address.state", "");
                    formik.setFieldValue("address.zipCode", "");
                  }}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.country &&
                    formik.errors.address?.country
                  }
                  required
                />
              </div>
              <div className="adminBsInpCols">
                <SelectStateInputBox
                  headName={"State / Region"}
                  selectPlaceholder={"Select"}
                  data={countryStateMasterData}
                  selectedData={formik?.values?.address?.country}
                  name="address.state"
                  value={formik?.values?.address?.state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.state &&
                    formik.errors.address?.state
                  }
                  required
                />
              </div>
              
            </div>
            <div className="adminBsInputRow">
             <div className="adminBsInpCols">
                <InputBox
                  headName={"Zipcode / Postal Code"}
                  placeholder=" Enter zipcode / postal code"
                  name="address.zipCode"
                  value={formik?.values?.address?.zipCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.zipCode &&
                    formik.errors.address?.zipCode
                  }
                  required
                />
              </div>
            </div>
            <div>
            </div>
          </form>
        </div>
    </div>
  )
}

export default AdminBasicDetails;