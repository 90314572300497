import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import "./style.css";

function LeadAndSupportPreview({value}) {
  return (
    <div className="supportPreCon">
      <h1 className="supportTitle">Lead & Support (Internal)</h1>
      <div className="supportPreRowCon">
        <div className="supportPreRow">
          <div className="supportPreCol1">
            <h2>Lead</h2>
          </div>
          <div className="supportPreCol2">
            <p>{value?.leadContactType}</p>
            <p>Name: {value?.leadName}</p>
            <p>Email: {value?.leadEmail}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          <ViewCommentsBtn
            secName={"Lead & Support (Internal)"}
            question={"Lead"}
            answer={"Lead Person"}
          />
        </div>
      </div>
      <div className="supportPreRowCon">
        <div className="supportPreRow">
          <div className="supportPreCol1">
            <h2>Support</h2>
          </div>
          <div className="supportPreCol2">
            <p>{value?.supportContactType}</p>
            <p>Name: {value?.supportName}</p>
            <p>Email: {value?.supportEmail}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          <ViewCommentsBtn
            secName={"Lead & Support (Internal)"}
            question={"Support"}
            answer={"Support Department"}
          />
        </div>
      </div>
    </div>
  );
}

export default LeadAndSupportPreview;
