import react, { useState } from "react";
import FormButton from "../../../components/button/FormButton";
import PdfUpload from "../../../components/pdfUpload/PdfUpload";
import FileUploadBox from "../../../components/fileUploadBox/FileUploadBox";
import CommentBox from "../../../components/commentBox/CommentBox";
import EmisGoodPractice from "../../../components/emissionComponents/emisGoodPractice/EmisGoodPractice";
import SelectNetYear from "../../../components/emissionComponents/step3/selectNetYear/SelectNetYear";
import EmisChecklistSection from "../../../components/emissionComponents/emisChecklistSection/EmisChecklistSection";
import ActionPlanTemplate from "../../../components/emissionComponents/step3/actionPlanTemplate/ActionPlanTemplate";
import Acknowledgement from "../../../components/emissionComponents/step1/acknowledgement/Acknowledgement";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import UpArraow from "../../../assets/icons/Caret_Circle_Up.svg";
import Circle from "../../../assets/icons/stepOneCircle.svg";
import Triangle from "../../../assets/icons/Triangle.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import PriorityPillar from "../../../components/emissionComponents/step3/priorityPillar/PriorityPillar";
import { useFormik } from "formik";
import AddPriorityPillar from "../../../components/emissionComponents/step3/addPriorityPillar/AddPriorityPillar";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import {
  useRoadmapDraftsMutation,
  useRoadmapResubmitMutation,
  useRoadmapSubmitMutation,
} from "../../../features/steps/roadmapApiSlice";
import { cleanObject, cleanObject2 } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import * as Yup from "yup";
import { useFormikToastError } from "../../../hooks/error";

function EmissionStep3() {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [
    roadmapSubmit,
    {
      isLoading: roadmapSubmitIsLoading,
      isSuccess: roadmapSubmitIsSuccess,
      error: roadmapSubmitIsError,
    },
  ] = useRoadmapSubmitMutation();

  const [
    roadmapResubmit,
    {
      isLoading: roadmapResubmitIsLoading,
      isSuccess: roadmapResubmitIsSuccess,
      error: roadmapResubmitIsError,
    },
  ] = useRoadmapResubmitMutation();

  const [
    roadmapDrafts,
    {
      isLoading: roadmapDraftsIsLoading,
      isSuccess: roadmapDraftsIsSuccess,
      error: roadmapDraftsIsError,
    },
  ] = useRoadmapDraftsMutation();

  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({});

  const validString = Yup.string()
    .trim()
    .min(0, "Minimum 0 characters are required")
    .max(500, "Maximum 500 characters are allowed")
    .matches(
      /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
      "Data must be alphanumeric and special characters only"
    );

  const fullNameString = Yup.string()
    .trim()
    .min(3, "Minimum 3 characters are required")
    .max(150, "Maximum 150 characters are allowed")
    .matches(/^[A-Za-z ]*$/, "Name must be alphabets and space only");

  // const timelineSchema = Yup.object().shape({
  //   fromYear: Yup.date("Invalid Date").required("Date is required"),
  //   fromQuater: Yup.string()
  //     .trim()
  //     .oneOf(["Q1", "Q2", "Q3", "Q4"], "Invalid Quarter")
  //     .required("Quarter is required"),
  //   toYear: Yup.date("Invalid Date").required("Date is required"),
  //   toQuater: Yup.string()
  //     .trim()
  //     .oneOf(["Q1", "Q2", "Q3", "Q4"], "Invalid Quarter")
  //     .required("Quarter is required"),
  // });
  const timelineSchema = Yup.object().shape({
    fromYear: Yup.date().required("Date is required"),
    fromQuater: Yup.string()
      .trim()
      .oneOf(["Q1", "Q2", "Q3", "Q4"], "Invalid Quarter")
      .required("Quarter is required"),
    toYear: Yup.date()
      .required("Date is required")
      .test(
        "is-greater",
        "Must be greater than or equal to From Year",
        function (value) {
          const { fromYear } = this.parent;
          return !fromYear || !value || new Date(value) >= new Date(fromYear);
        }
      ),
    toQuater: Yup.string()
      .trim()
      .oneOf(["Q1", "Q2", "Q3", "Q4"], "Invalid Quarter")
      .required("Quarter is required")
      .test("is-valid-quarter", "Invalid Quarter", function (value) {
        const { fromYear, toYear, fromQuater } = this.parent;
        if (!fromYear || !toYear || !fromQuater) return true;

        if (fromYear.getTime() === toYear.getTime() && fromQuater === "Q1") {
          return ["Q2", "Q3", "Q4"].includes(value);
        } else if (
          fromYear.getTime() === toYear.getTime() &&
          fromQuater === "Q2"
        ) {
          return ["Q3", "Q4"].includes(value);
        } else if (
          fromYear.getTime() === toYear.getTime() &&
          fromQuater === "Q3"
        ) {
          return ["Q4"].includes(value);
        }
        return true;
      }),
  });

  const initiativeSchema = Yup.object().shape({
    description: Yup.object().shape({
      data: validString.required("Description is required"),
    }),
    location: Yup.object().shape({
      location1: validString.required("Location is required"),
      location2: validString.required("Location is required"),
    }),
    leadAndSupport: Yup.object().shape({
      leadName: fullNameString.required("Name is required"),
      leadEmail: Yup.string()
        .trim()
        .email("Invalid Email")
        .lowercase("Email should be in lowercase")
        .required("Email is required"),
      leadContactType: Yup.string()
        .trim()
        .oneOf(["pointOfContact", "department"], "Invalid Contact Type")
        .required("Contact Type is required"),
      supportName: fullNameString.required("Name is required"),
      supportEmail: Yup.string()
        .trim()
        .email("Invalid Email")
        .lowercase("Email should be in lowercase")
        .required("Email is required"),
      supportContactType: Yup.string()
        .trim()
        .oneOf(["pointOfContact", "department"], "Invalid Contact Type")
        .required("Contact Type is required"),
    }),
    externalPartners: Yup.object().shape({
      business: validString.required("Business is required"),
      government: validString.required("Government is required"),
      nonProfitOrganizations: validString.required(
        "Non Profit Organizations is required"
      ),
    }),
    timelines: Yup.object().shape({
      planning: Yup.object().shape({
        data: Yup.array()
          .of(timelineSchema)
          .min(1, "At least one planning timeline is required")
          .max(5, "Maximum of 5 planning timelines are allowed")
          .required("Planning is required"),
      }),
      execution: Yup.object().shape({
        data: Yup.array()
          .of(timelineSchema)
          .min(1, "At least one planning timeline is required")
          .max(5, "Maximum of 5 planning timelines are allowed")
          .required("Executoion is required"),
      }),
      monitoring: Yup.object().shape({
        data: Yup.array()
          .of(timelineSchema)
          .min(1, "At least one planning timeline is required")
          .max(5, "Maximum of 5 planning timelines are allowed")
          .required("Monitoring is required"),
      }),
    }),
  });

  const actionPlanSchema = Yup.object().shape({
    year: Yup.date().required("Year is required"),
    priorityPillars: Yup.array()
      .of(
        Yup.object().shape({
          objective: validString.required("Objective is required"),
          description: validString.required("Description is required"),
          initiatives: Yup.array()
            .of(initiativeSchema)
            .min(1, "At least one initiative is required")
            .max(10, "Maximum of 10 initiatives are allowed"),
        })
      )
      .min(1, "At least one priority pillar is required")
      .max(5, "Maximum of 5 priority pillars are allowed"),
  });

  const RoadmapSubmissionSchema = Yup.object().shape({
    declerationId: Yup.string().trim().required(),
    actionPlan: actionPlanSchema,
  });

  const mapTimelines = (timelines) => {
    return timelines?.data?.length > 0
      ? timelines.data.map((item) => ({
          fromYear: item.fromYear ?? "",
          fromQuater: item.fromQuater ?? "",
          toYear: item.toYear ?? "",
          toQuater: item.toQuater ?? "",
        }))
      : [
          {
            fromYear: "",
            fromQuater: "",
            toYear: "",
            toQuater: "",
          },
        ];
  };

  const mapInitiatives = (initiatives) => {
    return initiatives?.length > 0
      ? initiatives.map((initiative) => ({
          description: {
            issueId: initiative?.description?.issueId ?? "",
            data: initiative?.description?.data ?? "",
          },
          location: {
            issueId: initiative?.location?.issueId ?? "",
            location1: initiative?.location?.location1 ?? "",
            location2: initiative?.location?.location2 ?? "",
          },
          leadAndSupport: {
            issueId: initiative?.leadAndSupport?.issueId ?? "",
            leadName: initiative?.leadAndSupport?.leadName ?? "",
            leadEmail: initiative?.leadAndSupport?.leadEmail ?? "",
            leadContactType:
              initiative?.leadAndSupport?.leadContactType ?? "pointOfContact",
            supportName: initiative?.leadAndSupport?.supportName ?? "",
            supportEmail: initiative?.leadAndSupport?.supportEmail ?? "",
            supportContactType:
              initiative?.leadAndSupport?.supportContactType ?? "department",
          },
          externalPartners: {
            issueId: initiative?.externalPartners?.issueId ?? "",
            business: initiative?.externalPartners?.business ?? "",
            government: initiative?.externalPartners?.government ?? "",
            nonProfitOrganizations:
              initiative?.externalPartners?.nonProfitOrganizations ?? "",
          },
          timelines: {
            planning: {
              issueId: initiative?.timelines?.planning?.issueId ?? "",
              data: mapTimelines(initiative?.timelines?.planning),
            },
            execution: {
              issueId: initiative?.timelines?.execution?.issueId ?? "",
              data: mapTimelines(initiative?.timelines?.execution),
            },
            monitoring: {
              issueId: initiative?.timelines?.monitoring?.issueId ?? "",
              data: mapTimelines(initiative?.timelines?.monitoring),
            },
          },
        }))
      : [
          {
            description: {
              issueId: "",
              data: "",
            },
            location: {
              issueId: "",
              location1: "",
              location2: "",
            },
            leadAndSupport: {
              issueId: "",
              leadName: "",
              leadEmail: "",
              leadContactType: "pointOfContact",
              supportName: "",
              supportEmail: "",
              supportContactType: "pointOfContact",
            },
            externalPartners: {
              issueId: "",
              business: "",
              government: "",
              nonProfitOrganizations: "",
            },
            timelines: {
              planning: {
                issueId: "",
                data: [
                  {
                    fromYear: "",
                    fromQuater: "",
                    toYear: "",
                    toQuater: "",
                  },
                ],
              },
              execution: {
                issueId: "",
                data: [
                  {
                    fromYear: "",
                    fromQuater: "",
                    toYear: "",
                    toQuater: "",
                  },
                ],
              },
              monitoring: {
                issueId: "",
                data: [
                  {
                    fromYear: "",
                    fromQuater: "",
                    toYear: "",
                    toQuater: "",
                  },
                ],
              },
            },
          },
        ];
  };

  const formik = useFormik({
    initialValues: {
      declarationId: findOneData?.data?.declaration?._id,
      actionPlan: {
        year: findOneData?.data?.declaration?.actionPlan?.year ?? "",
        priorityPillars:
          findOneData?.data?.declaration?.actionPlan?.priorityPillars?.length >
          0
            ? findOneData.data.declaration.actionPlan.priorityPillars.map(
                (item) => ({
                  issueId: item.issueId ?? "",
                  objective: item.objective ?? "",
                  description: item.description ?? "",
                  initiatives: mapInitiatives(item.initiatives ?? []),
                })
              )
            : [
                {
                  objective: "",
                  description: "",
                  initiatives: [
                    {
                      description: {
                        issueId: "",
                        data: "",
                      },
                      location: {
                        issueId: "",
                        location1: "",
                        location2: "",
                      },
                      leadAndSupport: {
                        issueId: "",
                        leadName: "",
                        leadEmail: "",
                        leadContactType: "pointOfContact",
                        supportName: "",
                        supportEmail: "",
                        supportContactType: "pointOfContact",
                      },
                      externalPartners: {
                        issueId: "",
                        business: "",
                        government: "",
                        nonProfitOrganizations: "",
                      },
                      timelines: {
                        planning: {
                          issueId: "",
                          data: [
                            {
                              fromYear: "",
                              fromQuater: "",
                              toYear: "",
                              toQuater: "",
                            },
                          ],
                        },
                        execution: {
                          issueId: "",
                          data: [
                            {
                              fromYear: "",
                              fromQuater: "",
                              toYear: "",
                              toQuater: "",
                            },
                          ],
                        },
                        monitoring: {
                          issueId: "",
                          data: [
                            {
                              fromYear: "",
                              fromQuater: "",
                              toYear: "",
                              toQuater: "",
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              ],
      },
    },
    enableReinitialize: true,
    validationSchema: RoadmapSubmissionSchema,
    onSubmit: (values) => {
      if (isConfirmed) {
        const data = structuredClone(values);
        cleanObject(data);
        if (
          findOneData?.data?.declaration?.currentStage === "Roadmap Submission"
        )
          roadmapSubmit(data)
            .unwrap()
            .then((res) => {
              dispatch(showSuccess({ message: `Submitted Successfully` }));
              navigate("/dashboard");
            })
            .catch((err) => {
              console.log("submit error", err.data.message);
            });
        else {
          roadmapResubmit(data)
            .unwrap()
            .then((res) => {
              dispatch(showSuccess({ message: `Resubmitted Successfully` }));
              navigate("/dashboard");
            })
            .catch((err) => {
              console.log("resubmit error", err.data.message);
            });
        }
      } else {
        dispatch(
          showError({
            message: `Please check the Monitoring & Reporting Acknowledgement before submitting your declaration`,
          })
        );
        return;
      }
    },
  });

  const addPriorityPillar = () => {
    formik.setFieldValue("actionPlan.priorityPillars", [
      ...formik.values.actionPlan.priorityPillars,
      {
        issueId: "",
        objective: "",
        description: "",
        initiatives: [
          {
            description: {
              issueId: "",
              data: "",
            },
            location: {
              issueId: "",
              location1: "",
              location2: "",
            },
            leadAndSupport: {
              issueId: "",
              leadName: "",
              leadEmail: "",
              leadContactType: "pointOfContact",
              supportName: "",
              supportEmail: "",
              supportContactType: "pointOfContact",
            },
            externalPartners: {
              issueId: "",
              business: "",
              government: "",
              nonProfitOrganizations: "",
            },
            timelines: {
              planning: {
                issueId: "",
                data: [
                  {
                    fromYear: "",
                    fromQuater: "",
                    toYear: "",
                    toQuater: "",
                  },
                ],
              },
              execution: {
                issueId: "",
                data: [
                  {
                    fromYear: "",
                    fromQuater: "",
                    toYear: "",
                    toQuater: "",
                  },
                ],
              },
              monitoring: {
                issueId: "",
                data: [
                  {
                    fromYear: "",
                    fromQuater: "",
                    toYear: "",
                    toQuater: "",
                  },
                ],
              },
            },
          },
        ],
      },
    ]);
  };

  const removePriorityPillar = ({ deleteIndex }) => {
    const updatedPriorityPillar =
      formik?.values?.actionPlan?.priorityPillars?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue("actionPlan.priorityPillars", updatedPriorityPillar);
  };

  const handleDraft = () => {
    const data = structuredClone(formik?.values);
    cleanObject2(data);
    console.log("DraftData", data);
    roadmapDrafts(data)
      .unwrap()
      .then((res) => {
        dispatch(showSuccess({ message: `Draft Saved Successfully` }));
        // navigate("/dashboard");
      })
      .catch((err) => {
        console.log("draft error", err.data.message);
      });
  };

  const handleCancleAndExit = () => {
    navigate("/dashboard");
  };

  useFormikToastError(formik);

  console.log("Step3FormikValues", formik?.values);
  console.log("Step3FormikErrrors", formik?.errors);
  console.log("FindOneData", findOneData);
  console.log(
    "Step3PriorityPillarsLength",
    formik.values.actionPlan.priorityPillars.length
  );

  return (
    <form className="emisSection" noValidate onSubmit={formik.handleSubmit}>
      <div className="emisTitleHeader">
        <div className="emiDecLogo">
          <img src={EmissionDecLogo} alt="" />
        </div>
        {/* {findOneData?.data?.declaration?.currentStage ===
          "Roadmap Submission" && ( */}
        <div className="emisTitleRightDiv">
          <div className="emisCancelBtn" onClick={handleCancleAndExit}>
            <FormButton
              buttonName={"Cancel & Exit"}
              buttonText={"#ffffff"}
              backgroundColor={"transparent"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
              type="button"
            />
          </div>

          <div
            className="emis3DraftBtn"
            onClick={() => {
              handleDraft();
            }}
          >
            <FormButton
              buttonName={"Save As Draft"}
              buttonText={"#ffffff"}
              backgroundColor={"transparent"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
              type="button"
            />
          </div>

          <div className="emisSubmitBtn">
            <FormButton
              buttonName={"Submit Roadmap"}
              backgroundColor={"#00637E"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </div>
        {/* )} */}
        {findOneData?.data?.declaration?.currentStage ===
          "Roadmap Resubmit" && (
          <div className="emisDec_buttonDiv">
            <div className="emisCancelBtn">
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
                type="button"
              />
            </div>
            <div
              className="emisSubmitBtn"
              onClick={(() => formik.handleSubmit(), console.log("resubmit"))}
            >
              <FormButton
                buttonName={"Resubmit Roadmap"}
                // backgroundColor={!isConfirmed ? "#7badba" : "#00637E"}
                backgroundColor={"#00637E"}
                buttonText={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
                // disabled={!isConfirmed}
              />
            </div>
          </div>
        )}
      </div>
      <div className="emisInnerContainer">
        <div className="emisSecCol1">
          <div className="roadmapTitle">
            <h1>Roadmap To Net Zero</h1>
            <div className="stepsNext">
              <p> Next Step: </p>
              <span> Assure/Audit Roadmap </span>
            </div>
          </div>
          <EmisChecklistSection />
          <EmisGoodPractice />
        </div>
        <div className="emisSecCol2">
          <div className="netYearRow">
            <div className="stepsCodewaveLogo">
              <img src={CodeLogo} />
              <h3>Codewave’s Roadmap To NetZero Emissions</h3>
            </div>
            <div>
              <SelectNetYear
                name="actionPlan.year"
                value={formik?.values?.actionPlan?.year}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik?.touched?.actionPlan?.year &&
                  formik?.errors?.actionPlan?.year
                }
                errorIcon={WarningIcon}
              />
            </div>
          </div>

          <ActionPlanTemplate />
          <Acknowledgement
            setIsConfirmed={setIsConfirmed}
            isConfirmed={isConfirmed}
            title="Targets Confirmation"
            confirmationText="We confirm that we have understood the Target Information and comply with the reductions per quarter as per our Net Zero Target Year"
          />

          {formik.values.actionPlan.priorityPillars.map((_, index) => (
            <PriorityPillar
              key={index}
              formik={formik}
              deleteIndex={index}
              index={index}
              removePriorityPillar={removePriorityPillar}
              headName={`Priority Pillar ${index + 1}`}
              name={`actionPlan.priorityPillars[${index}]`}
              value={formik?.values?.actionPlan?.priorityPillars[index]}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // error={
              //   !!formik.touched.actionPlan?.priorityPillars[index] &&
              //   formik.errors.actionPlan?.priorityPillars[index]
              // }
              errorIcon={WarningIcon}
            />
          ))}

          <AddPriorityPillar addPriorityPillar={addPriorityPillar} />

          {findOneData?.data?.declaration?.currentStage ===
            "Roadmap Submission" && (
            <div className=" bottomSubmitRow">
              <div className=" cursorPointer" onClick={handleCancleAndExit}>
                <FormButton
                  buttonName={"Cancel & Exit"}
                  border={"1px solid #008BB2"}
                  buttonText={"#008BB2"}
                  backgroundColor={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                  type="button"
                />
              </div>
              <div className="cancelBtn_Row">
                <div className="NextBtn cursorPointer">
                  <FormButton
                    buttonName={"Submit Roadmap"}
                    // backgroundColor={!isConfirmed ? "#7badba" : "#00637E"}
                    backgroundColor={"#00637E"}
                    buttonText={"#ffffff"}
                    btnFont={"14px"}
                    btnPdd={"12px 20px"}
                    // disabled={!isConfirmed}
                  />
                </div>
              </div>
            </div>
          )}
          {findOneData?.data?.declaration?.currentStage ===
            "Roadmap Resubmit" && (
            <div className=" bottomSubmitRow">
              <div className=" cursorPointer" onClick={handleCancleAndExit}>
                <FormButton
                  buttonName={"Cancel & Exit"}
                  border={"1px solid #008BB2"}
                  buttonText={"#008BB2"}
                  backgroundColor={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                  type="button"
                />
              </div>
              <div className="cancelBtn_Row">
                <div className="NextBtn cursorPointer">
                  <FormButton
                    buttonName={"Resubmit Roadmap"}
                    // backgroundColor={!isConfirmed ? "#7badba" : "#00637E"}
                    backgroundColor={"#00637E"}
                    buttonText={"#ffffff"}
                    btnFont={"14px"}
                    btnPdd={"12px 20px"}
                    // disabled={!isConfirmed}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </form>
  );
}

export default EmissionStep3;
