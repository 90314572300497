import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import AdminOrgTitle from "../../../components/adminComponents/adminOrgTitle/AdminOrgTitle";
import RatingFilterForm from "../../../components/adminComponents/userFilterForm/RatingFilterForm";
import OrgsRatingDetails from "../../../components/adminComponents/userDetails/OrgsRatingDetails";

import "./style.css";

function AdminOrgsRating() {
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu orgsRatingMenu={"orgsRatingMenu"} />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <AdminOrgTitle />
          <RatingFilterForm />
          <OrgsRatingDetails />
        </div>
      </div>
    </div>
  );
}

export default AdminOrgsRating;
