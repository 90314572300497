import React, { useEffect, useState } from "react";

import "./style.css";

import GreenCheckBox from "../../../assets/images/greenCheckBox.svg";
import DimondCheckBox from "../../../assets/images/dimondCheckBox.svg";
import blankCheckBox from "../../../assets/images/blankCheckBox.svg";
import ViewDeclarationBtn from "../../button/viewDeclarationBtn/ViewDeclarationBtn";
import TimeLineStepsData from "../timeLineStepsData/TimeLineStepsData";
import AuditTimelineData from "../auditTimelineData/AuditTimelineData";
import ViewCommentsBtn from "../../button/viewCommentsBtn/ViewCommentsBtn";
import ReportLog from "../../drawer/ReportLog";

const emissData = [
  {
    title: "Submitted on:",
    value: "20th November 2022",
  },
  {
    title: "Issues Resolved:",
    value: "4 of 4",
  },
  {
    title: "Corrections Made:",
    value: "4 of 4",
  },
  {
    title: "Resubmitted On:",
    value: "22nd November 2022",
  },
];

const auditEmissData = [
  {
    title: "Issues Reported:",
    value: "4 Issues",
  },
  {
    title: "Audited on:",
    value: "24th November 2022",
  },
];

function RatingTimeline({ data }) {
  console.log("emissData", data?.["Emission Declaration"]);

  const [reportLogs, setReportLogs] = useState(false);

  const reportLogOpen = () => {
    setReportLogs(true);
  };
  const reportLogClose = () => {
    setReportLogs(false);
  };


  return (
    <div className="ratingTimeline">
      <div className="ratingProcessSec">
        <h1>Rating Process</h1>
        <p>
          Your organization is due for it’s quarterly / yearly Monitoring &
          Reporting. Your consultant is working on it right now
        </p>
      </div>

      <div className="" style={{ position: "relative" }}>
        <div className="timelineTitleRow">
          <div className="timelineTitleCol1">
            <img
              src={
                data?.["Emission Declaration"].status === "completed"
                  ? GreenCheckBox
                  : data?.["Emission Declaration"].status === "inProgress"
                    ? DimondCheckBox
                    : data?.["Emission Declaration"].status === "inComplete"
                      ? blankCheckBox
                      : null
              }
              alt="GreenCheckBox"
            />
            <div className="ratTimelineTitle">
              <h1>Emissions Declaration</h1>
              <TimeLineStepsData value={data?.["Emission Declaration"]} />
            </div>
          </div>
          <ViewDeclarationBtn />
        </div>
        <div className="timelineTitleRow">
          <div className="timelineTitleCol1">
            <img
              src={
                data?.["Emission Audit"].status === "completed"
                  ? GreenCheckBox
                  : data?.["Emission Audit"].status === "inProgress"
                    ? DimondCheckBox
                    : data?.["Emission Audit"].status === "inComplete"
                      ? blankCheckBox
                      : null
              }
              alt="GreenCheckBox"
            />
            <div className="ratTimelineTitle">
              <h1>Assure/Audit Emissions</h1>
              <AuditTimelineData value={data?.["Emission Audit"]} />
            </div>
          </div>
          <ViewCommentsBtn />
        </div>
        <div className="timelineTitleRow">
          <div className="timelineTitleCol1">
            <img
              src={
                data?.["Roadmap Submission"].status === "completed"
                  ? GreenCheckBox
                  : data?.["Roadmap Submission"].status === "inProgress"
                    ? DimondCheckBox
                    : data?.["Roadmap Submission"].status === "inComplete"
                      ? blankCheckBox
                      : null
              }
              alt="GreenCheckBox"
            />
            <div className="ratTimelineTitle">
              <h1>Action Plan To Net Zero</h1>
              <TimeLineStepsData value={data?.["Roadmap Submission"]} />
            </div>
          </div>
          <ViewDeclarationBtn />
        </div>
        <div className="timelineTitleRow">
          <div className="timelineTitleCol1">
            <img
              src={
                data?.["Roadmap Audit"].status === "completed"
                  ? GreenCheckBox
                  : data?.["Roadmap Audit"].status === "inProgress"
                    ? DimondCheckBox
                    : data?.["Roadmap Audit"].status === "inComplete"
                      ? blankCheckBox
                      : null
              }
              alt="GreenCheckBox"
            />
            <div className="ratTimelineTitle">
              <h1>Assure/Audit Action Plan</h1>
              <AuditTimelineData value={data?.["Roadmap Audit"]} />
            </div>
          </div>
          <ViewCommentsBtn />
        </div>
        <div className="timelineTitleRow">
          <div className="timelineTitleCol1">
            <img
              src={
                data?.["Grant Rating"].status === "completed"
                  ? GreenCheckBox
                  : data?.["Grant Rating"].status === "inProgress"
                    ? DimondCheckBox
                    : data?.["Grant Rating"].status === "inComplete"
                      ? blankCheckBox
                      : null
              }
              alt="GreenCheckBox"
            />
            <div className="timelineUnactiveTitle">
              <h1>Granting A Rating</h1>
              <div>
                <p>
                  Our AI system is currently allocating a rating for your
                  organization. Please wait for a while longer
                </p>
              </div>
              {/* <AuditTimelineData /> */}
            </div>
          </div>
          {/* <ViewDeclarationBtn /> */}
        </div>
        <div className="timelineTitleRow">
          <div className="timelineTitleCol1">
            <img
              src={
                data?.["Monitor & Report"].status === "completed"
                  ? GreenCheckBox
                  : data?.["Monitor & Report"].status === "inProgress"
                    ? DimondCheckBox
                    : data?.["Monitor & Report"].status === "inComplete"
                      ? blankCheckBox
                      : null
              }
              alt="GreenCheckBox"
            />
            <div className="timelineUnactiveTitle">
              <h1>Granting A Rating</h1>
            </div>

            <button onClick={reportLogOpen}>
              <p>View Log</p>
            </button>
            {reportLogs &&
              <ReportLog 
              isOpen={reportLogs} 
              onClose={reportLogClose} 
             />
            }
            
          </div>
          {/* <ViewDeclarationBtn /> */}
        </div>

        <div className="ratingTimelineScale">
          <div className="ratingActiveScale" style={{ height: "40%" }}></div>
        </div>
      </div>
    </div>
  );
}

export default RatingTimeline;
