import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, FormGroup, Switch } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminPagination from "../pagination/AdminPagination";
import { useUserStatusChangeMutation } from "../../../features/admin/adminApiSlice";
import {
    showError,
    showSuccess,
} from "../../../features/snackbar/snackbarAction";
import PageLoader from "../../Loader/PageLoader";

import "./style.css";

const roleManageList = [
  {
    feedbackTitle: "Feedback",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
  {
    feedbackTitle: "Lorem Ipsum",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
];

const RoleManageDetails = ({
  isLoading,
  isFetching,
  changeRowsPerPage,
  rowsPerPage,
  changePage,
  page,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminCheck, setAdminCheck] = useState(false)
  const [orgsCheck, setOrgsCheck] = useState(false)
  const [consCheck, setConsCheck] = useState(false)
  const [auditCheck, setAuditCheck] = useState(false)
  const [advBCheck, setAdvBCheck] = useState(false)

  const [
    changeUserStatus,
    {
      isLoading: userStatusIsLoading,
      isSuccess: userStatusIsSuccess,
      error: userStatusError,
      isError: userStatusIsError,
    },
  ] = useUserStatusChangeMutation();

  const changeValueAdmin = (event) => {
    setAdminCheck(event.target.checked);
  };
  const changeValueOrgs = (event) => {
    setOrgsCheck(event.target.checked);
  };
  const changeValueCons = (event) => {
    setConsCheck(event.target.checked);
  };
  const changeValueAudit = (event) => {
    setAuditCheck(event.target.checked);
  };
  const changeValueAdvB = (event) => {
    setAdvBCheck(event.target.checked);
  };


  return (
    <div className="userDetailsContainer">
      {(userStatusIsLoading || isLoading || isFetching) && <PageLoader />}
      <table className="orgRatingTable">
        <tr className="userDetailTitle">
          <th className="heading"></th>
          <th className="heading roleTd">Admin</th>
          <th className="heading roleTd">B&I Organization</th>
          <th className="heading roleTd">Consultant</th>
          <th className="heading roleTd">Auditor</th>
          <th className="heading roleAdvMember">Member Of Advisory Board</th>
        </tr>
        {roleManageList.map((item, index) => (
          <tr className="adminUserList" key={index}>
            <td className="roleManagTextCol">
              <div className="roleManageText">
                <h1>{item.feedbackTitle}</h1>
                <p>{item.feedback}</p>
              </div>
            </td>
            <td className="checkTd">
                <FormGroup>
                    <FormControlLabel
                    value="Control"
                    label=""
                    control={
                        <Checkbox
                        size="small"
                        checked={adminCheck[index]}
                        onChange={changeValueAdmin}
                        style={{
                            color: '#008BB2',
                            '&.Mui-checked': {
                              color: 'green',
                            },
                          }}
                        />
                    }
                    />
                </FormGroup>
            </td>
            <td className="checkTd">
                <FormGroup>
                    <FormControlLabel
                    value="Control"
                    label=""
                    control={
                        <Checkbox
                        size="small"
                        checked={orgsCheck[index]}
                        onChange={changeValueOrgs}
                        style={{
                            color: '#008BB2',
                            '&.Mui-checked': {
                              color: 'green',
                            },
                          }}
                        />
                    }
                    />
                </FormGroup>
            </td>
            <td className="checkTd">
                <FormGroup>
                    <FormControlLabel
                    value="Control"
                    label=""
                    control={
                        <Checkbox
                        size="small"
                        checked={consCheck[index]}
                        onChange={changeValueCons}
                        style={{
                            color: '#008BB2',
                            '&.Mui-checked': {
                              color: 'green',
                            },
                          }}
                        />
                    }
                    />
                </FormGroup>
            </td>
            <td className="checkTd">
                <FormGroup>
                    <FormControlLabel
                    value="Control"
                    label=""
                    control={
                        <Checkbox
                        size="small"
                        checked={auditCheck[index]}
                        onChange={changeValueAudit}
                        style={{
                            color: '#008BB2',
                            '&.Mui-checked': {
                              color: 'green',
                            },
                          }}
                        />
                    }
                    />
                </FormGroup>
            </td>
            <td className="checkTd">
                <FormGroup>
                    <FormControlLabel
                    value="Control"
                    label=""
                    control={
                        <Checkbox
                        size="small"
                        checked={advBCheck[index]}
                        onChange={changeValueAdvB}
                        style={{
                            color: '#008BB2',
                            '&.Mui-checked': {
                              color: 'green',
                            },
                          }}
                        />
                    }
                    />
                </FormGroup>
            </td>
          </tr>
        ))}
      </table>
      <AdminPagination
        changeRowsPerPage={changeRowsPerPage}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        page={page}
        totalCount={totalCount}
      />
    </div>
  );
};

export default RoleManageDetails;
