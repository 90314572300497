import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import InputBox from '../../InputBox/InputBox';

import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import WavyCheck from "../../../assets/icons/WavyCheck.svg";

const passCriRul = [
    { rule: "The password should be at least 8 characters or more."},
    { rule: "Password must contain at least one uppercase and one lowercase alphabet."},
    { rule: "Password must contain at least one numeric character."},
    { rule: "Password must contain at least one special character."},
  ]

function AdminLogCreds({formik}) {
    const [currentStep, setCurrentStep] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

   const currentPasswordVisibe = () => {
    setCurrentStep(!currentStep);
    };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const confirmPassVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };


  return (
    <div className='adminBasicCon'>
        <h1>Login Credentials</h1>
        <form 
            className="adminBsForm" 
            noValidate 
            onSubmit={formik.handleSubmit}
        >
            <div className="adminBsInputRow">
                    <div className="relativePosition adminPassInp">
                    <InputBox
                        headName={"Current Password"}
                        placeholder="Current Password"
                        type={currentStep ? "text" : "password"}
                        name="currentPassword"
                        value={formik?.values?.currentPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorIcon={WarningIcon}
                        error={!!formik.touched.currentPassword && formik.errors.currentPassword}
                        required
                    />
                    <div className="login_Eyeicon_position">
                        <FontAwesomeIcon
                        icon={currentStep ? faEye : faEyeSlash}
                        onClick={() => currentPasswordVisibe()}
                        className="login_Eye_icon eye_color"
                        />
                    </div>
                    </div>
                    <div className="relativePosition adminPassInp">
                    <InputBox
                        headName={"New Password"}
                        placeholder="New Password"
                        type={isPasswordVisible ? "text" : "password"}
                        name="newPassword"
                        value={formik?.values?.newPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorIcon={WarningIcon}
                        error={!!formik.touched.newPassword && formik.errors.newPassword}
                        required
                    />
                    <div className="login_Eyeicon_position">
                        <FontAwesomeIcon
                        icon={isPasswordVisible ? faEye : faEyeSlash}
                        onClick={() => togglePasswordVisibility()}
                        className="login_Eye_icon eye_color"
                        />
                    </div>
                    </div>
                    <div className="relativePosition adminPassInp">
                    <InputBox
                        headName={"Retype New Password"}
                        placeholder="Retype new password"
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        name="retypePassword"
                        value={formik?.values?.retypePassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorIcon={WarningIcon}
                        error={
                        !!formik.touched.retypePassword &&
                        formik.errors.retypePassword
                        }
                        required
                    />
                    <div className="login_Eyeicon_position">
                        <FontAwesomeIcon
                        icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                        onClick={() => confirmPassVisibility()}
                        className="login_Eye_icon eye_color"
                        />
                    </div>
                    </div>
             

                <div className="editPassRule">
                    <h1 className="editPassRuleTitle">Password Criteria:</h1>
                    {passCriRul?.map((item) => (
                    <div className="editPassCriteria">
                        <img src={WavyCheck} />
                        <p>{item.rule}</p>
                    </div>
                    ))} 
                </div>
        </div>
      </form>
    </div>
  )
}

export default AdminLogCreds;