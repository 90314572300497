import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InventoryBoundaries from '../inventoryBoundaries/InventoryBoundaries';

import "./style.css";
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';

function InfoOffsetView({formik}) {
  
  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel8a-content"
        id="panel8a-header"
      >
        <div className='typographyRow'>
          <Typography>
          <div className='GenrlInfo'>
              <h3>INFORMATION ON OFFSETS</h3>
          </div>
          </Typography>
          <div className='editBtnRow'>
              <NewCommentsBtn btnTitle={"2 New Comments"} />
              <EditButton />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='infoOffsetContainer'>
            <InventoryBoundaries 
            AddOffsetTitle="Information on offsets that have been purchased or developed outside the inventory boundary"
            />
            <InventoryBoundaries 
            AddOffsetTitle="Information on reductions inside the inventory boundary that have been sold/transferred as offsets to a third party."
            />
        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default InfoOffsetView;