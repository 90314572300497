import { useCallback, useReducer, useEffect } from "react";
import apiSlice from "../../app/api/apiSlice";
import { useUploadUrlMutation } from "./fileUploadApiSlice";
// import { useDeleteFileMutation } from "../settings/filemanager/filemanagerApiSlice";

import { omitEmptyKeys } from "../../utils/helper";
import { useDispatch } from "react-redux";

const initialUploadState = {
  data: {
    details: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
  files: null,
  module: null,
  isMandatory: false,
  folder: null,
};

const uploadReducer = (state, action) => {
  if (action.type === "GET_URL") {
    const mediaDetails = omitEmptyKeys(action);
    return {
      ...initialUploadState,
      ...mediaDetails,
      isLoading: true,
    };
  }
  if (action.type === "GET_URL_ERROR") {
    return {
      ...initialUploadState,
      isError: action.isError,
      error: action.error,
    };
  }
  if (action.type === "UPLOAD_ERROR") {
    return {
      ...initialUploadState,
      isError: action.isError,
      error: action.error,
    };
  }
  if (action.type === "UPLOAD_SUCCESS") {
    return {
      ...state,
      data: { details: [...state.data.details, action.detail] },
    };
  }
  if (action.type === "UPLOAD_SUCCESS_ALL") {
    return {
      ...initialUploadState,
      data: { details: [...state.data.details] },
      isSuccess: true,
    };
  }
  if (action.type === "RESET") {
    return {
      ...initialUploadState,
    };
  }
  return initialUploadState;
};

const UseMultipleFileUpload = () => {
  const dispatch = useDispatch();
  const [uploadState, dispatchUpload] = useReducer(
    uploadReducer,
    initialUploadState
  );

  const [uploadUrl] = useUploadUrlMutation();
  //   const [deleteFile] = useDeleteFileMutation();

  const uploadFiles = useCallback(
    ({ files, module = "others", isMandatory, folder }) => {
      dispatchUpload({ type: "GET_URL", files, module, isMandatory, folder });
    },
    []
  );

  useEffect(() => {
    if (uploadState.files) {
      (async () => {
        const uploads = await Promise.allSettled(
          uploadState.files.map((file, ix) => {
            return uploadUrl({
              fileName: file.name,
              // filesize: file.size,
              // mimetype: file.type,
              // folderid: file.folderId ?? "",
              // filetype: file.type.slice(0, file.type.indexOf("/")),
              // module: uploadState.module,
              folder: uploadState.folder,
              // ismandatory: uploadState.isMandatory,
            }).unwrap();
          })
        );
        let ix = 0;
        for (const { status, value } of uploads) {
          const file = uploadState.files[ix++];
          if (status === "fulfilled") {
            const { fields, fileId, url } = value;
            const fd = new FormData();
            for (const [key, val] of Object.entries(fields))
              fd.append(key, val);
            fd.append("file", file);
            try {
              const res = await fetch(url, { method: "POST", body: fd });
              if (!res.ok || res.status !== 204)
                throw new Error(
                  `Upload Failed: ${res.status} ${res.statusText}`
                );
              else
                dispatchUpload({
                  type: "UPLOAD_SUCCESS",
                  detail: {
                    url: `${url}/${fields.key}`,
                    fileName: file.name,
                  },
                });
            } catch (error) {
              //   deleteFile(fileId)
              //     .unwrap()
              //     .catch(() => {});
            }
          }
        }
        dispatchUpload({ type: "UPLOAD_SUCCESS_ALL" });
      })().catch(() => {
        dispatchUpload({
          type: "GET_URL_ERROR",
          error: "Something went wrong!, please try again",
          isError: true,
        });
      });
      // .finally(() => dispatch(apiSlice.util.invalidateTags(["FileManager"])));
    } else {
      dispatchUpload({
        type: "RESET",
      });
    }
  }, [
    uploadState.files,
    uploadState.module,
    uploadState.folder,
    uploadState.isMandatory,
    uploadUrl,
    dispatch,
  ]);

  return [uploadFiles, uploadState];
};

export default UseMultipleFileUpload;
