import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import {
  useOtherAudInfoMutation,
  usePreviewAudInfoMutation,
} from "../../../../features/auditor/auditorApiSlice";
import {
  useOtherConsInfoMutation,
  usePreviewConsInfoMutation,
} from "../../../../features/consultant/consultantApiSlice";

import { otherDetailsSchema } from "../../../../Validators/register.validators";

import BasicTimeline from "../../../../components/timeLine/BasicTimeline";
import InputBox from "../../../../components/InputBox/InputBox";
import FormButton from "../../../../components/button/FormButton";
import FileUploadBox from "../../../../components/fileUploadBox/FileUploadBox";
import PdfUpload from "../../../../components/pdfUpload/PdfUpload";
import FileUploadBoxRegistration from "../../../../components/fileUploadBox/FileUploadBoxRegistration";
import RegCancelButton from "../../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../../components/button/regBackBtn/RegBackButton";

import "./style.css";

import RegistrationLogo from "../../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUserFromLocal } from "../../../../utils/storage";
import { setUserHandler } from "../../../../features/user/userAction";
import {
  useOrgAdditionalInfoMutation,
  useOrgPreviewInfoMutation,
} from "../../../../features/registration/organization/organizatonApiSlice";
import { userActions } from "../../../../features/user/userSlice";
import PageLoader from "../../../../components/Loader/PageLoader";
import { FormHelperText } from "@mui/material";

function OtherDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = getUserFromLocal();
  const userReduxDetails = useSelector((state) => state.user);

  const [resposnse, setResoponse] = useState("");
  const [currentStep, setCurrentStep] = useState("additional-details");

  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { additionalDetails } = response || {};

  const [
    previewInfo,
    {
      isLoading: previewIsLoading,
      isSuccess: previewIsSuccess,
      error: previewIsError,
    },
  ] = useOrgPreviewInfoMutation();

  const [
    previewConsInfo,
    {
      isLoading: previewConsIsLoading,
      isSuccess: previewConsIsSuccess,
      error: previewConsIsError,
    },
  ] = usePreviewConsInfoMutation();

  const [
    previewAudInfo,
    {
      isLoading: previewAudIsLoading,
      isSuccess: previewAudIsSuccess,
      error: previewAudIsError,
    },
  ] = usePreviewAudInfoMutation();

  const [
    otherInfo,
    {
      isLoading: otherInfoIsLoading,
      isSuccess: otherInfoIsSuccess,
      error: otherInfoIsError,
    },
  ] = useOrgAdditionalInfoMutation();

  const [
    otherAudInfo,
    {
      isLoading: otherAudInfoIsLoading,
      isSuccess: otherAudInfoIsSuccess,
      error: otherAudInfoIsError,
    },
  ] = useOtherAudInfoMutation();

  const [
    otherConsInfo,
    {
      isLoading: otherConsInfoIsLoading,
      isSuccess: otherConsInfoIsSuccess,
      error: otherConsInfoIsError,
    },
  ] = useOtherConsInfoMutation();

  useEffect(() => {
    if (userDetails?.userType === "organization") {
      previewInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "auditor") {
      previewAudInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "consultant") {
      previewConsInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
  }, [userDetails?.userId, userDetails?.trackingCode, userDetails?.userType]);

  const basicValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
  };

  const emptyValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
    orgDetailsFiles: [],
    otherDetailsFiles: [],
    websiteLink: "",
    otherWebsiteLink: [
      {
        link: "",
        linkName: "",
      },
    ],
  };

  const formik = useFormik({
    initialValues: {
      _id: userDetails?.userId,
      trackingCode: userReduxDetails?.trackingCode,
      orgDetailsFiles: additionalDetails?.orgDetailsFiles ?? [],
      otherDetailsFiles: additionalDetails?.otherDetailsFiles ?? [],
      // websiteLink: additionalDetails?.websiteLink ?? "",
      otherWebsiteLink: additionalDetails?.otherWebsiteLink?.map((branch) => ({
        link: branch?.link ?? "",
        linkName: branch?.linkName ?? "",
      })) || [{ link: "", linkName: "" }],
    },
    enableReinitialize: true,
    validationSchema: otherDetailsSchema,

    onSubmit: (values) => {
      const userTypes = ["organization", "auditor", "consultant"];
      const registerFunctions = [otherInfo, otherAudInfo, otherConsInfo];

      const userTypeIndex = userTypes.indexOf(userDetails?.userType);
      if (userTypeIndex !== -1) {
        registerFunctions[userTypeIndex](values)
          .unwrap()
          .then((res) => {
            // setFormNumber(4);
            navigate("/auth/registration/loginCredentials");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((err) => {});
      }
    },
  });

  // const handleCancel = () => {
  //   formik.resetForm();
  //   formik.setErrors({});
  // };

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  const handlePdfChange = (event) => {
    const file = event.target.files[0].name;
    formik.setFieldValue("otherDetailsFiles", [
      ...formik.values.otherDetailsFiles,
      file,
    ]);
  };

  const handlePdf = (event) => {
    const file = event.target.files[0].name;
    formik.setFieldValue(`[orgDetailsFiles]`, [
      ...formik.values.orgDetailsFiles,
      file,
    ]);
  };

  const addLink = () => {
    formik.setFieldValue("otherWebsiteLink", [
      ...formik.values.otherWebsiteLink,
      {
        link: "",
        linkName: "",
      },
    ]);
  };

  const removeLink = ({ deleteIndex }) => {
    const updatedBranch = formik?.values?.otherWebsiteLink?.filter(
      (_, index) => index !== deleteIndex
    );
    formik.setFieldValue("otherWebsiteLink", updatedBranch);
  };

  // const addLink = () => {
  //   const newLink = formik?.values?.otherWebsiteLink.concat({
  //     link: "",
  //     linkName: ""
  //   });
  //   formik.setFieldValue("otherWebsiteLink", newLink);
  // };

  // const removeLink = (index) => {
  //   delete formik.values.otherWebsiteLink[index];
  //   formik.setFieldValue("otherWebsiteLink", formik.values.otherWebsiteLink)
  // }

  console.log("formikValuesBasicDetails", formik?.values);
  console.log("formikValuesBasicErrors", formik?.errors);

  return (
    <div className="container otherDetails_section">
      {(previewIsLoading ||
        previewConsIsLoading ||
        previewAudIsLoading ||
        otherInfoIsLoading ||
        otherAudInfoIsLoading ||
        otherConsInfoIsLoading) && <PageLoader />}
      <div className="otherDetailsSec_Col1">
        <img className="otherDetails_topBg" src={SignUpTopBg} />
        <img className="otherDetails_bottomBg" src={SignUpBottomBg} />
        <img className="otherDetails_leftBg" src={SignUpLeftBg} />

        <div className="otherDetails_timeLineContents">
          <div className="otherDetails_logoDiv">
            <img src={RegistrationLogo} />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>
      <form
        className="otherDetailsSec_Col2"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <h1>Additional Details</h1>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur. Dictum eget viverra at
          pellentesque consectetur fames ullamcorper. Aliquet volutpat cras
          cursus at morbi consectetur. Gravida nunc in sit porta.
        </p> */}

        <div>
          <p className="upload_regDetails">
            Please upload copies of your organization’s registration details
            (eg. trade license, Articles of Incorporation, Certificate of
            Incorporation)
          </p>
          <p className="attachment_Paragraph">
            Attachments cannot exceed 5 Maximum size per file cannot exceed Xmb
            per attachment All video formats will be accepted
          </p>
          <div className="wd-100 mb-28 addPdfWidth">
            <FileUploadBoxRegistration
              formik={formik}
              field="orgDetailsFiles"
              value={formik?.values?.orgDetailsFiles}
              folder="registration"
              errorIcon={WarningIcon}
              error={
                !!formik.touched.orgDetailsFiles &&
                formik.errors.orgDetailsFiles
              }
            />
          </div>
        </div>
        {/* <div className="rowDiv">
          <div className="">
            <PdfUpload pdfTyle={"TradeLicense.pdf"} />
          </div>
          <div className=" mL-2r">
            <PdfUpload pdfTyle={"ArticlesOfIncorporation.pdf"} />
          </div>
          <div className=" mL-2r">
            <PdfUpload pdfTyle={"Certificate.pdf"} />
          </div>
        </div> */}

        <div className="horizontalLine"></div>

        <div>
          <p className="upload_regDetails">
            Please provide any other relevant information you have not yet
            provided to enable us to evaluate the legitimacy of your
            organization. Feel free to attach documents, links etc.
          </p>
        </div>
        <p className="attachment_Paragraph">
          Attachments cannot exceed 5 Maximum size per file cannot exceed Xmb
          per attachment All video formats will be accepted
        </p>
        <div className="wd-100 addPdfWidth">
          <FileUploadBoxRegistration
            formik={formik}
            field="otherDetailsFiles"
            value={formik?.values?.otherDetailsFiles}
            folder="registration"
          />
        </div>

        <div className="otherDetails_form">
          <div className="AdditionWebLink">
            {formik?.values?.otherWebsiteLink?.map((_, index) => (
              <div className="odOtherLink">
                <div className="wd-60">
                  <InputBox
                    headName={"Other Links:"}
                    placeholder="www.website.com"
                    inputBoxMb="0px"
                    name={`otherWebsiteLink[${index}].link`}
                    value={formik?.values?.otherWebsiteLink?.[index]?.link}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.otherWebsiteLink?.[index]?.link &&
                      formik.errors.otherWebsiteLink?.[index]?.link
                    }
                    required
                  />
                </div>
                <div className="wd-30 addLinkname">
                  <InputBox
                    headName={"Name Of Link:"}
                    placeholder="Enter a name for the link"
                    inputBoxMb="0px"
                    name={`otherWebsiteLink[${index}].linkName`}
                    value={formik?.values?.otherWebsiteLink?.[index]?.linkName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.otherWebsiteLink?.[index]?.linkName &&
                      formik.errors.otherWebsiteLink?.[index]?.linkName
                    }
                    required
                  />
                </div>
                <div className="odRemoveBtn">
                  <p
                    className="remove cursorPointer"
                    onClick={removeLink.bind(null, {
                      deleteIndex: index,
                    })}
                  >
                    <FontAwesomeIcon
                      icon={faCircleMinus}
                      className="fontAwesomeIcon_img"
                      style={{ color: "#008BB2" }}
                    />{" "}
                    Remove
                  </p>
                </div>
              </div>
            ))}
            <div className="otherAddBranch_div cursorPointer">
              <p className="addBranch" onClick={addLink}>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="fontAwesomeIcon_img"
                  style={{ color: "#008BB2" }}
                />
                Add a link
              </p>
            </div>
          </div>

          <div className="form-row od_BtnRow">
            <div
              className="cursorPointer"
              onClick={() => {
                navigate("/auth/registration/organizationDetails");
              }}
            >
              <RegBackButton buttonName={"Back"} />
            </div>
            <div
              className=" cursorPointer cancelBtn_Row"
              //  onClick={handleCancel}
            >
              <div
                onClick={() => {
                  formik.resetForm();
                  formik.setValues(emptyValues);
                }}
              >
                <RegCancelButton buttonName={"Cancel"} />
              </div>
              <div className=" NextBtn cursorPointer">
                <FormButton
                  buttonName={"Next"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"3px 12px"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default OtherDetails;
