import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";

import OtpVerification from "../../../components/otpVerification/emailVerification/OtpVerification";
import FormButton from "../../../components/button/FormButton";

import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import { useOrgVerifyOtpMutation } from "../../../features/registration/organization/organizatonApiSlice";
import { useEffect } from "react";
import { setUserHandler } from "../../../features/user/userAction";
import { useDispatch } from "react-redux";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { userActions } from "../../../features/user/userSlice";
import { useAdvisoryBoardVerifyOtpMutation } from "../../../features/registration/AdvisoryBoardMember/AdvisoryBoardMemberApiSlice";
import {
  useCheckConsultantQuery,
  useGetCheckConsultantQuery,
} from "../../../features/registration/consultant/consultantApiSlice";
import { useResendOtpMutation } from "../../../features/auth/authApiSlice";
import PageLoader from "../../../components/Loader/PageLoader";

function EmailVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [otp, setOtp] = useState("");
  const [timmer, setTimmer] = useState("10");
  const [otpVerifyStatus, setOtpVerifyStatus] = useState(false);
  const identifier = decodeURIComponent(searchParams.get("identifier"));
  const userType = decodeURIComponent(searchParams.get("userType"));
  const email = decodeURIComponent(searchParams.get("email"));
  const fullName = decodeURIComponent(searchParams.get("fullName"));
  const resendInstant = decodeURIComponent(searchParams.get("resendInstant"));

  const [verificationFailureStatus, setVerificationFailureStatus] =
    useState(false);
  const [verificationSuccessStatus, setVerificationSuccessStatus] =
    useState(false);

  const [
    orgVerifyOtp,
    {
      data: orgVerifyOtpData,
      isLoading: orgVerifyOtpIsLoading,
      isSuccess: orgVerifyOtpIsSuccess,
      error: orgVerifyOtpIsError,
    },
  ] = useOrgVerifyOtpMutation();

  const [
    advisoryBoardMemberVerifyOtp,
    {
      data: advisoryBoardMemberVerifyOtpData,
      isLoading: advisoryBoardMemberVerifyOtpIsLoading,
      isSuccess: advisoryBoardMemberVerifyOtpIsSuccess,
      error: advisoryBoardMemberVerifyOtpIsError,
    },
  ] = useAdvisoryBoardVerifyOtpMutation();

  const {
    data: consultantData,
    isSuccess: consultantIsSuccess,
    error: consultantError,
  } = useCheckConsultantQuery(
    { identifier: identifier },
    {
      skip: userType === "organization" || userType === "advisoryBoard",
    }
  );

  const [resendOtp, { isSuccess: resendOtpIsSuccess }] = useResendOtpMutation();

  const handleOtpVerification = () => {
    const userTypes = ["organization", "advisoryBoard"];
    const verifyFunctions = [orgVerifyOtp, advisoryBoardMemberVerifyOtp];

    const userTypeIndex = userTypes.indexOf(userType);
    if (userTypeIndex !== -1) {
      verifyFunctions[userTypeIndex]({
        email: email,
        fullName: fullName,
        identifier: identifier,
        code: otp,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: "OTP Verified Successfully" }));
          setOtpVerifyStatus(true);
          dispatch(
            setUserHandler({
              email: res?.data?.user?.email,
              userName: fullName,
              userType: res?.data?.user?.userType,
              userId: res?.data?.user?._id,
            })
          );
          dispatch(
            userActions.setTrackingCode({
              trackingCode: res?.data?.trackingCode,
            })
          );
          navigate({
            pathname: "/auth/verifySuccess",
            search: `?userType=${res?.data?.user?.userType}`,
          });
        })
        .catch((e) => {
          setOtpVerifyStatus(false);
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
          navigate({
            pathname: "/auth/verifyFailure",
            search: `?identifier=${identifier}&userType=${userType}&email=${email}&fullName=${fullName}`,
          });
        });
    }
  };

  const handleResendOtp = () => {
    if (timmer === "0") {
      resendOtp({
        email: email,
        registerUserType: userType,
      })
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "OTP Resend Successfully" }));
          setTimmer("30");
        })
        .catch((e) => {
          dispatch(showError({ message: e?.data?.message }));
        });
    }
  };

  const handleRedirectBackToOtpVerifyScreen = () => {
    setVerificationFailureStatus(false);
  };

  const handleVerifyOtp = () => {
    if (orgVerifyOtpData?.data?.user?.stageAdditionalDetails === true) {
      navigate("/auth/registration/loginCredentials");
    } else if (orgVerifyOtpData?.data?.user?.stageOrgDetails === true) {
      navigate("/auth/registration/additionalDetails");
    } else if (orgVerifyOtpData?.data?.user?.stageBasic === true) {
      navigate("/auth/registration/organizationDetails");
    } else {
      navigate("/auth/registration/basicDetails");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (timmer > 0) {
        // Update timer display
        setTimmer(`${timmer - 1}`);
      }
    }, 1000);
  }, [timmer]);

  useEffect(() => {
    if (consultantIsSuccess) {
      navigate("/auth/registration/basicDetails");
    }
  }, [consultantIsSuccess, navigate]);

  console.log("setTimer", timmer);
  console.log({ resendInstant });

  return (
    <div className="container signUp_section">
      {orgVerifyOtpIsLoading ||
        (advisoryBoardMemberVerifyOtpIsLoading && <PageLoader />)}
      <div className="signSec_Col1">
        <img className="signUp_topBg" src={SignUpTopBg} />
        <img className="signUp_bottomBg" src={SignUpBottomBg} />
        <img className="signUp_leftBg" src={SignUpLeftBg} />
        <div className="signUp_logoDiv">
          <img src={RegistrationLogo} />
        </div>
        <div className="signUp_divContents">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="signUp_leftContent">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>

        <div className="copyRight_div">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>

      <div className="signSec_Col2">
        {verificationFailureStatus && (
          <div className="verifiFailedSec">
            <h1>Uh oh! Verification Failed</h1>
            <p>
              Looks like something went wrong while verifying your email ID.
              Please retry verification to proceed
            </p>
            <div
              className="cursorPointer verifiOtpBtn"
              onClick={handleRedirectBackToOtpVerifyScreen}
            >
              <FormButton
                buttonName={"Retry Verification"}
                btnWidth={"100%"}
                backgroundColor={"#008BB2"}
                buttonText={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
          </div>
        )}
        {verificationSuccessStatus && (
          <div className="verifySuccessSec">
            <h1>Verification Successful</h1>
            <p>
              Your email ID was successfully verified. Please continue with your
              account registration to start your rating process
            </p>
            <div
              className="cursorPointer verifiOtpBtn"
              onClick={handleVerifyOtp}
            >
              <FormButton
                buttonName={"Continue Registration"}
                btnWidth={"100%"}
                backgroundColor={"#008BB2"}
                buttonText={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
          </div>
        )}
        {!verificationFailureStatus && !verificationSuccessStatus && (
          <div>
            <div className="OtpHeadingSec">
              <h1>One Time Password </h1>
              <p>
                Please enter the code you have received a code on your email ID
              </p>
              <div className="EditNameSec">
                <span>{email}</span>
                <div
                  className="EditNameDiv cursor-pointer"
                  onClick={() => {
                    navigate("/auth/signup");
                  }}
                >
                  <img src={EditIcon} />
                  <p className="nameEdit">Change</p>
                </div>
              </div>
            </div>
            <OtpVerification otp={otp} setOtp={setOtp} />
            <div
              className="cursorPointer verifiOtpBtn"
              onClick={handleOtpVerification}
            >
              <FormButton
                buttonName={"Verify OTP"}
                btnWidth={"100%"}
                backgroundColor={"#008BB2"}
                buttonText={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="Otp_title_container">
              <p
                className={`Otp_title count_down_mb ${
                  timmer !== "0" && "notAllowed"
                }`}
                onClick={handleResendOtp}
              >
                Resend OTP
                {timmer === "0" ? (
                  ""
                ) : (
                  <span style={{ color: "red" }}>
                    {" "}
                    (0:{timmer.length < 2 ? `0${timmer}` : timmer}){" "}
                  </span>
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailVerification;
