import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getUserFromLocal, removeAuthFromLocal } from "../../../utils/storage";
import { useDispatch } from "react-redux";
import { authActions } from "../../../features/auth/authSlice";
import {
  useGetDashBoardStatusQuery,
  useGetStageQuery,
} from "../../../features/status/statusApiSlice";

import InputBox from "../../../components/InputBox/InputBox";
import TextArea from "../../../components/InputBox/textAreaBox/TextArea";
import PageLoader from "../../../components/Loader/PageLoader";
import Notifications from "../../../components/indicationMessage/Notifications";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import Location from "../../../assets/icons/location.svg";
import BellIcon from "../../../assets/icons/bell.svg";
import UserProfileImg from "../../../assets/images/userImg.png";
import FeedbackImage from "../../../assets/icons/feedbackImage.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import PreviousFeedback from "../../../components/dashboardComponents/PreviousFeedback";
import FAQ from "../../../components/dashboardComponents/FAQ";


const feedbackSchema = Yup.object({
  feedbackSubject: Yup.string()
    .required("Required")
    .trim()
    .matches(/^[A-Za-z\s]+$/, "Enter your feedback subject")
    .max(50)
    .min(3, "Feedback must be at least 5 characters"),
  feedback: Yup.string()
    .required("Required")
    .min(5, "Please Enter at least 5 characters"),
});

function UserFeedback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logOutPopUp, setLogOutPopUp] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(prevState => !prevState);
  };

  const toggleLogOut = () => {
    setLogOutPopUp(!logOutPopUp);
  };

  const handleNavigateToRating = () => {
    navigate("/organizationRating");
  };

  const handleNavigateToDashboard = () => {
    navigate("/dashboard");
  };

  const handleNavigateToFeedback = () => {
    navigate("/userFeedBack");
  };

  const {
    data: dashBoardStatusData,
    isLoading: dashBoardStatusIsLoading,
    isSuccess: dashBoardStatusIsSuccess,
    error: dashBoardStatusError,
    isError: dashBoardStatusIsError,
    isFetching: dashboardStatusIsFetching,
  } = useGetDashBoardStatusQuery({});

  const {
    data: stageData,
    isLoading: stageIsLoading,
    isSuccess: stageIsSuccess,
    error: stageError,
    isError: stageIsError,
    isFetching: stageIsFetching,
  } = useGetStageQuery({});


  const formik = useFormik({
    initialValues: {
      feedbackSubject: "",
      feedback: "",
    },
    enableReinitialize: true,
    validationSchema: feedbackSchema,

    onSubmit: (values) => {
      console.log("🚀 ~ file: GetInTouch.js:65 ~ GetInTouch ~ values:", values);
    },
  });

  return (
    <div className="container dashboardSection">
      {(stageIsLoading ||
        dashBoardStatusIsLoading ||
        dashboardStatusIsFetching ||
        stageIsFetching) && <PageLoader />}
      <div className="dashtitleDiv">
        <div className=" logoSec wd-50">
          <img src={EmissionDecLogo} alt="" />
          <ul className="dashUl">
            <li onClick={handleNavigateToDashboard}>Dashboard</li>
            <li onClick={handleNavigateToRating}>My Rating</li>
            <li onClick={handleNavigateToFeedback}>FeedBack</li>
            <li>Help & Support</li>
          </ul>
        </div>
        <div className="dashProfil wd-50">
          <div className="city">
            <p>Dubai</p>
            <img src={Location} />
          </div>
          <div className="rightBorder"></div>
          <div className="city" style={{ position: "relative" }} onClick={toggleNotifications}>
            <img src={BellIcon} />
          </div>
          {showNotifications && <Notifications />}

          <div className="rightBorder"></div>
          <div className="profileImg" onClick={toggleLogOut}>
            <img src={UserProfileImg} />
          </div>
        </div>
      </div>
      <div className="dashboardInner feedbackInnerSec">
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <p className="feedbackTitle">Feedbacks</p>
          <div className="haveFeedbackContainer">
            <div className="feedbackInpCol1">
              <img src={FeedbackImage} alt="feedbackImage" />
            </div>
            <div className="feedbackInpCol2">
              <h1>Have some feedback to share with us? Let us know and we’ll work on it</h1>
              <p>Send us a feedback and we will get back to you in a short while with an answer</p>
              <div className="feedbackForm">
                <InputBox
                  headName={"Feedback Subject"}
                  placeholder="Type here..."
                  name="feedbackSubject"
                  value={formik.values?.feedbackSubject}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.feedbackSubject &&
                    formik.errors.feedbackSubject
                  }
                  required
                />
                <TextArea
                  headName={""}
                  type="text"
                  placeholder="Type here..."
                  name="feedback"
                  value={formik.values.feedback}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched.feedback && formik.errors.feedback
                  }
                  errorIcon={WarningIcon}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <p className="feedbackTitle">Your Previous Feedbacks</p>
          <div className="previousfeedbackTable">
            <PreviousFeedback />
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <p className="feedbackTitle">Frequently Asked Questions</p>
          <div className="faqcontainer">
            <FAQ
              question={"Lorem ipsum dolor sit amet consectetur?"}
              answer={"Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio."}
            />
          </div>
          <div className="faqcontainer">
            <FAQ
              question={"Lorem ipsum dolor sit amet consectetur?"}
              answer={"Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio."}
            />
          </div>
          <div className="faqcontainer">
            <FAQ
              question={"Lorem ipsum dolor sit amet consectetur?"}
              answer={"Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio."}
            />
          </div>
          <div className="faqcontainer">
            <FAQ
              question={"Lorem ipsum dolor sit amet consectetur?"}
              answer={"Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio."}
            />
          </div>
        </div>

      </div>
      <div className="dashboardCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>

      {logOutPopUp && (
        <div className="LogOutContainer cursor-pointer">
          <div
            className="cursor-pointer"
            onClick={() => {
              removeAuthFromLocal();
              dispatch(authActions.logout());
            }}
          >
            <h4 className="cursor-pointer colorWhite"> Log Out </h4>
          </div>
          <div>{/* <h4 className="">Sign In </h4> */}</div>
        </div>
      )}
    </div>
  );
}

export default UserFeedback;
