import { useEffect, useState } from "react";
import FormButton from "../button/FormButton";

import "./style.css";

import TimerIcon from "../../assets/icons/Timer.svg";
import RightCheck from "../../assets/icons/Check.svg";
import AddIcon from "../../assets/icons/AddPlusBtn.svg";
import AddConsultantPopUp from "../PopUp/consAuditorPopUp.js/AddConsultantAndAuditorPopUp";
import { useNavigate } from "react-router-dom";
import StatusTab from "../tabs/StatusTab";
import ContainedButton from "../button/ContainedButton";
import RightArrow from "../../assets/icons/continueRightArrow.svg";
import OutlinedButton from "../button/OutlinedButton";
import DeclerationTimeline from "../dashTimeline/DeclerationTimline";
import ViewEditsIcon from "../../assets/icons/ViewEditsIcon.svg";

function AuditorTimeline({ status, stageData }) {
  const [addConsultantPopUpOpen, setAddConsultantPopUpOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Add Consultant");
  const navigate = useNavigate();

  const handleAddConsultantPopUpOpen = () => {
    setPopupTitle("Add Consultant");
    setAddConsultantPopUpOpen(true);
  };

  const handleAddAuditorPopUpOpen = () => {
    setPopupTitle("Add Auditor");
    setAddConsultantPopUpOpen(true);
  };

  const handleAddConsultantPopUpClose = () => {
    setAddConsultantPopUpOpen(false);
  };

  const handleOpenEmissionDeclerationForm2 = () => {
    navigate("/emissionStep2");
  };

  const handleOpenRoadmapAudit = () => {
    navigate("/emissionStep4");
  };

  return (
    <div className="orgDashTimeContainer">
      <div className="orgRatingStatus">
        <div className="orgRatingCo1">
          <div className="updateBtnSec">
            <p>Rating Status</p>
            {(stageData?.data?.stage === "Emission Resubmit" ||
              stageData?.data?.stage === "Roadmap Resubmit") &&
            stageData?.data?.isRejected === false ? (
              <StatusTab text="Update Required" type="pending" />
            ) : stageData?.data?.isRejected === true ? (
              <StatusTab text="Rejected" type="rejected" />
            ) : (
              <StatusTab text="Active" type="active" />
            )}
          </div>
        </div>

        {stageData?.data?.stage === "Initial Declaration" ? null : stageData
            ?.data?.stage === "Emission Declaration" ? null : stageData?.data
            ?.stage === "Emission Audit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View Edits"
              bgColor="bgWhite"
              type="button"
              leftIcon={ViewEditsIcon}
              fontColor={"#A20074"}
              borderColor={"1px solid #A20074"}
              // onClick={handleOpenEmissionDeclerationForm2}
            />
            <ContainedButton
              text="Audit Emisssions"
              bgColor="bgBlue"
              type="button"
              onClick={handleOpenEmissionDeclerationForm2}
              rightIcon={RightArrow}
            />
          </div>
        ) : stageData?.data?.stage === "Emission Resubmit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
          </div>
        ) : stageData?.data?.stage === "Roadmap Submission" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
          </div>
        ) : stageData?.data?.stage === "Roadmap Audit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View Edits"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
            <ContainedButton
              text="Audit Roadmap"
              bgColor="bgBlue"
              type="button"
              onClick={handleOpenRoadmapAudit}
              rightIcon={RightArrow}
            />
          </div>
        ) : stageData?.data?.stage === "Roadmap Resubmit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
          </div>
        ) : null}

        {stageData?.data?.isRejected === true && (
          <div className="orgRatingCo2">
            <ContainedButton
              text="Register Again"
              bgColor="bgBlue"
              type="button"
            />
          </div>
        )}
      </div>
      <p className="orgDeclarText declarePadd">
        {(() => {
          switch (stageData?.data?.stage) {
            case "Initial Declaration":
              return "Emissions have not been declared by the consultant. Please wait until they are declared.";
            case "Emission Declaration":
              return "Emission Declaration is in progress. You can review them once the consultant submits them.";
            case "Emission Audit":
              return "The declarations are ready to be audited. Start the audit for emissions now to proceed.";
            case "Emission Resubmit":
              return "The consultant is working on updating the declaration based on your comments. Please wait to reaudit them.";
            case "Roadmap Submission":
              return "The roadmap documents are yet to be submitted by the consultant. Please wait until they are uploaded.";
            case "Roadmap Audit":
              return "The roadmap documents are ready to be audited. Start auditing them to proceed.";
            case "Roadmap Resubmit":
              return "The consultant is working on updating the roadmap based on your comments. Please wait to reaudit them.";
            case "Grant Rating":
              return "Our AI system is working on providing you a rating based on your declarations and roadmap at the moment.";
            case "Monitor & Report":
              return "Your organization is due for it’s quarterly / yearly Monitoring & Reporting.  Your consultant is working on it right now.";
            default:
              return "";
          }
        })()}
      </p>
      <DeclerationTimeline status={stageData?.data?.stage} />
    </div>
  );
}

export default AuditorTimeline;
