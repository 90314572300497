import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';

import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScopEmissonTable from '../scopEmissonTable/ScopEmissonTable';
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';

function InfoOnEmissionPart1({formik}) {
    const ghgGasRow = [
        { title: "Scope 1 Emissions" },
        { title: "Scope 2 Emissions" },
        { title: "Scope 3 Emissions (Applicable only if Scope 3 is included)" },
    ];

    const ghgGasData = [
        {
          index: "1",
          name: "CO2 (mt)",
          value: "123445",
        },
        {
          index: "2",
          name: "CH2 (mt)",
          value: "234123412",
        },
        {
          index: "3",
          name: "N2O(mt)",
          value: "12342342",
        },
        {
          index: "4",
          name: "HFCs (mt)",
          value: "12342342",
        },
        {
          index: "5",
          name: "PFCs (mt)",
          value: "12342342",
        },
        {
          index: "6",
          name: "SF6 (mt)",
          value: "12342342",
        },
        {
          index: "7",
          name: "Total (MTCO2E)",
          value: "123414323",
          bgColorClass: "greenBg",
        },
      ];
    

  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4a-content"
        id="panel4a-header"
      >
        <div className='typographyRow'>
          <Typography>
          <div className='GenrlInfo'>
              <h3>INFORMATION ON EMISSIONS - Part 1</h3>
          </div>
          </Typography>
          <div className='editBtnRow'>
              <NewCommentsBtn btnTitle={"2 New Comments"} />
              <EditButton />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='infoPartOneContainer'>
            <div className='emissPartOneSec'>
                <h1>
                    The table below refers to emissions independent of any GHG trades such as sales, 
                    purchases, transfers, or banking of allowances
                </h1>
                <ScopEmissonTable scopeTitle="Scope 1 Emissions" />
                <ScopEmissonTable scopeTitle="Scope 2 Emissions" />
                <ScopEmissonTable scopeTitle="Scope 3 Emissions (Applicable only if Scope 3 is included)" />
            </div>
            <div className='infoPartDirectRow'>
                <h1>Direct CO2 emissions from Biogenic combustion (mtCO2)</h1>
                <p>1234567</p>
            </div>
            <div className='viewCmtRow'>
                <ViewCommentsBtn 
                  secName={"Information On Emission - Part1"} 
                  question={"Direct CO2 emissions from Biogenic combustion (mtCO2)"} 
                  answer={"1234567"} 
                />
              </div>
        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default InfoOnEmissionPart1;