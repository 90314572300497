import React from 'react';

import "./style.css";

function ViewDeclarationBtn() {
  return (
    <div className='viewDeclarationCon cursorPointer'>
      <p>View Declaration</p>
    </div>
  )
}

export default ViewDeclarationBtn;