import apiSlice from "../../app/api/apiSlice";

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userList: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/admin/v1/user/find-all${queryString}`,
        };
      },
      providesTags: ["AdminFindAll"],
    }),
    getUser: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/admin/v1/user/find-one${queryString}`,
        };
      },
      providesTags: ["AdminFindOne"],
    }),

    pendingUserListCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/admin/v1/user/count-pending${queryString}`,
        };
      },
      providesTags: ["PendingUserListCount"],
    }),

    getRegistrationLogs: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/admin/v1/user/registration-logs${queryString}`,
        };
      },
    }),

    approveUserStatus: builder.mutation({
      query: (info) => ({
        url: "/admin/v1/user/approve-user",
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["PendingUserListCount","AdminFindOne","AdminFindAll"],
    }),

    userStatusChange: builder.mutation({
      query: (info) => ({
        url: "/admin/v1/user/update",
        method: "POST",
        body: info,
      }),
      invalidatesTags: ["AdminFindAll", "PendingUserListCount"],
    }),
  }),
});

export const {
  useUserListQuery,
  useGetUserQuery,
  usePendingUserListCountQuery,
  useGetRegistrationLogsQuery,
  useApproveUserStatusMutation,
  useUserStatusChangeMutation,
} = adminApiSlice;
