import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentMedical} from "@fortawesome/free-solid-svg-icons";

import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';

import "./style.css";
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';

function OperaBoundariesView({formik}) {

  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <div className='typographyRow'>
          <Typography>
          <div className='GenrlInfo'>
              <h3>Operational Boundaries</h3>
          </div>
          </Typography>
          <div className='editBtnRow'>
              <NewCommentsBtn btnTitle={"2 New Comments"} />
              <EditButton />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='operaInventaryContainer'>
          <div className='operatScopeRow'>
            <div className='operatScopeCol1'>
              <h1>Are Scope 3 emissions included in this inventory?</h1>
            </div>
            <div className='operatScopeCol2'>
              <p>Yes</p>
            </div>
          </div>
            <div className='viewCmtRow'>
              <ViewCommentsBtn
                secName={"Operational Boundaries"} 
                question={"Are Scope 3 emissions included in this inventory?"} 
                answer={"Yes"} 
              />
            </div>
          <div className='operatScopeRow'>
            <div className='operatScopeCol1'>
              <h1>If yes, which types of activities are included in Scope 3 emissions?</h1>
            </div>
            <div className='operatScopeCol2'>
              <p>
                Lorem ipsum dolor sit amet consectetur. 
                Metus at maecenas quis scelerisque pharetra consequat sit. 
                Sollicitudin amet magnis proin vel turpis quis 
                aliquam pretium. Dignissim arcu adipiscing neque nulla tristique.
              </p>
            </div>
          </div>
            <div className='viewCmtRow'>
              <ViewCommentsBtn
                secName={"Operational Boundaries"} 
                question={"If yes, which types of activities are included in Scope 3 emissions?"} 
                answer={"Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra consequat sit. Sollicitudin amet magnis proin vel turpis quis aliquam pretium. Dignissim arcu adipiscing neque nulla tristique."} 
              />
            </div>
        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default OperaBoundariesView;