import React from 'react';

import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';
import YearQuarterCard from '../yearQuarterCard/YearQuarterCard';

import "./style.css";

import RightArrow from "../../../../assets/icons/Arrow_Right_LG.svg";
import YearQuarterTimeline from '../../../chatBox/chatBoxComponents/yearQuarterTimeline/YearQuarterTimeline';


function TimelinePreview() {
  return (
    <div className='timelinePreCon'>
        <h1 className='timelineTitle'>Timeline</h1>
        <div className="timelinePreRowCon">
            <div className='timelinePreRow'>
                <div className='timelinePreCol1'>
                    <h2>
                        Planning
                    </h2>
                </div>
                <div className="timelinePreCol2">
                    <YearQuarterCard />
                    <img src={RightArrow} alt='RightArrow'/>
                    <YearQuarterCard />
                </div>
            </div>
            <div className='viewCmtRow'>
                <ViewCommentsBtn
                    secName={"Timeline"} 
                    question={"Timeline Planning"} 
                    // answer={"Lorem ipsum dolor sit amet consectetur."} 
                    answer={<YearQuarterTimeline />}
                    />
            </div>
        </div>
        <div className="timelinePreRowCon">
            <div className='timelinePreRow'>
                <div className='timelinePreCol1'>
                    <h2>
                        Execution
                    </h2>
                </div>
                <div className="timelinePreCol2">
                    <YearQuarterCard />
                    <img src={RightArrow} alt='RightArrow'/>
                    <YearQuarterCard />
                </div>
            </div>
            <div className='viewCmtRow'>
                <ViewCommentsBtn
                    secName={"Timeline"} 
                    question={"Timeline Execution"} 
                    answer={"Lorem ipsum dolor sit amet consectetur."} 
                />
            </div>
        </div>
        <div className="timelinePreRowCon">
            <div className='timelinePreRow'>
                <div className='timelinePreCol1'>
                    <h2>
                        Monitoring
                    </h2>
                </div>
                <div className="timelinePreCol2">
                    <YearQuarterCard />
                    <img src={RightArrow} alt='RightArrow'/>
                    <YearQuarterCard />
                </div>
            </div>
            <div className='viewCmtRow'>
                <ViewCommentsBtn
                    secName={"Timeline"} 
                    question={"Timeline Monitoring"} 
                    answer={"Lorem ipsum dolor sit amet consectetur."} 
                />
            </div>
        </div>
    </div>
  )
}

export default TimelinePreview;