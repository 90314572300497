import React, { useEffect, useRef, useState } from "react";
import AddComments from "./AddComments/AddComments";
import ViewComments from "./ViewComments/ViewComments";
import {
  useAddChatMutation,
  useLazyGetChatsQuery,
  useLazyGetIssueQuery,
} from "../../features/steps/declerationApiSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import { create } from "@mui/material/styles/createTransitions";
import PageLoader from "../Loader/PageLoader";
import {
  useEmissionCreateIssueMutation,
  useEmissionUpdateIssueMutation,
} from "../../features/steps/emissionApiSlice";

const EmissionComments = ({
  issueId,
  arrayId,
  mapping,
  declarationId,
  secName,
  question,
  answer,
}) => {
  let commentButton = null;

  const [type, setType] = useState("Corrective Action Required");
  const [summary, setSummary] = useState();
  const [description, setDescription] = useState();
  const [openChatBox, setOpenChatBox] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const subMenuRef = useRef(null);

  const [
    getIssues,
    {
      data: issue,
      isLoading: issueIsLoading,
      isSuccess: issueIsSuccess,
      error: issueError,
      isError: issueIsError,
      isFetching: issueIsFetching,
    },
  ] = useLazyGetIssueQuery();
  const [
    getChats,
    {
      data: chats,
      isLoading: chatsIsLoading,
      isSuccess: chatsIsSuccess,
      error: chatsError,
      isError: chatsIsError,
      isFetching: chatsIsFetching,
    },
  ] = useLazyGetChatsQuery();

  const [
    emissionCreateIssue,
    {
      data: emissionCreateIssueData,
      isLoading: emissionCreateIssueIsLoading,
      isSuccess: emissionCreateIssueIsSuccess,
      error: emissionCreateIssueError,
      isError: emissionCreateIssueIsError,
    },
  ] = useEmissionCreateIssueMutation();

  const [
    emissionUpdateIssue,
    {
      data: emissionUpdateIssueData,
      isLoading: emissionUpdateIssueIsLoading,
      isSuccess: emissionUpdateIssueIsSuccess,
      error: emissionUpdateIssueError,
      isError: emissionUpdateIssueIsError,
    },
  ] = useEmissionUpdateIssueMutation();

  const [
    createChat,
    {
      data: createChatData,
      isLoading: createChatIsLoading,
      isSuccess: createChatIsSuccess,
      error: createChatError,
      isError: createChatIsError,
      isFetching: createChatIsFetching,
    },
  ] = useAddChatMutation();

  const handleChatBoxOpen = () => {
    setOpenChatBox(true);
  };
  const handleChatBoxClose = () => {
    setOpenChatBox(false);
    setDescription("");
    setType("");
  };

  const handleCreateIssueAndChats = () => {
    if (issueId) {
      createChat({
        declarationId: declarationId,
        issueId: issueId,
        message: description,
      })
        .unwrap()
        .then(() => {
          setDescription("");
          dispatch(showSuccess({ message: `Chat Added successfully` }));
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else {
      arrayId
        ? emissionCreateIssue({
            declarationId: declarationId,
            mapping: mapping,
            type: type,
            arrayId: arrayId,
            summary: summary,
            description: description,
          })
            .unwrap()
            .then(() => {
              handleChatBoxClose();
              dispatch(showSuccess({ message: "Issue Created Successfully" }));
            })
            .catch((e) => {
              dispatch(
                showError({
                  message: e?.data?.message ?? "Something went wrong",
                })
              );
            })
        : emissionCreateIssue({
            declarationId: declarationId,
            mapping: mapping,
            type: type,
            summary: summary,
            description: description,
          })
            .unwrap()
            .then(() => {
              handleChatBoxClose();
              dispatch(showSuccess({ message: "Issue Created Successfully" }));
            })
            .catch((e) => {
              dispatch(
                showError({
                  message: e?.data?.message ?? "Something went wrong",
                })
              );
            });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !subMenuRef.current?.contains(event.target)
      ) {
        setOpenMenu(false);
        setOpenSubMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef, subMenuRef]);

  const handleMenuOptions = (event) => {
    event.stopPropagation();
    setOpenMenu(!openMenu);
  };

  const handleSubMenuOptions = (event) => {
    event.stopPropagation();
    setOpenSubMenu(!openSubMenu);
  };

  const handleSelectValue = (value) => {
    if (
      value === "Clarification" ||
      value === "Recommendation" ||
      value === "Corrective Action Required"
    ) {
      setOpenMenu(false);
      setOpenSubMenu(false);
      emissionUpdateIssue({
        declarationId: declarationId,
        issueId: issueId,
        updateType: "changeType",
        type: value,
      })
        .unwrap()
        .then(() => {
          handleChatBoxClose();
          dispatch(showSuccess({ message: `Issue Updated successfully` }));
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else {
      setOpenMenu(false);
      setOpenSubMenu(false);
      emissionUpdateIssue({
        declarationId: declarationId,
        issueId: issueId,
        updateType: value,
      })
        .unwrap()
        .then(() => {
          handleChatBoxClose();
          dispatch(showSuccess({ message: `Issue Updated successfully` }));
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    }
  };

  const handleGetChatsAndIssues = () => {
    getIssues({
      issueId: issueId,
      declarationId: declarationId,
    });
    getChats({
      issueId: issueId,
      declarationId: declarationId,
    });
  };

  if (issueId) {
    commentButton = (
      <ViewComments
        mapping={mapping}
        issueId={issueId}
        arrayId={arrayId}
        declarationId={declarationId}
        secName={secName}
        question={question}
        answer={answer}
        description={description}
        setDescription={setDescription}
        openChatBox={openChatBox}
        openMenu={openMenu}
        openSubMenu={openSubMenu}
        menuRef={menuRef}
        subMenuRef={subMenuRef}
        handleGetChatsAndIssues={handleGetChatsAndIssues}
        handleChatBoxClose={handleChatBoxClose}
        handleChatBoxOpen={handleChatBoxOpen}
        issue={issue}
        chats={chats}
        handleCreateIssueAndChats={handleCreateIssueAndChats}
        handleMenuOptions={handleMenuOptions}
        handleSubMenuOptions={handleSubMenuOptions}
        handleSelectValue={handleSelectValue}
        chatsIsLoading={chatsIsLoading}
        chatsIsFetching={chatsIsFetching}
        issuesIsLoading={issueIsLoading}
        createChatIsLoading={createChatIsLoading}
        createChatIsFetching={createChatIsFetching}
      />
    );
  } else {
    commentButton = (
      <AddComments
        mapping={mapping}
        issueId={issueId}
        arrayId={arrayId}
        declarationId={declarationId}
        secName={secName}
        question={question}
        answer={answer}
        type={type}
        setType={setType}
        summary={summary}
        setSummary={setSummary}
        description={description}
        setDescription={setDescription}
        handleCreateIssueAndChats={handleCreateIssueAndChats}
        openChatBox={openChatBox}
        setOpenChatBox={setOpenChatBox}
        handleChatBoxClose={handleChatBoxClose}
        handleChatBoxOpen={handleChatBoxOpen}
      />
    );
  }

  console.log("createChatIsLoading", createChatIsLoading);

  return (
    <>
      {emissionCreateIssueIsLoading ||
        chatsIsLoading ||
        issueIsLoading ||
        issueIsLoading ||
        chatsIsLoading ||
        chatsIsFetching ||
        (emissionUpdateIssueIsLoading && <PageLoader />)}
      {commentButton}
    </>
  );
};

export default EmissionComments;
