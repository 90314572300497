import React, { useEffect, useRef, useState } from "react";

import ChatCommentInputBox from "../../../../InputBox/chatCommentInputBox/ChatCommentInputBox";
import ChatBoxSubmitBtn from "../../../../button/chatBoxSubmitBtn/ChatBoxSubmitBtn";
import SummarizeIssueInput from "../../../../InputBox/summarizeIssueInput/SummarizeIssueInput";
import RecommendedOptions from "../recommendedOptions/RecommendedOptions";

import "./style.css";

import ThreeDotsIcon from "../../../../../assets/icons/threeDotsIcon.svg";
import { useEmissionUpdateIssueMutation } from "../../../../../features/steps/emissionApiSlice";
import ChatFooterMenu from "../chatFooterMenu/ChatFooterMenu";
import { useDispatch } from "react-redux";
import { showSuccess } from "../../../../../features/snackbar/snackbarAction";
import { useNavigate } from "react-router-dom";

function ChatBoxFooter({
  kebabMenu = true,
  mapping,
  issueId,
  declarationId,
  arrayId,
  type,
  setType,
  summary,
  setSummary,
  description,
  setDescription,
  handleCreateIssue,
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const subMenuRef = useRef(null);

  const [
    emissionUpdateIssue,
    {
      data: emissionUpdateIssueData,
      isLoading: emissionUpdateIssueIsLoading,
      isSuccess: emissionUpdateIssueIsSuccess,
      error: emissionUpdateIssueError,
      isError: emissionUpdateIssueIsError,
    },
  ] = useEmissionUpdateIssueMutation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !subMenuRef.current?.contains(event.target)
      ) {
        setOpenMenu(false);
        setOpenSubMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef, subMenuRef]);

  const handleMenuOptions = (event) => {
    event.stopPropagation();
    setOpenMenu(!openMenu);
  };

  const handleSubMenuOptions = (event) => {
    event.stopPropagation();
    setOpenSubMenu(!openSubMenu);
  };

  const handleSelectValue = (value) => {
    console.log("value", value);
    if (
      value === "Clarification" ||
      value === "Recommendation" ||
      value === "Corrective Action Required"
    ) {
      setOpenMenu(false);
      setOpenSubMenu(false);
      emissionUpdateIssue({
        declarationId: declarationId,
        issueId: issueId,
        updateType: "changeType",
        type: value,
      })
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Updated Successfully" }));
        });
    } else {
      setOpenMenu(false);
      setOpenSubMenu(false);
      emissionUpdateIssue({
        declarationId: declarationId,
        issueId: issueId,
        updateType: value,
      })
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Updated Successfully" }));
        });
    }
  };

  console.log("openMenu", openMenu);
  console.log("openSubMenu", openSubMenu);
  console.log("issueId", issueId);

  return (
    <footer className="chatBoxFootContainer">
      {!issueId && (
        <>
          <RecommendedOptions type={type} setType={setType} />
          <SummarizeIssueInput summary={summary} setSummary={setSummary} />
        </>
      )}
      <div className="chatBoxSubmitBtnRow">
        {issueId && (
          <div className="cursorPointer" onClick={handleMenuOptions}>
            <img src={ThreeDotsIcon} alt="ThreeDotsIcon" />
          </div>
        )}
        <ChatCommentInputBox
          description={description}
          setDescription={setDescription}
        />
        <ChatBoxSubmitBtn handleCreateIssue={handleCreateIssue} />
      </div>
      {openMenu && issueId && (
        <ChatFooterMenu
          menuRef={menuRef}
          subMenuRef={subMenuRef}
          openSubMenu={openSubMenu}
          handleSubMenuOptions={handleSubMenuOptions}
          handleSelectValue={handleSelectValue}
        />
      )}
    </footer>
  );
}

export default ChatBoxFooter;
