import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import "./style.css";
import Comments from "../../../chats/Comments";

function InitLocationPreview({ value }) {
  return (
    <div className="locationPreCon">
      <h1 className="locationTitle">LOCATIONS</h1>
      <div className="locationPreRowCon">
        <div className="locationPreRow">
          <div className="locationPreCol1">
            <h2>Location 1</h2>
          </div>
          <div className="locationPreCol2">
            <p>{value?.location1}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          {/* <ViewCommentsBtn
            secName={"Locations"}
            question={"Location 1"}
            answer={"Lorem ipsum dolor sit amet consectetur."}
          /> */}
          {
            <Comments
              secName={"Locations"}
              question={"Location 1"}
              answer={"Lorem ipsum dolor sit amet consectetur."}
            />
          }
        </div>
      </div>
      <div className="locationPreRowCon">
        <div className="locationPreRow">
          <div className="locationPreCol1">
            <h2>Location 2</h2>
          </div>
          <div className="locationPreCol2">
            <p>{value?.location2}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          <ViewCommentsBtn
            secName={"Locations"}
            question={"Location 2"}
            answer={"Lorem ipsum dolor sit amet consectetur."}
          />
        </div>
      </div>
    </div>
  );
}

export default InitLocationPreview;
