import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import "./style.css";

function ExternalPartnerPreview({ value }) {
  return (
    <div className="externalPreCon">
      <h1 className="externalTitle">External Partners</h1>
      <div className="externalPreRowCon">
        <div className="externalPreRow">
          <div className="externalPreCol1">
            <h2>Business / Industry</h2>
          </div>
          <div className="externalPreCol2">
            <p>{value?.business}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          <ViewCommentsBtn
            secName={"External Partners"}
            question={"Business / Industry"}
            answer={"Lorem ipsum dolor sit amet consectetur."}
          />
        </div>
      </div>
      <div className="externalPreRowCon">
        <div className="externalPreRow">
          <div className="externalPreCol1">
            <h2>Government</h2>
          </div>
          <div className="externalPreCol2">
            <p>{value?.government}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          <ViewCommentsBtn
            secName={"External Partners"}
            question={"Government"}
            answer={"Lorem ipsum dolor sit amet consectetur."}
          />
        </div>
      </div>
      <div className="externalPreRowCon">
        <div className="externalPreRow">
          <div className="externalPreCol1">
            <h2>Non-Profit Organizations</h2>
          </div>
          <div className="externalPreCol2">
            <p>{value?.nonProfitOrganizations}</p>
          </div>
        </div>
        <div className="viewCmtRow">
          <ViewCommentsBtn
            secName={"External Partners"}
            question={"Non-Profit Organizations"}
            answer={"Lorem ipsum dolor sit amet consectetur."}
          />
        </div>
      </div>
    </div>
  );
}

export default ExternalPartnerPreview;
