import { useEffect, useState } from "react";
import { getUserFromLocal } from "../../../utils/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentMedical } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";


import FormButton from "../../../components/button/FormButton";
import EmisTitleSection from "../../../components/emissionComponents/emisTitleSection/EmisTitleSection";
import GenInfoView from "../../../components/emissionComponents/stepOneView/genInfoView/GenInfoView";
import OrgBoundariesView from "../../../components/emissionComponents/stepOneView/orgBoundariesView/OrgBoundariesView";
import OperaBoundariesView from "../../../components/emissionComponents/stepOneView/operaBoundariesView/OperaBoundariesView";
import InfoOnEmissionPart1 from "../../../components/emissionComponents/stepOneView/infoOnEmissionPart1/InfoOnEmissionPart1";
import MethoFactorView from "../../../components/emissionComponents/stepOneView/methoFactorView/MethoFactorView";
import InfoOnEmissionPart2 from "../../../components/emissionComponents/stepOneView/infoOnEmissionPart2/InfoOnEmissionPart2";
import AdditionalInfoView from "../../../components/emissionComponents/stepOneView/additionalInfoView/AdditionalInfoView";
import InfoOffsetView from "../../../components/emissionComponents/stepOneView/infoOffsetView/InfoOffsetView";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";

const stepTwoSchema = Yup.object().shape({
  generalInfo: Yup.object().shape({
    inventoryYear: Yup.number().required("Inventory Year is required"),
    isInventoryVerified: Yup.object().shape({
      data: Yup.boolean().required("Verification status is required"),
      comment: Yup.string(),
    }),
    verifier: Yup.string().required("Verifier name is required"),
    date: Yup.date().required("Verification date is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    address: Yup.string().required("Verifier address is required"),
    isSourceExcluded: Yup.object().shape({
      data: Yup.boolean().required("Exclusion status is required"),
      comment: Yup.string(),
    }),
    isSourceExcludedSpecify: Yup.string(),
    reportingFrom: Yup.date().required("Reporting from date is required"),
    reportingTo: Yup.object().shape({
      data: Yup.date().required("Reporting to date is required"),
      comment: Yup.string(),
    }),
  }),
});

function EmissionStep2Preview() {
  // const [resposnse, setResoponse] = useState("");
  // const [currentStep, setCurrentStep] = useState('finishing-up');
  // const { data } = resposnse || {};
  // const response = data?.data?.user;
  // const { findOneData} = response || {};
  // console.log(response, "response");

  const trackId = localStorage.getItem("trackId");
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const [resposnse, setResoponse] = useState("");
  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { findYou } = response || {};

  const [findOneData, setFindOneData] = useState(null);
  const [values, setValues] = useState(null);

  const {
    data: findOne,
    isLoading: findOneIsLoading,
    isSuccess: findOneIsSuccess,
    error: findOneError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({});

  useEffect(() => {
    console.log("the findOne data ==>", findOne);
  }, [findOne]);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    // validationSchema: stepTwoSchema,

    onSubmit: (values) => {
      console.log(values, "values");
    },
  });

  return (
    <div className="container emiDecl_section">
      <div className="emissionDec_titleDiv">
        <div className="emiDecLogo">
          <img src={EmissionDecLogo} alt="" />
        </div>
        <div className="emisDec_buttonDiv">
          <div className="emisCancelBtn">
            <FormButton
              buttonName={"Cancel & Exit"}
              buttonText={"#ffffff"}
              backgroundColor={"transparent"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
          <div className="editDeclarBtn">
            <p>Edit Declaration</p>
          </div>
          <div className="emisSubmitBtn">
            <FormButton
              buttonName={"Resubmit Declaration"}
              backgroundColor={"#00637E"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </div>
      </div>

      <div className="emiDecl_innerContainer">
        <div className="emissionDecSec_Col1">
          <div className="emisDec_NowNextStep">
            <div className="emisDecleftTitle">
              <h1>Emission Declaration</h1>
              <div className="nextStep">
                <p>Next Step:</p>
                <h3>Assure/Audit Emissions</h3>
              </div>
            </div>
            <EmisTitleSection />
          </div>
        </div>

        <div className="emissionDecSec_Col2">
          <div className="CodewaveTitleDiv">
            <div className="CodeCol1">
              <div className="InventoryYear">
                <div className="codwaveLogo">
                  <img src={CodeLogo} />
                  <h3>Codewave’s Greenhouse Gas Emissions Inventory</h3>
                </div>
                <div>
                  <p>Inventory Year: 2021</p>
                </div>
              </div>
            </div>
          </div>

          <div class="EmissInnerMainBlock">
            <GenInfoView formik={formik} data={findOne} />
            <OrgBoundariesView formik={formik} />
            <OperaBoundariesView formik={formik} />
            <InfoOnEmissionPart1 formik={formik} />
            <MethoFactorView formik={formik} />
            <InfoOnEmissionPart2 formik={formik} />
            <AdditionalInfoView formik={formik} />
            <InfoOffsetView formik={formik} />
          </div>

          <form className="full_width">
            <div className="form-row bs_BtnRow">
              <div className=" cursorPointer">
                <FormButton
                  buttonName={"Cancel & Exit"}
                  buttonText={"#1D1D1B"}
                  backgroundColor={"transparent"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
              <div className="cancelBtn_Row">
                <div className=" NextBtn cursorPointer revertBtn">
                  <FormButton
                    buttonName={"Save As Draft"}
                    backgroundColor={"#ffffff"}
                    buttonText={"#00637E"}
                    border={"1px solid #008BB2"}
                    btnFont={"14px"}
                    btnPdd={"12px 20px"}
                  />
                </div>
                <div className=" NextBtn cursorPointer">
                  <FormButton
                    buttonName={"Submit Declaration"}
                    backgroundColor={"#00637E"}
                    buttonText={"#ffffff"}
                    btnFont={"14px"}
                    btnPdd={"12px 20px"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default EmissionStep2Preview;
