import apiSlice from "../../app/api/apiSlice";

export const declerationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmissionData: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/declaration/findOne${queryString}`,
        };
      },
      providesTags: ["findOne"],
    }),
    getIssue: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/declaration/get-one-issue${queryString}`,
        };
      },
      providesTags: ["Issues"],
    }),
    addChat: builder.mutation({
      query: (chatBody) => ({
        url: "/rating/v1/declaration/add-chat",
        method: "POST",
        body: chatBody,
      }),
      invalidatesTags: ["Chats", "Issues"],
    }),
    getChats: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/declaration/get-chat${queryString}`,
        };
      },
      providesTags: ["Chats"],
    }),
    getDeclarationInfo: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/declaration/declaration-info${queryString}`,
        };
      },
      providesTags: ["DeclarationInfo"],
    }),
    myRating: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/declaration/my-rating${queryString}`,
        };
      },
      providesTags: ["MyRating"],
    }),
  }),
});

export const {
  useGetEmissionDataQuery,
  useLazyGetIssueQuery,
  useAddChatMutation,
  useLazyGetChatsQuery,
  useGetDeclarationInfoQuery,
  useMyRatingQuery,
} = declerationApiSlice;
