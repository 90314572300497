import react, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import * as Yup from "yup";
import dayjs from "dayjs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getUserFromLocal } from "../../../utils/storage";
import {
  cleanObject,
  cleanObject2,
  cleanObjectExceptKeys,
  removeEmptyKeys,
} from "../../../utils/helper";

import { stepOneSchema } from "../../../Validators/register.validators";

import InputBox from "../../../components/InputBox/InputBox";
import FormButton from "../../../components/button/FormButton";
import SelectInputBox from "../../../components/InputBox/selectBox/SelectInputBox";
import PhoneInputBox from "../../../components/InputBox/phoneInputBox/PhoneInputBox";
import FileUploadBox from "../../../components/fileUploadBox/FileUploadBox";
import PdfUpload from "../../../components/pdfUpload/PdfUpload";
import CommentBox from "../../../components/commentBox/CommentBox";
import TextArea from "../../../components/InputBox/textAreaBox/TextArea";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import UpArraow from "../../../assets/icons/Caret_Circle_Up.svg";
import Circle from "../../../assets/icons/stepOneCircle.svg";
import Triangle from "../../../assets/icons/Triangle.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import EmisTitleSection from "../../../components/emissionComponents/emisTitleSection/EmisTitleSection";
import Calendar from "../../../components/calendar/Calendar";
import GenInformation from "../../../components/emissionComponents/step1/genInformation/GenInformation";
import OrgBoundaries from "../../../components/emissionComponents/step1/orgBoundaries/OrgBoundaries";
import EmisChecklistSection from "../../../components/emissionComponents/emisChecklistSection/EmisChecklistSection";
import EmisGoodPractice from "../../../components/emissionComponents/emisGoodPractice/EmisGoodPractice";
import OperaBoundaries from "../../../components/emissionComponents/step1/operaBoundaries/OperaBoundaries";
import InfoOnEmission from "../../../components/emissionComponents/step1/infoOnEmission/InfoOnEmission";
import MethodoEmission from "../../../components/emissionComponents/step1/methodoEmission/MethodoEmission";
import InfoOnEmission2 from "../../../components/emissionComponents/step1/infoOnEmission2/InfoOnEmission2";
import AdditInformation from "../../../components/emissionComponents/step1/additInformation/AdditInformation";
import InfoOnOffsets from "../../../components/emissionComponents/step1/infoOnOffsets/InfoOnOffsets";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import { urlReg } from "../../../utils/regex";
import RegCancelButton from "../../../components/button/regCancelBtn/RegCancelButton";
import Acknowledgement from "../../../components/emissionComponents/step1/acknowledgement/Acknowledgement";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import {
  useEmissionDraftsMutation,
  useEmissionResubmitMutation,
  useEmissionSubmitMutation,
} from "../../../features/steps/emissionApiSlice";
import { useFormikToastError } from "../../../hooks/error";

const years = [
  { value: 2020, name: 2020 },
  { value: 2021, name: 2021 },
  { value: 2022, name: 2022 },
  { value: 2023, name: 2023 },
];

const baseYears = [
  { value: 2020, name: 2020 },
  { value: 2021, name: 2021 },
  { value: 2022, name: 2022 },
  { value: 2023, name: 2023 },
];

function EmissionsDeclaration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState("generalInfoSection");
  const userType = localStorage.getItem("userType");
  const [resposnse, setResoponse] = useState("");
  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { generalInfo } = response || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [submitButtonState, setSubmitButtonState] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const generalInformationSchema = Yup.object().shape({
    facilitiesExcluded: Yup.object().shape({
      isExcluded: Yup.bool().required("Exclusion status is required"),
      exclusionDetails: Yup.string().when("isExcluded", {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .matches(
              /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
              "Exclusion Details must be alphanumeric and special characters"
            )
            .min(50, "Minimum 10 Characters")
            .max(500, "Maximum 500 Characters")
            .required(
              "Exclusion details are required when facilities are excluded"
            ),
        otherwise: () => Yup.string().nullable(true),
      }),
    }),
    reportingPeriod: Yup.object().shape({
      from: Yup.date().required("Reporting period start date is required"),
      to: Yup.date().required("Reporting period end date is required"),
    }),
  });

  const organizationalBoundariesSchema = Yup.object().shape({
    consolidationApproach: Yup.object().shape({
      equityShare: Yup.boolean().required(),
      financialControl: Yup.boolean().required(),
      operationalControl: Yup.boolean().required(),
    }),
    entities: Yup.array()
      .of(
        Yup.object().shape({
          legalEntityOrFacility: Yup.string()
            .trim()
            .min(3, "Minimum 3 Characters")
            .max(100, "Maximum 100 Characters")
            .matches(
              /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
              "Legal Entity or Facility must be alphanumeric and special characters"
            )
            .required("Legal Entity or Facility is required"),
          percentageEquityShare: Yup.number()
            .min(0, "Minimum 0")
            .max(100, "Maximum 100")
            .required("Percentage Equity Share is required")
            .typeError("Please enter a number"),
          isCompanyHasFinancialControl: Yup.boolean().required(
            "Financial Control is required"
          ),
          isCompanyHasOperationalControl: Yup.boolean().required(
            "Operational Control is required"
          ),
        })
      )
      .min(1, "Atleast one entity is required")
      .max(10, "Maximum 10 entities"),
    orgDiagramFile: Yup.array()
      .of(
        Yup.object().shape({
          fileName: Yup.string().required("File name is required"),
        })
      )
      .min(1, "Atleast one document is required")
      .max(10, "Maximum 10 documents"),
  });

  const operationalBoundariesSchema = Yup.object().shape({
    includeScope3: Yup.boolean().required("Include Scope 3 status is required"),
    scope3ActivityTypes: Yup.string().when("includeScope3", {
      is: true,
      then: () =>
        Yup.string()
          .trim()
          .min(50, "Minimum 50 Characters")
          .max(500, "Maximum 500 Characters")
          .matches(
            /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
            "Scope 3 activity types must be alphanumeric and special characters"
          )
          .required(
            "Scope 3 activity types are required when Scope 3 is included"
          ),
      otherwise: () => Yup.string().nullable(true),
    }),
  });

  const GasValidationSchema = Yup.object().shape({
    notRecorded: Yup.boolean().required(),
    value: Yup.number().when("notRecorded", {
      is: false,
      then: () =>
        Yup.number()
          .min(0, "Minimum 0")
          .max(10000000, "Maximum 10000000")
          .required("Emissions are required")
          .typeError("Please enter a number"),
      otherwise: () => Yup.number().nullable(true),
    }),
    specify: Yup.string().when("notRecorded", {
      is: true,
      then: () =>
        Yup.string()
          .trim()
          .min(5, "Minimum 5 Characters")
          .max(200, "Maximum 200 Characters")
          .matches(
            /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
            "Must be alphanumeric and special characters"
          )
          .required("Please specify the reason"),
      otherwise: () => Yup.string().nullable(true),
    }),
  });

  const ScopeEmissionSchema = Yup.object().shape({
    co2: GasValidationSchema,
    ch4: GasValidationSchema,
    n2o: GasValidationSchema,
    hfcs: GasValidationSchema,
    pfcs: GasValidationSchema,
    sf6: GasValidationSchema,
    total: Yup.number()
      .min(0)
      .required("Total is required")
      .positive("Must be greater than 0")
      .typeError("Please enter a number"),
  });

  const infoOnEmission1Schema = Yup.object().shape({
    scope1Emissions: ScopeEmissionSchema,
    scope2Emissions: ScopeEmissionSchema,
    scope3Emissions: Yup.object().when("$operationalBoundaries.includeScope3", {
      is: true,
      then: () => ScopeEmissionSchema,
      otherwise: (schema) => schema.nullable(true),
    }),
    directCo2Emission: Yup.object().shape({
      notRecorded: Yup.boolean().required(),
      value: Yup.number().when("notRecorded", {
        is: false,
        then: () =>
          Yup.number()
            .min(0, "Minimum 0")
            .max(10000000, "Maximum 10000000")
            .required("Mobile Combustion is required")
            .typeError("Please enter a number"),
        otherwise: () => Yup.number().nullable(true),
      }),
      specify: Yup.string().when("notRecorded", {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .min(5, "Minimum 5 Characters")
            .max(200, "Maximum 200 Characters")
            .matches(
              /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
              "Must be alphanumeric and special characters"
            )
            .required("Please specify the reason"),
        otherwise: () => Yup.string().nullable(true),
      }),
    }),
  });

  const methodologiesAndEmissionFactorsSchema = Yup.object().shape({
    description: Yup.string()
      .min(50, "Minimum 50 Characters")
      .max(500, "Maximum 500 Characters")
      .required("Description is required"),
    referenceLink: Yup.string()
      .trim()
      .min(5, "Minimum 5 Characters")
      .max(500, "Maximum 500 Characters")
      .required("Reference link is required"),
  });

  const infoOnEmission2Schema = Yup.object().shape({
    directEmission: Yup.object().shape({
      stationaryCombustion: GasValidationSchema,
      mobileCombustion: GasValidationSchema,
      processSources: GasValidationSchema,
      fugativeSources: GasValidationSchema,
      agriculturalSources: GasValidationSchema,
    }),
    indirectEmission: Yup.object().shape({
      electricity: GasValidationSchema,
      steam: GasValidationSchema,
      heating: GasValidationSchema,
      cooling: GasValidationSchema,
    }),
    emissionsDisaggregatedByFacility: Yup.object().shape({
      aggregationResponse: Yup.string()
        .trim()
        .oneOf(["recorded", "notRecorded", "notApplicable"], "Invalid value")
        .required("Aggregation response is required"),
      facilityData: Yup.array().when("aggregationResponse", {
        is: (aggregationResponse) => aggregationResponse === "recorded",
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                facility: Yup.string()
                  .trim()
                  .min(2, "Minimum 2 Characters")
                  .max(200, "Maximum 200 Characters")
                  .matches(
                    /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
                    "Facility must be alphanumeric and special characters"
                  )
                  .required("Facility is required"),
                scope1Emissions: Yup.number()
                  .min(0, "Minimum 0")
                  .max(10000000, "Maximum 10000000")
                  .typeError("Please enter a number")
                  .required("Scope 1 emissions is required"),
              })
            )
            .min(1, "At least one facility is required")
            .max(10, "Maximum 10 facilities"),
        otherwise: () => Yup.array().nullable(true),
      }),
    }),
    // emissionsDisaggregatedByFacility: Yup.object().shape({
    //   aggregationResponse: Yup.string()
    //     .trim()
    //     .oneOf(["recorded", "notRecorded", "notApplicable"], "Invalid value")
    //     .required("Aggregation response is required"),
    //   facilityData: Yup.array()
    //     .of(
    //       Yup.object().shape({
    //         facility: Yup.string()
    //           .trim()
    //           .min(2, "Minimum 2 Characters")
    //           .max(200, "Maximum 200 Characters")
    //           .matches(
    //             /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
    //             "Facility must be alphanumeric and special characters"
    //           )
    //           .required("Facility is required"),
    //         scope1Emissions: Yup.number()
    //           .min(0, "Minimum 0")
    //           .max(10000000, "Maximum 10000000")
    //           .required("Scope 1 emissions is required"),
    //       })
    //     )
    //     .max(10, "Maximum 10 facilities"),
    // }),
    // emissionsDisaggregatedByCountry: Yup.array().of(
    //   Yup.object().shape({
    //     country: Yup.string().required("Country is required"),
    //     scope1Emissions: Yup.string().required("Scope 1 emissions is required"),
    //   })
    // ),
    emissionsDisaggregatedByCountry: Yup.object().shape({
      aggregationResponse: Yup.string()
        .trim()
        .oneOf(["recorded", "notRecorded", "notApplicable"], "Invalid value")
        .required("Aggregation response is required"),
      countryData: Yup.array().when("aggregationResponse", {
        is: (aggregationResponse) => aggregationResponse === "recorded",
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                country: Yup.string()
                  .trim()
                  .oneOf(["UAE", "India", "Saudi Arabia"], "Invalid value")
                  .required("Country is required"),
                scope1Emissions: Yup.number()
                  .min(0, "Minimum 0")
                  .max(10000000, "Maximum 10000000")
                  .typeError("Please enter a number")
                  .required("Scope 1 emissions is required"),
              })
            )
            .min(1, "At least one country is required")
            .max(10, "Maximum 10 countries"),
        otherwise: () => Yup.array().nullable(true),
      }),
    }),

    emissionsFromSoldOrTransferredEnergy: Yup.object().shape({
      value: Yup.number()
        .min(0, "Minimum 0")
        .max(10000000, "Maximum 10000000")
        .typeError("Please enter a number")
        .required(
          "Data for emissions from sold or transferred energy is required"
        ),
    }),
    emissionsFromPurchasedForResaleEnergy: Yup.object().shape({
      value: Yup.number()
        .min(0, "Minimum 0")
        .max(10000000, "Maximum 10000000")
        .typeError("Please enter a number")
        .required(
          "Data for emissions from purchased for resale energy is required"
        ),
    }),
    emissionsFromNonKyotoGHGs: Yup.object().shape({
      value: Yup.number()
        .min(0, "Minimum 0")
        .max(10000000, "Maximum 10000000")
        .typeError("Please enter a number")
        .required("Data for emissions from non-Kyoto GHGs is required"),
    }),
    performanceIndicatorsFile: Yup.array()
      .of(
        Yup.object().shape({
          fileName: Yup.string().trim().required("File name is required"),
        })
      )
      .min(1, "Atleast one document is required")
      .max(10, "Maximum 10 documents")
      .required("Performance indicators file is required"),
  });

  const additionalInfoSchema = Yup.object().shape({
    ghgContractualProvisionsInfo: Yup.object().shape({
      isApplicable: Yup.boolean().required(),
      specify: Yup.string().when("isApplicable", {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .min(50, "Minimum 50 Characters")
            .max(500, "Maximum 500 Characters")
            .matches(
              /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
              "Must be alphanumeric and special characters"
            )
            .required("Please specify the reason"),
        otherwise: () => Yup.string().nullable(true),
      }),
    }),
    ghgSequestrationInfo: Yup.object().shape({
      isApplicable: Yup.boolean().required(),
      specify: Yup.string().when("isApplicable", {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .min(50, "Minimum 50 Characters")
            .max(500, "Maximum 500 Characters")
            .matches(
              /^[A-Za-z0-9\s.,\/()"'\{\}\[\]_\-+=]+$/,
              "Must be alphanumeric and special characters"
            )
            .required("Please specify the reason"),
        otherwise: () => Yup.string().nullable(true),
      }),
    }),
  });

  const infoOnOffsetsSchema = Yup.object().shape({
    purchasedOrDevelopedOffsetsInfo: Yup.object().shape({
      isApplicable: Yup.boolean().required(),
      offset: Yup.array().when("isApplicable", {
        is: true,
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                quantityOfGhgs: Yup.number()
                  .min(0, "Minimum 0")
                  .max(10000000, "Maximum 10000000")
                  .typeError("Please enter a number")
                  .required(
                    "Quantity of GHGs for purchased or developed offsets is required"
                  ),
                offsetProjectType: Yup.string()
                  .trim()
                  .oneOf(
                    [
                      "Offset Project 1",
                      "Offset Project 2",
                      "Offset Project 3",
                      "Offset Project 4",
                      "Offset Project 5",
                    ],
                    "Invalid value"
                  )
                  .required("Offset project type is required"),
                offsetsverified: Yup.boolean().required(
                  "Verification status for purchased or developed offsets is required"
                ),
              })
            )
            .min(1, "Atleast one offset is required")
            .max(10, "Maximum 10 offsets"),
        otherwise: () => Yup.array().nullable(true),
      }),
    }),
    soldTransferredReductionsInfo: Yup.object().shape({
      isApplicable: Yup.boolean().required(),
      reduction: Yup.array().when("isApplicable", {
        is: true,
        then: () =>
          Yup.array()
            .of(
              Yup.object().shape({
                quantityOfGhgs: Yup.number()
                  .min(0, "Minimum 0")
                  .max(10000000, "Maximum 10000000")
                  .typeError("Please enter a number")
                  .required(
                    "Quantity of GHGs for sold or transferred offsets is required"
                  ),
                offsetProjectType: Yup.string()
                  .trim()
                  .oneOf(
                    [
                      "Offset Project 1",
                      "Offset Project 2",
                      "Offset Project 3",
                      "Offset Project 4",
                      "Offset Project 5",
                    ],
                    "Invalid value"
                  )
                  .required("Offset project type is required"),
                offsetsverified: Yup.boolean().required(
                  "Verification status for sold or transferred offsets is required"
                ),
              })
            )
            .min(1, "Atleast one offset is required")
            .max(10, "Maximum 10 offsets"),
        otherwise: () => Yup.array().nullable(true),
      }),
    }),
  });

  const schema = Yup.object().shape({
    generalInformation: generalInformationSchema,
    organizationalBoundaries: organizationalBoundariesSchema,
    operationalBoundaries: operationalBoundariesSchema,
    infoOnEmission1: infoOnEmission1Schema,
    methodologiesAndEmissionFactors: methodologiesAndEmissionFactorsSchema,
    infoOnEmission2: infoOnEmission2Schema,
    additionalInfo: additionalInfoSchema,
    infoOnOffsets: infoOnOffsetsSchema,
  });

  const emptyValues = {
    inventoryYear: "",
    generalInformation: {
      facilitiesExcluded: {
        isExcluded: true,
        exclusionDetails: "",
      },
      reportingPeriod: {
        from: null,
        to: null,
      },
      reportingFrequency: {
        data: "Quarterly",
      },
    },

    organizationalBoundaries: {
      consolidationApproach: {
        data: ["Equity Share"],
      },
      entities: [
        {
          legalEntityOrFacility: "",
          percentageEquityShare: "",
          isCompanyHasFinancialControl: true,
          isCompanyHasOperationalControl: false,
        },
      ],
      orgDiagramFile: [],
    },
    operationalBoundaries: {
      includeScope3: false,
      scope3ActivityTypes: "",
    },
    infoOnEmission1: {
      scope1Emissions: {
        co2: null,
        ch4: null,
        n2o: null,
        hfcs: null,
        pfcs: null,
        sf6: null,
        total: null,
      },
      scope2Emissions: {
        co2: null,
        ch4: null,
        n2o: null,
        hfcs: null,
        pfcs: null,
        sf6: null,
        total: null,
      },
      scope3Emissions: {
        co2: null,
        ch4: null,
        n2o: null,
        hfcs: null,
        pfcs: null,
        sf6: null,
        total: null,
      },
      directCo2Emission: {
        data: "",
      },
    },
    methodologiesAndEmissionFactors: {
      description: "",
      referenceLink: "",
    },
    infoOnEmission2: {
      directEmission: {
        stationaryCombustion: "",
        mobileCombustion: "",
        processSources: "",
        fugativeSources: "",
        agriculturalSources: "",
      },
      indirectEmission: {
        electricity: "",
        steam: "",
        heating: "",
        cooling: "",
      },
      emissionsDisaggregatedByFacility: [
        {
          facility: "",
          scope1Emissions: "",
        },
      ],
      emissionsDisaggregatedByCountry: [
        {
          country: "",
          scope1Emissions: "",
        },
      ],
      emissionsFromSoldOrTransferredEnergy: {
        value: null,
      },
      emissionsFromPurchasedForResaleEnergy: {
        value: null,
      },
      emissionsFromNonKyotoGHGs: {
        value: null,
      },
      performanceIndicatorsFile: [],
    },
    additionalInfo: {
      ghgContractualProvisionsInfo: {
        specify: "",
      },
      ghgSequestrationInfo: {
        data: "",
      },
    },
    infoOnOffsets: {
      purchasedOrDevelopedOffsetsInfo: [
        {
          quantityOfGhgs: "",
          offsetProjectType: "",
          offsetsverified: true,
        },
      ],
      soldTransferredOffsetsInfo: [
        {
          quantityOfGhgs: "",
          offsetProjectType: "",
          offsetsverified: false,
        },
      ],
    },
  };

  const [
    emissionDrafts,
    {
      isLoading: emissionDraftIsLoading,
      isSuccess: emissionDraftIsSuccess,
      error: emissionDraftIsError,
    },
  ] = useEmissionDraftsMutation();

  const [
    emissionSubmit,
    {
      isLoading: emissionSubmitIsLoading,
      isSuccess: emissionSubmitIsSuccess,
      error: emissionSubmitIsError,
    },
  ] = useEmissionSubmitMutation();

  const [
    emissionResubmit,
    {
      isLoading: emissionResubmitIsLoading,
      isSuccess: emissionResubmitIsSuccess,
      error: emissionResubmitIsError,
    },
  ] = useEmissionResubmitMutation();

  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({});

  const formik = useFormik({
    initialValues: {
      declarationId: findOneData?.data?.declaration?._id,
      generalInformation: {
        facilitiesExcluded: {
          isExcluded:
            findOneData?.data?.declaration?.generalInformation
              ?.facilitiesExcluded?.isExcluded ?? true,
          exclusionDetails:
            findOneData?.data?.declaration?.generalInformation
              ?.facilitiesExcluded?.exclusionDetails ?? "",
          issueId:
            findOneData?.data?.declaration?.generalInformation
              ?.facilitiesExcluded?.issueId ?? null,
        },
        reportingPeriod: {
          from:
            findOneData?.data?.declaration?.generalInformation?.reportingPeriod
              ?.from ?? null,
          to:
            findOneData?.data?.declaration?.generalInformation?.reportingPeriod
              ?.to ?? null,
          issueId:
            findOneData?.data?.declaration?.generalInformation?.reportingPeriod
              ?.issueId ?? null,
        },
      },
      organizationalBoundaries: {
        consolidationApproach: {
          equityShare:
            findOneData?.data?.declaration?.organizationalBoundaries
              ?.consolidationApproach?.equityShare ?? false,
          financialControl:
            findOneData?.data?.declaration?.organizationalBoundaries
              ?.consolidationApproach?.financialControl ?? false,
          operationalControl:
            findOneData?.data?.declaration?.organizationalBoundaries
              ?.consolidationApproach?.operationalControl ?? false,
          issueId:
            findOneData?.data?.declaration?.organizationalBoundaries
              ?.consolidationApproach?.issueId ?? null,
        },
        entities: findOneData?.data?.declaration?.organizationalBoundaries
          ?.entities ?? [
          {
            legalEntityOrFacility: "",
            percentageEquityShare: "",
            isCompanyHasFinancialControl: true,
            isCompanyHasOperationalControl: true,
            issueId: null,
            _id: "",
          },
        ],
        orgDiagramFile:
          findOneData?.data?.declaration?.organizationalBoundaries
            ?.orgDiagramFile ?? [],
      },
      operationalBoundaries: {
        includeScope3:
          findOneData?.data?.declaration?.operationalBoundaries
            ?.includeScope3 ?? true,
        scope3ActivityTypes:
          findOneData?.data?.declaration?.operationalBoundaries
            ?.scope3ActivityTypes ?? "",
        issueId:
          findOneData?.data?.declaration?.operationalBoundaries?.issueId ??
          null,
      },
      infoOnEmission1: {
        scope1Emissions: {
          co2: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.co2?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.co2?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.co2?.specify ?? "",
          },
          ch4: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.ch4?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.ch4?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.ch4?.specify ?? "",
          },
          n2o: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.n2o?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.n2o?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.n2o?.specify ?? "",
          },
          hfcs: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.hfcs?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.hfcs?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.hfcs?.specify ?? "",
          },
          pfcs: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.pfcs?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.pfcs?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.pfcs?.specify ?? "",
          },
          sf6: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.sf6?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.sf6?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
                ?.sf6?.specify ?? "",
          },
          issueId:
            findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
              ?.issueId ?? null,
          total:
            findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions
              ?.total ?? null,
        },

        scope2Emissions: {
          co2: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.co2?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.co2?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.co2?.specify ?? "",
          },
          ch4: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.ch4?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.ch4?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.ch4?.specify ?? "",
          },
          n2o: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.n2o?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.n2o?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.n2o?.specify ?? "",
          },
          hfcs: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.hfcs?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.hfcs?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.hfcs?.specify ?? "",
          },
          pfcs: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.pfcs?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.pfcs?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.pfcs?.specify ?? "",
          },
          sf6: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.sf6?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.sf6?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
                ?.sf6?.specify ?? "",
          },
          issueId:
            findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
              ?.issueId ?? null,
          total:
            findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions
              ?.total ?? null,
        },
        scope3Emissions: {
          co2: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.co2.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.co2.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.co2.specify ?? "",
          },
          ch4: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.ch4?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.ch4?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.ch4?.specify ?? "",
          },
          n2o: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.n2o?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.n2o?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.n2o?.specify ?? "",
          },
          hfcs: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.hfcs?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.hfcs?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.hfcs?.specify ?? "",
          },
          pfcs: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.pfcs?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.pfcs?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.pfcs?.specify ?? "",
          },
          sf6: {
            value:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.sf6?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.sf6?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
                ?.sf6?.specify ?? "",
          },
          total:
            findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
              ?.total ?? null,
          issueId:
            findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions
              ?.issueId ?? null,
        },
        directCo2Emission: {
          value:
            findOneData?.data?.declaration?.infoOnEmission1?.directCo2Emission
              .value ?? null,
          notRecorded:
            findOneData?.data?.declaration?.infoOnEmission1?.directCo2Emission
              ?.notRecorded ?? false,
          specify:
            findOneData?.data?.declaration?.infoOnEmission1?.directCo2Emission
              ?.specify ?? "",
          issueId:
            findOneData?.data?.declaration?.infoOnEmission1?.directCo2Emission
              ?.issueId ?? null,
        },
      },
      methodologiesAndEmissionFactors: {
        description:
          findOneData?.data?.declaration?.methodologiesAndEmissionFactors
            ?.description ?? "",
        referenceLink:
          findOneData?.data?.declaration?.methodologiesAndEmissionFactors
            ?.referenceLink ?? "",
        issueId:
          findOneData?.data?.declaration?.methodologiesAndEmissionFactors
            ?.issueId ?? null,
      },
      infoOnEmission2: {
        directEmission: {
          stationaryCombustion: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.stationaryCombustion?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.stationaryCombustion?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.stationaryCombustion?.specify ?? "",
          },
          mobileCombustion: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.mobileCombustion?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.mobileCombustion?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.mobileCombustion?.specify ?? "",
          },
          processSources: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.processSources?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.processSources?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.processSources?.specify ?? "",
          },
          fugativeSources: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.fugativeSources?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.fugativeSources?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.fugativeSources?.specify ?? "",
          },
          agriculturalSources: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.agriculturalSources?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.agriculturalSources?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.agriculturalSources?.specify ?? "",
          },
          issueId:
            findOneData?.data?.declaration?.infoOnEmission2?.directEmission
              ?.issueId ?? null,
        },
        indirectEmission: {
          electricity: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.electricity?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.electricity?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.electricity?.specify ?? "",
          },
          steam: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.steam?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.steam?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.steam?.specify ?? "",
          },
          heating: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.heating?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.heating?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.heating?.specify ?? "",
          },
          cooling: {
            value:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.cooling?.value ?? null,
            notRecorded:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.cooling?.notRecorded ?? false,
            specify:
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.heatcoolinging?.specify ?? "",
          },
          issueId:
            findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
              ?.issueId ?? null,
        },
        emissionsDisaggregatedByFacility: {
          aggregationResponse:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsDisaggregatedByFacilitydata?.declaration
              ?.infoOnEmission2.emissionsDisaggregatedByFacility
              ?.aggregationResponse?.aggregationResponse ?? "recorded",
          facilityData: findOneData?.data?.declaration?.infoOnEmission2
            ?.emissionsDisaggregatedByFacility?.facilityData ?? [
            {
              facility: "",
              scope1Emissions: null,
              issueId: null,
              _id: "",
            },
          ],
        },
        emissionsDisaggregatedByCountry: {
          aggregationResponse:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsDisaggregatedByCountry?.aggregationResponse ??
            "recorded",
          countryData: findOneData?.data?.declaration?.infoOnEmission2
            ?.emissionsDisaggregatedByCountry?.countryData ?? [
            {
              country: "",
              scope1Emissions: null,
              issueId: null,
              _id: "",
            },
          ],
        },
        emissionsFromSoldOrTransferredEnergy: {
          value:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsFromSoldOrTransferredEnergy?.value ?? null,
          issueId:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsFromSoldOrTransferredEnergy?.issueId ?? null,
        },
        emissionsFromPurchasedForResaleEnergy: {
          value:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsFromPurchasedForResaleEnergy?.value ?? null,
          issueId:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsFromPurchasedForResaleEnergy?.issueId ?? null,
        },
        emissionsFromNonKyotoGHGs: {
          value:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsFromNonKyotoGHGs?.value ?? null,
          issueId:
            findOneData?.data?.declaration?.infoOnEmission2
              ?.emissionsFromNonKyotoGHGs?.issueId ?? null,
        },
        performanceIndicatorsFile:
          findOneData?.data.declaration?.infoOnEmission2
            ?.performanceIndicatorsFile ?? [],
      },
      additionalInfo: {
        ghgContractualProvisionsInfo: {
          isApplicable:
            findOneData?.data?.declaration?.additionalInfo
              ?.ghgContractualProvisionsInfo?.isApplicable ?? true,
          specify:
            findOneData?.data?.declaration?.additionalInfo
              ?.ghgContractualProvisionsInfo?.specify ?? "",
          issueId:
            findOneData?.data?.declaration?.additionalInfo
              ?.ghgContractualProvisionsInfo?.issueId ?? null,
        },
        ghgSequestrationInfo: {
          isApplicable:
            findOneData?.data?.declaration?.additionalInfo?.ghgSequestrationInfo
              ?.isApplicable ?? true,
          specify:
            findOneData?.data?.declaration?.additionalInfo?.ghgSequestrationInfo
              ?.specify ?? "",
          issueId:
            findOneData?.data?.declaration?.additionalInfo?.ghgSequestrationInfo
              ?.issueId ?? null,
        },
      },
      infoOnOffsets: {
        purchasedOrDevelopedOffsetsInfo: {
          isApplicable:
            findOneData?.data?.declaration?.infoOnOffsets
              ?.purchasedOrDevelopedOffsetsInfo?.isApplicable ?? true,
          offset: findOneData?.data?.declaration?.infoOnOffsets
            ?.purchasedOrDevelopedOffsetsInfo?.offset ?? [
            {
              quantityOfGhgs: null,
              offsetProjectType: "",
              offsetsverified: true,
              issueId: null,
              _id: "",
            },
          ],
        },
        soldTransferredReductionsInfo: {
          isApplicable:
            findOneData?.data?.declaration?.infoOnOffsets
              ?.soldTransferredReductionsInfo?.isApplicable ?? true,
          reduction: findOneData?.data?.declaration?.infoOnOffsets
            ?.soldTransferredReductionsInfo.reduction ?? [
            {
              quantityOfGhgs: null,
              offsetProjectType: "",
              offsetsverified: true,
              issueId: null,
              _id: "",
            },
          ],
        },
      },
    },
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values) => {
      if (isConfirmed) {
        const data = structuredClone(values);
        cleanObject(data);
        if (
          findOneData?.data?.declaration?.currentStage === "Emission Resubmit"
        )
          emissionResubmit(data)
            .unwrap()
            .then((res) => {
              dispatch(showSuccess({ message: `Resubmitted Successfully` }));
              console.log(res, "res");
              navigate("/dashboard");
            })
            .catch((err) => {
              console.log("resubmit error", err.data.message);
            });
        else {
          emissionSubmit(data)
            .unwrap()
            .then((res) => {
              dispatch(showSuccess({ message: `Submitted Successfully` }));
              console.log(res, "res");
              navigate("/dashboard");
            })
            .catch((err) => {
              console.log("submit error", err.data.message);
            });
        }
      } else {
        dispatch(
          showError({
            message: `Please check the Monitoring & Reporting Acknowledgement before submitting your declaration`,
          })
        );
        return;
      }
    },
  });

  const handleEmissionDraft = () => {
    const data = structuredClone(formik?.values);
    cleanObject(data);
    console.log("DraftData", data);
    emissionDrafts(data)
      .unwrap()
      .then((res) => {
        dispatch(showSuccess({ message: `Draft Saved Successfully` }));
        console.log(res, "res");
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log("draft error", err.data.message);
      });
  };

  const handleCancleAndExit = () => {
    navigate("/dashboard");
  };

  useFormikToastError(formik);

  console.log("FormikValues", formik?.values);
  console.log("FormikErrors", formik?.errors);
  console.log("activeSection", activeSection);

  return (
    <form
      className="container emiDecl_section"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className="emissionDec_titleDiv">
        <div className="emiDecLogo">
          <img src={EmissionDecLogo} alt="" />
        </div>
        {(findOneData?.data?.declaration?.currentStage ===
          "Initial Declaration" ||
          findOneData?.data?.declaration?.currentStage ===
            "Emission Declaration") && (
          <div className="emisDec_buttonDiv">
            <div className="emisCancelBtn" onClick={handleCancleAndExit}>
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
                type="button"
              />
            </div>
            <div className="emisSaveBtn">
              <div
                className="form_SubmitBtn"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ffffff",
                  color: "#ffffff",
                  fontSize: "1rem",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleEmissionDraft();
                }}
              >
                Save As Draft
              </div>
            </div>
            <div className="emisSubmitBtn">
              <FormButton
                buttonName={"Submit Declaration"}
                backgroundColor={"#00637E"}
                buttonText={"#ffffff"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
              />
            </div>
          </div>
        )}
        {findOneData?.data?.declaration?.currentStage ===
          "Emission Resubmit" && (
          <div className="emisDec_buttonDiv">
            <div className="emisCancelBtn" onClick={handleCancleAndExit}>
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
                type="button"
              />
            </div>
            <div
              className="emisSubmitBtn"
              onClick={(() => formik.handleSubmit(), console.log("resubmit"))}
            >
              <FormButton
                buttonName={"Resubmit Declaration"}
                // backgroundColor={!isConfirmed ? "#7badba" : "#00637E"}
                backgroundColor={"#00637E"}
                buttonText={"#ffffff"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
                // disabled={!isConfirmed}
              />
            </div>
          </div>
        )}
      </div>

      <div className="emiDecl_innerContainer">
        <div className="emissionDecSec_Col1">
          <div className="emisDec_NowNextStep">
            <div className="emisDecleftTitle">
              <h1>Emission Declaration</h1>
              <div className="nextStep">
                <p>Next Step:</p>
                <h3>Assure/Audit Emissions</h3>
              </div>
            </div>
          </div>
          <EmisTitleSection
            setActiveSection={setActiveSection}
            sectionId={[
              "generalInfoSection",
              "orgBoundariesSection",
              "operaBoundariesSection",
            ]}
          />
          <EmisChecklistSection />
          <EmisGoodPractice />
        </div>

        <div className="emissionDecSec_Col2">
          <div
            className="emissionDecform"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <div className="CodewaveTitleDiv">
              <div className="CodeCol1">
                <img src={CodeLogo} alt="CodeLogo" />
                <h3>
                  Codewave’s Greenhouse Gas
                  <br />
                  Emissions Inventory
                </h3>
              </div>
            </div>
          </div>

          <div className="EmissInnerMainBlock">
            <Acknowledgement
              setIsConfirmed={setIsConfirmed}
              isConfirmed={isConfirmed}
              title="Monitoring & Reporting Acknowledgement"
              confirmationText="We confirm that we have understood that monitoring & reporting frequency is quarterly to maintain the consistency of the rating. We agree to be notified about the same after every quarter"
            />
            <GenInformation
              formik={formik}
              sectionId="generalInfoSection"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <OrgBoundaries
              formik={formik}
              sectionId="orgBoundariesSection"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <OperaBoundaries
              formik={formik}
              sectionId="operaBoundariesSection"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <InfoOnEmission
              formik={formik}
              sectionId="infoEmission1"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <MethodoEmission
              formik={formik}
              sectionId="methodologyEmission"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <InfoOnEmission2
              formik={formik}
              sectionId="infoEmission2"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AdditInformation
              formik={formik}
              sectionId="additionalInfo"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <InfoOnOffsets
              formik={formik}
              sectionId="infoOnOffsets"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </div>

          <div className="form-row bs_BtnRow wd-100">
            <div
              className=" cursorPointer"
              // onClick={() => {
              //   formik.resetForm();
              //   formik.setValues(emptyValues);
              // }}
              onClick={handleCancleAndExit}
            >
              <RegCancelButton buttonName={"Cancel & Exit"} />
            </div>
            <div className="bottomSaveBtnRow">
              {findOneData?.data?.declaration?.currentStage !==
                "Emission Resubmit" && (
                <div
                  className="saveDraftButton cursorPointer"
                  onClick={() => {
                    handleEmissionDraft();
                    // formik.handleSubmit();
                    // handleSubmitButtonState("draft");
                  }}
                >
                  <p>Save As Draft</p>
                </div>
              )}
              <div
                className=" NextBtn cursorPointer"
                // onClick={() => handleSubmitButtonState("submit")}
              >
                <FormButton
                  buttonName={
                    findOneData?.data?.declaration?.currentStage ===
                    "Emission Resubmit"
                      ? "ReSubmit Declaration"
                      : "Submit Declaration"
                  }
                  // backgroundColor={!isConfirmed ? "#7badba" : "#00637E"}
                  backgroundColor={"#00637E"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                  // disabled={!isConfirmed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </form>
  );
}

export default EmissionsDeclaration;
