import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";

import PdfUpload from "../../../pdfUpload/PdfUpload";
import EmissionComments from "../../../chats/EmissionComments";

function AuditOrgBoundaries({ data }) {
  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>ORGANIZATIONAL BOUNDARIES</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditOrgBoundries">
            <div className="auditOrgBounInfoSec">
              <div className="auditWhichConsiText">
                <h2>
                  Which consolidation approach was chosen (check each
                  consolidation approach for which your company is reporting
                  emissions.) If your company is reporting according to more
                  than one consolidation approach, please complete and attach an
                  additional completed reporting template that provides your
                  company’s emissions data following the other consolidation
                  approach(es).
                </h2>
              </div>
              <div>
                <p className="auditOrgEquityShare">
                  {[
                    data?.data?.declaration?.organizationalBoundaries
                      ?.consolidationApproach?.equityShare && "Equity Share",
                    data?.data?.declaration?.organizationalBoundaries
                      ?.consolidationApproach?.financialControl &&
                      "Financial Control",
                    data?.data?.declaration?.organizationalBoundaries
                      ?.consolidationApproach?.operationalControl &&
                      "Operational Control",
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </p>
              </div>
              <div className="addCmtRow">
                <EmissionComments
                  className="addCmtRow"
                  mapping="organizationalBoundaries.consolidationApproach"
                  issueId={
                    data?.data?.declaration?.organizationalBoundaries
                      .consolidationApproach?.issueId
                  }
                  declarationId={data?.data?.declaration?._id}
                  secName="Organizational Boundaries"
                  question={
                    "Which consolidation approach was chosen (check each consolidation approach for which your company is reporting emissions.) If your company is reporting according to more than one consolidation approach, please complete and attach an additional completed reporting template that provides your company’s emissions data following the other consolidation approach(es)."
                  }
                  answer={[
                    data?.data?.declaration?.organizationalBoundaries
                      ?.consolidationApproach?.equityShare && "Equity Share",
                    data?.data?.declaration?.organizationalBoundaries
                      ?.consolidationApproach?.financialControl &&
                      "Financial Control",
                    data?.data?.declaration?.organizationalBoundaries
                      ?.consolidationApproach?.operationalControl &&
                      "Operational Control",
                  ]
                    .filter(Boolean)
                    .join(", ")}
                  data={data}
                />
              </div>
            </div>

            <div className="auditOrgListDownSec">
              <h1 className="auditListDown">
                List down all legal entities or facilities over which reporting
                company has equity share, financial control or operational
                control
              </h1>

              {data?.data?.declaration?.organizationalBoundaries?.entities.map(
                (item, index) => (
                  <>
                    <div className="auditOrgcontrolSec" key={index}>
                      <h2>
                        Equity Share, Financial & Operation Control Information
                      </h2>
                      <div className="auditOrgFacilityRow">
                        <div className="auditOrgFacilityCol1">
                          <h1>Legal Entity or Facility</h1>
                        </div>
                        <div className="auditOrgFacilityCol2">
                          <p>{item?.legalEntityOrFacility}</p>
                        </div>
                      </div>
                      <div className="auditOrgPerInSec">
                        <div className="auditOrgPerInCol1">
                          <div className="auditOrgPerCol1">
                            <h1>Percentage equity share in legal entity</h1>
                          </div>
                          <div className="auditOrgPerCol2">
                            <p>{item?.percentageEquityShare}</p>
                          </div>
                        </div>
                        <div className="auditOrgPerInCol2">
                          <div className="auditOrgPerCol1">
                            <h1>
                              Does reporting company have financial control?
                            </h1>
                          </div>
                          <div className="auditOrgPerCol2">
                            <p>
                              {item?.isCompanyHasFinancialControl
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="auditOrgFacilityRow">
                        <div className="auditOrgFacilityCol1">
                          <h1>
                            Does reporting company have operational control?
                          </h1>
                        </div>
                        <div className="auditOrgFacilityCol2">
                          <p>
                            {item?.isCompanyHasOperationalControl
                              ? "Yes"
                              : "No"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="addCmtRow">
                      <EmissionComments
                        className="addCmtRow"
                        mapping={"organizationalBoundaries.entities"}
                        issueId={item?.issueId}
                        declarationId={data?.data?.declaration?._id}
                        arrayId={item?._id}
                        secName={"Organizational Boundaries"}
                        question={
                          "List down all legal entities or facilities over which reporting company has equity share, financial control or operational control"
                        }
                        answer={[
                          item?.legalEntityOrFacility,
                          item?.percentageEquityShare,
                          item?.isCompanyHasFinancialControl ? "Yes" : "No",
                          item?.isCompanyHasOperationalControl ? "Yes" : "No",
                        ].join(", ")}
                        data={data}
                      />
                    </div>
                  </>
                )
              )}
            </div>
            <div className="auditRepoCmpnySec">
              <div>
                <h1>
                  If the reporting company’s parent company does not report
                  emissions, include an organizational diagram that clearly
                  defines relationship of the reporting subsidiary as well as
                  other subsidiaries
                </h1>
              </div>
              {data?.data?.declaration?.organizationalBoundaries?.orgDiagramFile?.map(
                (item, index) => (
                  <>
                    <div key={index}>
                      <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div>
                      <div className="addCmtRow">
                        <EmissionComments
                          className="addCmtRow"
                          mapping="organizationalBoundaries.orgDiagramFile"
                          issueId={item?.issueId}
                          declarationId={data?.data?.declaration?._id}
                          arrayId={item?._id}
                          secName={"Organizational Boundaries"}
                          question={
                            " If the reporting company’s parent company does not report emissions, include an organizational diagram that clearly defines relationship of the reporting subsidiary as well as other subsidiaries"
                          }
                          answer={item?.fileName}
                          data={data}
                        />
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditOrgBoundaries;
