import react, { useState } from "react";
import FormButton from "../../../components/button/FormButton";
import EmisGoodPractice from "../../../components/emissionComponents/emisGoodPractice/EmisGoodPractice";
import EmisChecklistSection from "../../../components/emissionComponents/emisChecklistSection/EmisChecklistSection";
import ApproveDecButton from "../../../components/button/approveButton/ApproveDecButton";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import RatingCard from "../../../components/emissionComponents/step6/ratingCard/RatingCard";
import QuarterTwoPreview from "../../../components/emissionComponents/step6/quarterTwoPreview/QuarterTwoPreview";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import { useNavigate } from "react-router-dom";
import { useQuarterlyReportAuditMutation } from "../../../features/steps/quarterlyReportApiSlice";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";

function EmissionStep6Preview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({});

  const [
    quarterlyReportAudit,
    {
      isLoading: quarterlyReportAuditIsLoading,
      isSuccess: quarterlyReportAuditIsSuccess,
      error: quarterlyReportAuditIsError,
    },
  ] = useQuarterlyReportAuditMutation();

  const handleCancelAndExit = () => {
    navigate("/dashboard");
  };

  const handleApproveOrReject = (value) => {
    if (value === "approve") {
      quarterlyReportAudit({
        declarationId: "660cff0f3d97f92668db2cfd",
        reportId: "660d383a99779fbac3751c63",
        action: "approve",
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Submitted Successfully` }));
          navigate("/dashboard");
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else if (value === "reject") {
      quarterlyReportAudit({
        declarationId: "660cff0f3d97f92668db2cfd",
        reportId: "660d383a99779fbac3751c63",
        action: "reject",
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Submitted Successfully` }));
          navigate("/dashboard");
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    }
  };

  return (
    <form className="emis6Section">
      <div className="emis6TitleHeader">
        <div className="">
          <img src={EmissionDecLogo} alt="EmissionDecLogo" />
        </div>
        <div className="emis6LeftDiv">
          <div className="emis6CancelBtn" onClick={handleCancelAndExit}>
            <FormButton
              type="button"
              buttonName={"Cancel & Exit"}
              buttonText={"#ffffff"}
              backgroundColor={"transparent"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
          <div
            className="emisSubmitBtn"
            onClick={() => handleApproveOrReject("reject")}
          >
            <FormButton
              type="button"
              buttonName={"Reject"}
              backgroundColor={"#00637E"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
          <div
            className="emisSubmitBtn"
            onClick={() => handleApproveOrReject("approve")}
          >
            <FormButton
              type="button"
              buttonName={"Approve"}
              backgroundColor={"#00637E"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </div>
      </div>
      <div className="emis6InnerCon">
        <div className="emis6SecCol1">
          <div className="roadmapTitle">
            <h1>Monitoring & Reporting</h1>
            <div className="stepsNext">
              <p> Frequency: </p>
              <span> Quarterly </span>
            </div>
          </div>
          <RatingCard />
          <EmisChecklistSection />
          <EmisGoodPractice />
        </div>
        <div className="emis6SecCol2">
          <div className="net6YearRow">
            <div className="stepsCodewaveLogo">
              <img src={CodeLogo} />
              <h3>Codewave’s Roadmap To NetZero Emissions</h3>
            </div>
            <div className="step6NetZeroValue">
              <h1>For 2024</h1>
              <div className="zeroValueBorder"></div>
              <h1>Quarter 2</h1>
            </div>
          </div>

          <QuarterTwoPreview />

          <div className="bottomSubmitRow">
            <div className="cursorPointer">
              <FormButton
                buttonName={"Cancel & Exit"}
                border={"1px solid #008BB2"}
                buttonText={"#008BB2"}
                backgroundColor={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="cancelBtn_Row">
              <div className="NextBtn cursorPointer">
                <FormButton
                  buttonName={"Submit for Auditing"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </form>
  );
}

export default EmissionStep6Preview;
