import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import FeedbackFilterForm from "../../../components/adminComponents/userFilterForm/FeedbackFilterForm";
import FeedbackDetails from "../../../components/adminComponents/userDetails/FeedbackDetails";

import "./style.css";
import { useDispatch } from "react-redux";
import { useUserListQuery } from "../../../features/admin/adminApiSlice";
import moment from "moment";
import { showError } from "../../../features/snackbar/snackbarAction";


const initialQueryFilterState = {
    userType: "",
    country: "",
    status: "",
    date: "",
    startDate: null,
    endDate: null,
    createdAt: "-1",
    alphabetical: null,
    // location: "",
  };
  const queryFilterReducer = (state, action) => {
    if (action.type === "SEARCH") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        identifier: action.identifier,
      };
    }
    if (action.type === "SET_USER_TYPE") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        userType: action.userType,
      };
    }
    if (action.type === "SET_COUNTRY") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        country: action.country,
      };
    }
    if (action.type === "SET_SEARCH_VALUE") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        searchValue: action.searchValue,
      };
    }
    if (action.type === "SET_STATUS") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        status: action.status ? action.status : initialQueryFilterState.status,
      };
    }
    if (action.type === "SET_STARTDATE") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        startDate: action.startDate,
      };
    }
    if (action.type === "SET_ENDDATE") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        endDate: action.endDate,
      };
    }
    if (action.type === "SET_ALPHABETICAL_SORTING") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        alphabetical: action.alphabetical,
        createdAt: null,
      };
    }
    if (action.type === "SET_CRONOLOGICAL_SORTING") {
      return {
        ...state,
        pageNo: initialQueryFilterState.pageNo,
        createdAt: action.createdAt,
        alphabetical: null,
      };
    }
    if (action.type === "SET_ALL_FILTERS") {
      return {
        ...initialQueryFilterState,
        ...action.filters,
      };
    }
  
    return initialQueryFilterState;
  };

function AdminFeedback() {
    const [feedbackMenu, setFeedbackMenu] = useState(false)
    const [queryFilterState, dispatchQueryFilter] = useReducer(
        queryFilterReducer,
        initialQueryFilterState
      );
      const [userData, setUserData] = useState([]);
      const dispatch = useDispatch();
      const [selectedDates, setSelectedDates] = useState([]);
    
      const {
        data: userList,
        isLoading: userListIsLoading,
        isSuccess: userListIsSuccess,
        error: userListError,
        isError: userListIsError,
        isFetching: userListIsFetching,
      } = useUserListQuery({ ...queryFilterState });
      const handleSearchChange = (e) => {
        dispatchQueryFilter({ type: "SEARCH", identifier: e.target.value });
      };
    
      const handleChangeUserType = (e) => {
        dispatchQueryFilter({ type: "SET_USER_TYPE", userType: e.target.value });
      };
    
      const handleChangeCountry = (e) => {
        dispatchQueryFilter({ type: "SET_COUNTRY", country: e.target.value });
      };
    
      const handleChangeStatus = (e) => {
        dispatchQueryFilter({ type: "SET_STATUS", status: e.target.value });
      };
    
      const handleChangeCalender = (dateRange) => {
        const [startDate, endDate] = dateRange;
        if (startDate === null && endDate === null) {
          const formattedStartDate = null;
          const formattedEndDate = null;
          dispatchQueryFilter({
            type: "SET_STARTDATE",
            startDate: formattedStartDate,
          });
          dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });
    
          return;
        }
    
        // Continue with formatting when at least one of startDate or endDate is not null
        const formattedStartDate = startDate
          ? moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : null;
    
        const formattedEndDate = endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : null;
    
        console.log("checkdateStartDate", startDate);
        console.log("checkdateEndDate", endDate);
        console.log("formattedStartDate", formattedStartDate);
        console.log("formattedEndDate", formattedEndDate);
    
        dispatchQueryFilter({
          type: "SET_STARTDATE",
          startDate: formattedStartDate,
        });
        dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });
      };
    
      const handleChangeRowsPerPage = (event) => {
        dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
      };
    
      const handleChangePage = (_, pageNo) => {
        dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
      };
    
    //   useEffect(() => {
    //     if (userListIsError) {
    //       if (userListError?.message) {
    //         dispatch(showError({ message: userListError.message }));
    //       } else {
    //         dispatch(
    //           showError({ message: "Something went wrong, please try again" })
    //         );
    //       }
    //     }
    //     if (userListIsSuccess) {
    //       setUserData(userList);
    //     }
    //   }, [userList, userListIsSuccess, userListError, dispatch, selectedDates]);
   
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu feedbackMenu={"feedbackMenu"} />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <div>
            <h1 className="feedbackTitle">Feedbacks</h1>
          </div>
          <FeedbackFilterForm 
            handleSearchChange={handleSearchChange}
            queryFilterState={queryFilterState}
            onChangeUserType={handleChangeUserType}
            onChangeCountry={handleChangeCountry}
            onChangeStatus={handleChangeStatus}
            onChangeRegistrationDate={handleChangeCalender}
          />
          <FeedbackDetails />
        </div>
      </div>
    </div>
  );
}

export default AdminFeedback;
