import React, { useState } from 'react';

import FormButton from '../button/FormButton';

import "./style.css";

import UnreadIcom from "../../assets/icons/unreadNotificationIcon.svg";
import NotificationArrowIcon from "../../assets/images/whiteTriangleIcon.png";

function Notifications() {
    const [hideUnreadIcon, setHideUnreadIcon] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

    const notificationData = [
        {
            notificationText: "Lorem ipsum dolor sit amet consectetur. Id odio ultrices ultricies eleifend lobortis tristique. Purus neque morbi sem pretium nisl. Dui nunc mauris hac urna turpis.",
            sendBy: "by You (Auditor)",
            time: "11:04 am",
            unreadIcon: UnreadIcom
        },
        {
            notificationText: "Lorem ipsum dolor sit amet consectetur. Id odio ultrices ultricies eleifend lobortis tristique. Purus neque morbi sem pretium nisl. Dui nunc mauris hac urna turpis.",
            sendBy: "by You (Auditor)",
            time: "11:04 am",
            unreadIcon: "read"

        },
        {
            notificationText: "Lorem ipsum dolor sit amet consectetur. Id odio ultrices ultricies eleifend lobortis tristique. Purus neque morbi sem pretium nisl. Dui nunc mauris hac urna turpis.",
            sendBy: "by You (Auditor)",
            time: "11:04 am",
            unreadIcon: UnreadIcom

        },
    ]

    const handleMarkAsRead = () => {
        setHideUnreadIcon(true);
        setBackgroundColor("#E0E0E0");
    };

    return (

        <div className='notificationContainer'>
            <img
                src={NotificationArrowIcon}
                alt='icon'
                className='notifiArrowIcon'
            />
            <header className='notificationHeader' style={{position:"relative"}}>
                <div className='markAsReadRow'>
                    <h1>Notifications</h1>
                    <div onClick={handleMarkAsRead} className='cursorPointer'>
                        <h2>Mark As Read</h2>
                    </div>
                </div>
                <div className='unreadRow'>
                    <div className="emisSubmitBtn">
                        <FormButton
                            buttonName={"All"}
                            backgroundColor={"#000000"}
                            buttonText={"#ffffff"}
                            btnFont={"12px"}
                            btnPdd={"3px 6px"}
                        />
                    </div>
                    <div className="unreadButton">
                        Unread (2)
                    </div>
                </div>
            </header>
            <div className='bottomBorder' />
            <div className='notifiBoxsection'>
                {notificationData?.map((item, index) => (
                    <div className='notifiTextContainer' style={{ backgroundColor: item.unreadIcon !== "read" ? backgroundColor : "" }} key={index}>
                        <div className='notificationTextBox'>
                            <div style={{ display: "flex" }}>
                                <h3 className='notifiText'>{item?.notificationText} </h3>
                                {!hideUnreadIcon && item.unreadIcon !== "read" && (
                                    <img
                                        src={item?.unreadIcon}
                                        alt='unreadIcom'
                                        className='unreadIcomStyle'
                                    />
                                )}
                            </div>
                            <div className='notificationBy'>
                                <p>{item.sendBy}</p>
                                <div className='notifiByRightBorder'></div>
                                <span>{item.time}</span>
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default Notifications;