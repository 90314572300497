import React from 'react';

import "./style.css";

import InactiveImg from "../../assets/images/currentRatingInactinveImage.svg"
import Gap from "../../assets/images/gapObservedImg.svg";

function OrganizationEmissionsDeclared() {
  return (
    <div className='orgsCurrentRatingCon'>
        <div className='orgsCurrentRatingInner'>
            <div className='currentRatTitle'>
                <h1>Emissions Declared</h1>
                <p>-</p>
                <h2>mtCO2E</h2>
            </div>
            <div className='blueBoarderLine'></div>
        </div>

        <div className="orgsReductionsRateCon">
            <div className='reductionsRateCol1'>
                <h1>GAP OBSERVED</h1>
                <p>-</p>
            </div>
            <img src={Gap} alt='Gap' />
        </div>

    </div>
  )
}

export default OrganizationEmissionsDeclared;