import React from 'react';

import "./style.css";


function YearQuarterCard() {
  return (
    <div className='yearQuarterCard'>
        <div className='yearQuartCardRow'>
            <div className='yearQuartCardCol1'>
                <h2>Year</h2>
                <p>2024</p>
            </div>
                <div className='dashBorder'></div>
            <div className='yearQuartCardCol2'>
                <h2>Quarter</h2>
                <p>Q1</p>
            </div>
        </div>
    </div>
  )
}

export default YearQuarterCard;