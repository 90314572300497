import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import NewCommentsBtn from "../../../button/newCommentsBtn/NewCommentsBtn";
import EditButton from "../../../button/editButton/EditButton";

import "./style.css";
import GHGEmission from "../ghgEmission/GHGEmission";
import OffsetsAndCredits from "../offsetsAndCredits/OffsetsAndCredits";
import FileUploadBoxDecleration from "../../../fileUploadBox/FileUploadBoxDecleration";
import { SelectBox2 } from "../../step3/selectBox/SelectBox";

function QuarterTwo({ formik }) {
  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Typography>
              <div className="GenrlInfo">
                <h3>2024 | Quarter 2</h3>
              </div>
            </Typography>
            <SelectBox2
              headName={"Updated Net Zero Target Year"}
              formik={formik}
              name="updatedNetZeroTarget"
              value={formik?.values?.updatedNetZeroTarget}
              touch={formik?.touched?.updatedNetZeroTarget}
              error={formik?.errors?.updatedNetZeroTarget}
              onChange={formik.handleChange}
              options={[2030, 2034, 2038, 2042, 2046, 2050]}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="quarterTwoCon">
            <GHGEmission
              formik={formik}
              resultHeaderName={"GHG Emissions Increases"}
              resultTitle1={"Scope 1"}
              resultTitle2={"Scope 2"}
              resultTitle3={"Scope 3"}
              name1="ghgEmissionsIncrease.scope1"
              value1={formik?.values?.ghgEmissionsIncrease?.scope1}
              error1={formik?.errors?.ghgEmissionsIncrease?.scope1}
              touched1={formik?.touched?.ghgEmissionsIncrease?.scope1}
              name2="ghgEmissionsIncrease.scope2"
              value2={formik?.values?.ghgEmissionsIncrease?.scope2}
              error2={formik?.errors?.ghgEmissionsIncrease?.scope2}
              touched2={formik?.touched?.ghgEmissionsIncrease?.scope2}
              name3="ghgEmissionsIncrease.scope3"
              value3={formik?.values?.ghgEmissionsIncrease?.scope3}
              error3={formik?.errors?.ghgEmissionsIncrease?.scope3}
              touched3={formik?.touched?.ghgEmissionsIncrease?.scope3}
            />
            <GHGEmission
              formik={formik}
              resultHeaderName={"GHG Emissions Reductions"}
              resultTitle1={"Scope 1"}
              resultTitle2={"Scope 2"}
              resultTitle3={"Scope 3"}
              name1="ghgEmissionsReductions.scope1"
              value1={formik?.values?.ghgEmissionsReductions?.scope1}
              error1={formik?.errors?.ghgEmissionsReductions?.scope1}
              touched1={formik?.touched?.ghgEmissionsReductions?.scope1}
              name2="ghgEmissionsReductions.scope2"
              value2={formik?.values?.ghgEmissionsReductions?.scope2}
              error2={formik?.errors?.ghgEmissionsReductions?.scope2}
              touched2={formik?.touched?.ghgEmissionsReductions?.scope2}
              name3="ghgEmissionsReductions.scope3"
              value3={formik?.values?.ghgEmissionsReductions?.scope3}
              error3={formik?.errors?.ghgEmissionsReductions?.scope3}
              touched3={formik?.touched?.ghgEmissionsReductions?.scope3}
            />
            <GHGEmission
              formik={formik}
              resultHeaderName={"Removals"}
              resultTitle1={"Within Its Boundaries"}
              resultTitle2={"In The Wider Value Chain"}
              resultTitle3={"Outside The Value Chain"}
              name1="removals.withinBoundaries"
              value1={formik?.values?.removals?.withinBoundaries}
              error1={formik?.errors?.removals?.withinBoundaries}
              touched1={formik?.touched?.removals?.withinBoundaries}
              name2="removals.inTheWiderValueChain"
              value2={formik?.values?.removals?.inTheWiderValueChain}
              error2={formik?.errors?.removals?.inTheWiderValueChain}
              touched2={formik?.touched?.removals?.inTheWiderValueChain}
              name3="removals.scope3"
              value3={formik?.values?.removals?.outsideTheValueChain}
              error3={formik?.errors?.removals?.outsideTheValueChain}
              touched3={formik?.touched?.removals?.outsideTheValueChain}
            />
            <OffsetsAndCredits
              formik={formik}
              resultHeaderName={"Offsets and credits outside the value chain"}
              name="offsetsAndCredits.outsideTheValueChain"
              value={formik?.values?.offsetsAndCredits?.outsideTheValueChain}
              error={formik?.errors?.offsetsAndCredits?.outsideTheValueChain}
              touched={formik?.touched?.offsetsAndCredits?.outsideTheValueChain}
            />
            <OffsetsAndCredits
              formik={formik}
              resultHeaderName={"Net GHG Emissions"}
              name="offsetsAndCredits.netGHGEmission"
              value={formik?.values?.offsetsAndCredits?.netGHGEmission}
              error={formik?.errors?.offsetsAndCredits?.netGHGEmission}
              touched={formik?.touched?.offsetsAndCredits?.netGHGEmission}
            />
            <OffsetsAndCredits
              formik={formik}
              resultHeaderName={"Expected GHG Reduction Percentage"}
              name="offsetsAndCredits.expectedGHGReductionPercentage"
              value={
                formik?.values?.offsetsAndCredits
                  ?.expectedGHGReductionPercentage
              }
              error={
                formik?.errors?.offsetsAndCredits
                  ?.expectedGHGReductionPercentage
              }
              touched={
                formik?.touched?.offsetsAndCredits
                  ?.expectedGHGReductionPercentage
              }
            />
            <OffsetsAndCredits
              formik={formik}
              resultHeaderName={"Achieved GHG Reduction Percentage"}
              name="offsetsAndCredits.achievedGHGReductionPercentage"
              value={
                formik?.values?.offsetsAndCredits
                  ?.achievedGHGReductionPercentage
              }
              error={
                formik?.errors?.offsetsAndCredits
                  ?.achievedGHGReductionPercentage
              }
              touched={
                formik?.touched?.offsetsAndCredits
                  ?.achievedGHGReductionPercentage
              }
            />
            <OffsetsAndCredits
              formik={formik}
              resultHeaderName={"Gap for SEE Rating Target"}
              gapForSee={true}
              name="offsetsAndCredits.gapForSEERatingTarget"
              value={formik?.values?.offsetsAndCredits?.gapForSEERatingTarget}
              error={formik?.errors?.offsetsAndCredits?.gapForSEERatingTarget}
              touched={
                formik?.touched?.offsetsAndCredits?.gapForSEERatingTarget
              }
            />
            <div className="wd-100 subsidiarySec">
              <div>
                <h1
                  style={{
                    color: "var(--Neutral-Charcoal-Black, #1D1D1B)",
                    fontFamily: "Uniform",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "20px",
                    margin: "0",
                    WebkitTextStrokeWidth: "0.6px",
                  }}
                >
                  Upload any supporting documents to validate your report
                </h1>
              </div>
              <div className="wd-100 mb-28 addPdfWidth">
                <FileUploadBoxDecleration
                  formik={formik}
                  field="relatedDocuments"
                  value={formik?.values?.relatedDocuments}
                  folder="registration"
                  // errorIcon={WarningIcon}
                  error={
                    !!formik.touched?.relatedDocuments &&
                    formik.errors?.relatedDocuments
                  }
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default QuarterTwo;
