import React from 'react';

import "./style.css";

import ChatBoxIcon from "../../../assets/icons/ChatBoxBtnIcon.svg";

function ViewCommentsBtn() {
  return (
    <div className='viewCommentBtnCon cursorPointer'>
      <img src={ChatBoxIcon} alt='ChatBoxIcon' />
      <p>View Comments</p>
    </div>
  )
}

export default ViewCommentsBtn;