import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';

import "./style.css";
import EmissDisaggreType from '../emissDisaggreType/EmissDisaggreType';
import PdfUpload from '../../../pdfUpload/PdfUpload';
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';


function InfoOnEmissionPart2({formik}) {
  
  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel6a-content"
        id="panel6a-header"
      >
        <div className='typographyRow'>
          <Typography>
          <div className='GenrlInfo'>
              <h3>INFORMATION ON EMISSIONS 2</h3>
          </div>
          </Typography>
          <div className='editBtnRow'>
              <NewCommentsBtn btnTitle={"2 New Issue"} />
              <EditButton />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='InfoEmiss2Container'>
          <h2>Emissions disaggregated by source types</h2>

            <EmissDisaggreType />

            <div className='diaggreFacilSec'>
               <h1>Emissions disaggregated by facility (recommended for individual facilities with stationary combustion emissions over 10,000 mtCO2e)</h1>
               <div className='emisViewFacility1'>
                  <div className='emissScope2row'>
                    <div className='emissScope2Col1'>
                      <h1 className='facilityFont'>Facility 1</h1>
                    </div>
                    <div className='emissScope2Col2'>
                      <ViewCommentsBtn 
                        secName={"Information on Emission 2"}
                        question={"Facility 1"} 
                        answer={"Codewave"} 
                      />
                    </div>
                  </div>
                  <div className='emisFacilRow'>
                    <div className='emisFacilCol1'>
                      <h1>Facility</h1>
                    </div>
                    <div className='emisFacilCol2'>
                      <p>Name Here</p>
                    </div>
                  </div>
                  <div className='emisFacilRow'>
                    <div className='emisFacilCol1'>
                      <h1>Scope 1 Emissions</h1>
                    </div>
                    <div className='emisFacilCol2'>
                      <p>Value Here</p>
                    </div>
                  </div>
               </div>
            </div>

            <div className='diaggreFacilSec'>
               <h1>Emissions disaggregated by country</h1>
               <div className='emisViewFacility1'>
                  <div className='emissScope2row'>
                    <div className='emissScope2Col1'>
                      <h1 className='facilityFont'>Country 1</h1>
                    </div>
                    <div className='emissScope2Col2'>
                      <ViewCommentsBtn 
                        secName={"Information on Emission 2"}
                        question={"Country 1"} 
                        answer={"India"} 
                      />
                    </div>
                  </div>
                  <div className='emisFacilRow'>
                    <div className='emisFacilCol1'>
                      <h1>Country</h1>
                    </div>
                    <div className='emisFacilCol2'>
                      <p>Name Here</p>
                    </div>
                  </div>
                  <div className='emisFacilRow'>
                    <div className='emisFacilCol1'>
                      <h1>Scope 1 Emissions</h1>
                    </div>
                    <div className='emisFacilCol2'>
                      <p>Value Here</p>
                    </div>
                  </div>
               </div>
            </div>

            <div className='ownAttributSec'>
              <h1>
                Emissions attributable to own generation of electricity, 
                heat, or stem that is sold or transferred to another
                organization
              </h1>
              <p>Value Here</p>
                <div className='viewCmtRow'>
                  <ViewCommentsBtn 
                    secName={"Information on Emission 2"}
                    question={"Emissions attributable to own generation of electricity, heat, or stem that is sold or transferred to another organization"} 
                    answer={"Value Here"} 
                  />
                </div>
            </div>

            <div className='ownAttributSec'>
              <h1>
                Emissions attributable to the generation of electricity, 
                heat or steam that is purchased for re-sale to non-end users
              </h1>
              <p>Value Here</p>
                <div className='viewCmtRow'>
                  <ViewCommentsBtn 
                    secName={"Information on Emission 2"}
                    question={"Emissions attributable to the generation of electricity, heat or steam that is purchased for re-sale to non-end users"} 
                    answer={"Value Here"} 
                  />
                </div>
            </div>

            <div className='ownAttributSec'>
              <h1>
                Emissions from GHGs not covered by the Kyoto Protocol (e.g., CFCs, NOx,)
              </h1>
              <p>Value Here</p>
                <div className='viewCmtRow'>
                  <ViewCommentsBtn 
                    secName={"Information on Emission 2"}
                    question={"Emissions from GHGs not covered by the Kyoto Protocol (e.g., CFCs, NOx,)"} 
                    answer={"Value Here"} 
                  />
                </div>
            </div>

            <div className='ownAttributSec'>
              <h1>
                Relevant ratio performance indicators (e.g. emissions per kilowatt-hour generated, sales, etc.)
              </h1>
              <PdfUpload />
              <div className='viewCmtRow'>
                  <ViewCommentsBtn 
                    secName={"Information on Emission 2"}
                    question={"Relevant ratio performance indicators (e.g. emissions per kilowatt-hour generated, sales, etc.)"} 
                    answer={"Pdf File"} 
                  />
                </div>
            </div>

        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default InfoOnEmissionPart2;