import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';

import "./style.css";
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';


function MethoFactorView({formik}) {
  
    
  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5a-content"
        id="panel5a-header"
      >
        <div className='typographyRow'>
          <Typography>
          <div className='GenrlInfo'>
              <h3>METHODOLOGIES AND EMISSION FACTORS</h3>
          </div>
          </Typography>
          <div className='editBtnRow'>
              <NewCommentsBtn btnTitle={"2 New Issue"} />
              <EditButton />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='methodoCalContainer'>
            <div className='methoUsedToSec'>
                <div className='nonGHGRow'>
                    <div className='nonGHGCol1'>
                        <h1>
                            Methodologies used to calculate or measure emissions other 
                            than those provided by the GHG Protocol. 
                            (Provide a reference or link to any non-GHG 
                            Protocol calculation tools used)
                        </h1>
                    </div>
                    <div className='nonGHGCol2'>
                        <p>
                            Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis 
                            scelerisque pharetra consequat sit. Sollicitudin amet magnis 
                            proin vel turpis quis aliquam pretium. 
                            Dignissim arcu adipiscing neque nulla tristique.
                        </p>
                    </div>
                </div>
                  <div className='viewCmtRow'>
                    <ViewCommentsBtn 
                      secName={"Methodologies and Emission Factors"} 
                      question={"Methodologies used to calculate or measure emissions other than those provided by the GHG Protocol. (Provide a reference or link to any non-GHG Protocol calculation tools used)"} 
                      answer={"Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra consequat sit. Sollicitudin amet magnis proin vel turpis quis aliquam pretium. Dignissim arcu adipiscing neque nulla tristique."} 
                    />
                  </div>
                <div className='nonGHGRow'>
                    <div className='nonGHGCol1'>
                        <h1>Link</h1>
                    </div>
                    <div className='nonGHGCol2'>
                        <p>www.websitelinkhere.com</p>
                    </div>
                </div>
                  <div className='viewCmtRow'>
                    <ViewCommentsBtn 
                      secName={"Methodologies and Emission Factors"} 
                      question={"Link"} 
                      answer={"www.websitelinkhere.com"} 
                    />
                  </div>
            </div>
        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default MethoFactorView;