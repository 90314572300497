import react, { Fragment, useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import "./style.css";

import Close_MD from "../../../assets/icons/Close_MD.svg";
import { useGetRegistrationLogsQuery } from "../../../features/admin/adminApiSlice";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";


function ViewFeedback({ isOpen, onClose }) {
    // const [searchParams, setSearchParams] = useSearchParams();
    // const userType = searchParams.get("userType");
    // const userId = searchParams.get("userId");
    // const dispatch = useDispatch();
    // const {
    //     data: regisLogDetailsData,
    //   } = useGetRegistrationLogsQuery({
    //     userId: userId,
    //     userType: userType,
    //   });
    

    const handleClose = () => {
        onClose();
      };

    const [state, setState] = useState({
        // top: false,
        // left: false,
        // bottom: false,
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };
    
      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="regisLogHeader">
                <h1>Feedback</h1>
                <img src={Close_MD} onClick={handleClose} />
            </div>
            <div className="viewFeedbacks">
                <h1>Emissions Declaration</h1>
                <div className="feedbackData">
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra
                        consequat sit. Sollicitudin amet magnis proin vel turpis quis aliquam pretium. 
                        Dignissim arcu adipiscing neque nulla tristique?
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra
                        consequat sit. Sollicitudin amet magnis proin vel turpis quis aliquam pretium. 
                        Dignissim arcu adipiscing neque nulla tristique?
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra
                        consequat sit. Sollicitudin amet magnis proin vel turpis quis aliquam pretium. 
                        Dignissim arcu adipiscing neque nulla tristique?
                    </p>
                  </div>
                  <div className="feedbackBy">
                    <h2>by Mark Anthony (Consultant)</h2>
                    <div className="feedbackByBorder"></div>
                    <span className="logTime">On {moment().format('DD/MM/YYYY')} at {moment().format('h:mm')}</span>
                  </div>
            </div>
        </Box>
      );

  return (
    <div>
    {['right'].map((anchor) => (
      <Fragment key={anchor}>
        <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
        <Drawer
          anchor={anchor}
          open={isOpen}
          onClose={onClose}
        >
          {list(anchor)}
        </Drawer>
      </Fragment>
    ))}
  </div>
  );
}

export default ViewFeedback;
