import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { cleanObject, omitEmptyKeys } from "../../../../utils/helper";

import { usePreviewInfoMutation } from "../../../../features/organization/organizationApiSlice";
import {
  useOrgAudInfoMutation,
  usePreviewAudInfoMutation,
} from "../../../../features/auditor/auditorApiSlice";
import {
  useOrgConsInfoMutation,
  usePreviewConsInfoMutation,
} from "../../../../features/consultant/consultantApiSlice";

import { orgDetailsSchema } from "../../../../Validators/register.validators";

import BasicTimeline from "../../../../components/timeLine/BasicTimeline";
import SelectInputBox, {
  SelecSubCatBox,
  SelectCountryInputBox,
  SelectStateInputBox,
} from "../../../../components/InputBox/selectBox/SelectInputBox";
import InputBox from "../../../../components/InputBox/InputBox";
import FormButton from "../../../../components/button/FormButton";
import RegCancelButton from "../../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../../components/button/regBackBtn/RegBackButton";
import { getUserFromLocal } from "../../../../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { setUserHandler } from "../../../../features/user/userAction";
import {
  useOrgInfoMutation,
  useOrgPreviewInfoMutation,
} from "../../../../features/registration/organization/organizatonApiSlice";

import "./style.css";

import RegistrationLogo from "../../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import { userActions } from "../../../../features/user/userSlice";
import { showError } from "../../../../features/snackbar/snackbarAction";
import PageLoader from "../../../../components/Loader/PageLoader";

const categories = [
  {
    name: "Energy",
    value: "Energy",
    subcategories: [
      {
        name: "Energy Equipment & Services",
        value: "Energy Equipment & Services",
      },
      {
        name: "Oil, Gas & Consumable Fuels",
        value: "Oil, Gas & Consumable Fuels",
      },
    ],
  },
  {
    name: "Materials",
    value: "Materials",
    subcategories: [
      { name: "Chemicals", value: "Chemicals" },
      { name: "Construction Materials", value: "Construction Materials" },
      { name: "Containers & Packaging", value: "Containers & Packaging" },
      { name: "Metals & Mining", value: "Metals & Mining" },
      { name: "Paper & Forest Products", value: "Paper & Forest Products" },
    ],
  },
  {
    name: "Industrials",
    value: "Industrials",
    subcategories: [
      { name: "Aerospace & Defense", value: "Aerospace & Defense" },
      { name: "Building Products", value: "Building Products" },
      {
        name: "Construction & Engineering",
        value: "Construction & Engineering",
      },
      { name: "Electrical Equipment", value: "Electrical Equipment" },
      { name: "Industrial Conglomerates", value: "Industrial Conglomerates" },
      { name: "Machinery", value: "Machinery" },
      {
        name: "Trading Companies & Distributors",
        value: "Trading Companies & Distributors",
      },
    ],
  },
  {
    name: "Consumer Discretionary",
    value: "Consumer Discretionary",
    subcategories: [
      { name: "Retail", value: "Retail" },
      { name: "Apparel & Luxury Goods", value: "Apparel & Luxury Goods" },
      {
        name: "Automobiles and Auto Components",
        value: "Automobiles and Auto Components",
      },
      {
        name: "Hotels, Restaurants and Leisure Companies",
        value: "Hotels, Restaurants and Leisure Companies",
      },
      { name: "Consumer Services", value: "Consumer Services" },
      { name: "Media", value: "Media" },
      {
        name: "Consumer Durables and Apparel",
        value: "Consumer Durables and Apparel",
      },
      {
        name: "Diversified Consumer Services",
        value: "Diversified Consumer Services",
      },
      { name: "Leisure Products", value: "Leisure Products" },
      {
        name: "Internet and Direct Marketing Retail",
        value: "Internet and Direct Marketing Retail",
      },
    ],
  },
  {
    name: "Consumer Staples",
    value: "Consumer Staples",
    subcategories: [
      { name: "Beverages", value: "Beverages" },
      { name: "Food & Staples Retailing", value: "Food & Staples Retailing" },
      { name: "Food Products", value: "Food Products" },
      {
        name: "Household & Personal Products",
        value: "Household & Personal Products",
      },
      { name: "Tobacco", value: "Tobacco" },
    ],
  },
  {
    name: "Health Care",
    value: "Health Care",
    subcategories: [
      { name: "Biotechnology", value: "Biotechnology" },
      {
        name: "Health Care Equipment & Supplies",
        value: "Health Care Equipment & Supplies",
      },
      {
        name: "Health Care Providers & Services",
        value: "Health Care Providers & Services",
      },
      { name: "Health Care Technology", value: "Health Care Technology" },
      { name: "Pharmaceuticals", value: "Pharmaceuticals" },
    ],
  },
  {
    name: "Financials",
    value: "Financials",
    subcategories: [
      { name: "Banks", value: "Banks" },
      { name: "Capital Markets", value: "Capital Markets" },
      { name: "Consumer Finance", value: "Consumer Finance" },
      { name: "Diversified Financials", value: "Diversified Financial" },
      { name: "Insurance", value: "Insurance" },
      {
        name: "Mortgage Real Estate Investment Trusts (REITs)",
        value: "Mortgage Real Estate Investment Trusts (REITs)",
      },
      {
        name: "Real Estate Management & Development",
        value: "Real Estate Management & Development",
      },
      {
        name: "Thrifts & Mortgage Finance",
        value: "Thrifts & Mortgage Finance",
      },
    ],
  },
  {
    name: "Information Technology",
    value: "Information Technology",
    subcategories: [
      { name: "Communications Equipment", value: "Communications Equipment" },
      { name: "IT Services", value: "IT Services" },
      { name: "Software", value: "Software" },
      {
        name: "Technology Hardware, Storage & Peripherals",
        value: "Technology Hardware, Storage & Peripherals",
      },
      {
        name: "Electronic Equipment, Instruments & Components",
        value: "Electronic Equipment, Instruments & Components",
      },
      {
        name: "Semiconductors & Semiconductor Equipment",
        value: "Semiconductors & Semiconductor Equipment",
      },
    ],
  },
  {
    name: "Communication Services",
    value: "Communication Services",
    subcategories: [
      {
        name: "Diversified Telecommunication Services",
        value: "Diversified Telecommunication Services",
      },
      { name: "Entertainment", value: "Entertainment" },
      {
        name: "Interactive Media & Services",
        value: "Interactive Media & Services",
      },
    ],
  },
  {
    name: "Utilities",
    value: "Utilities",
    subcategories: [
      { name: "Electric Utilities", value: "Electric Utilities" },
      { name: "Gas Utilities", value: "Gas Utilities" },
      { name: "Multi-Utilities", value: "Multi-Utilities" },
      { name: "Water Utilities", value: "Water Utilities" },
    ],
  },
  {
    name: "Real Estate",
    value: "Real Estate",
    subcategories: [
      {
        name: "Equity Real Estate Investment Trusts (REITs)",
        value: "Equity Real Estate Investment Trusts (REITs) ",
      },
      {
        name: "Real Estate Management & Development",
        value: "Real Estate Management & Development ",
      },
    ],
  },
];

const orgCategoryList = [
  { name: "Category 1", value: "category1" },
  { name: "Category 2", value: "category2" },
  { name: "Category 3", value: "category3" },
  { name: "Category 4", value: "category4" },
];

const countryStateMasterData = {
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};

const stateList = [{ name: "Uttar Pradesh", value: "up" }];

const employeeList = [
  { name: "10-100", value: "10-100" },
  { name: "101-500", value: "101-500" },
  { name: "501-1000", value: "501-1000" },
  { name: "1001-5000", value: "1001-5000" },
  { name: "5001-10000", value: "5001-10000" },
  { name: "10000+", value: "10000+" },
];

const sectorList = [
  { value: "sector1", name: "Energy" },
  { value: "sector2", name: "Materials" },
  { value: "Sector3", name: "Industrials" },
  { value: "sector4", name: "Consumer Discretionary" },
  { value: "sector5", name: "Consumer Staples" },
  { value: "sector6", name: "Health Care" },
  { value: "sector7", name: "Financials" },
  { value: "sector8", name: "Information Technology" },
  { value: "sector9", name: "Communication Services" },
  { value: "sector10", name: "Utilities" },
  { value: "sector11", name: "Real Estate" },
];

const subCategoryList = [
  { name: "Sub-category1", value: "sub-category1" },
  { name: "Sub-category2", value: "sub-category2" },
  { name: "Sub-category3", value: "sub-category3" },
  { name: "Sub-category4", value: "sub-category4" },
];

const sectorSubcategoryMapping = {
  Energy: ["Energy Equipment & Services", "Oil, Gas & Consumable Fuels"],
  Materials: [
    "Chemicals",
    "Construction Materials",
    "Containers & Packaging",
    "Metals & Mining",
  ],
};

function OrganizationDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = getUserFromLocal();
  const userReduxDetails = useSelector((state) => state.user);
  const [resposnse, setResoponse] = useState("");
  const [currentStep, setCurrentStep] = useState("organization-details");
  const [selectedSector, setSelectedSector] = useState(null);

  const [subCategories, setSubCategories] = useState([]);

  // Function to handle sector selection and update sub-categories
  const handleSectorChange = (selectedSector) => {
    const selectedSubCategories =
      sectorSubcategoryMapping[selectedSector] || [];
    setSubCategories(selectedSubCategories);
    setSelectedSector(selectedSector);
    formik.setFieldValue("orgActiveSector", selectedSector);
    formik.setFieldValue("orgActiveSubCategory", "");
  };

  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { orgDetails } = response || {};

  const [
    previewInfo,
    {
      isLoading: previewIsLoading,
      isSuccess: previewIsSuccess,
      error: previewIsError,
    },
  ] = useOrgPreviewInfoMutation();

  const [
    previewConsInfo,
    {
      isLoading: previewConsIsLoading,
      isSuccess: previewConsIsSuccess,
      error: previewConsIsError,
    },
  ] = usePreviewConsInfoMutation();

  const [
    previewAudInfo,
    {
      isLoading: previewAudIsLoading,
      isSuccess: previewAudIsSuccess,
      error: previewAudIsError,
    },
  ] = usePreviewAudInfoMutation();

  const [
    orgInfo,
    {
      isLoading: organisationIsLoading,
      isSuccess: organisationIsSuccess,
      error: organisationIsError,
    },
  ] = useOrgInfoMutation();

  const [
    orgAudInfo,
    {
      isLoading: auditorIsLoading,
      isSuccess: auditorIsSuccess,
      error: auditorIsError,
    },
  ] = useOrgAudInfoMutation();

  const [
    orgConsInfo,
    {
      isLoading: consultantIsLoading,
      isSuccess: consultantIsSuccess,
      error: consultantIsError,
    },
  ] = useOrgConsInfoMutation();

  useEffect(() => {
    if (userDetails?.userType === "organization") {
      previewInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "auditor") {
      previewAudInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "consultant") {
      previewConsInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
  }, [userDetails?.userId, userDetails?.trackingCode, userDetails?.userType]);

  const basicValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
  };

  const emptyValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
    orgName: "",
    orgWebsiteLink: "",
    positionInOrg: "",
    orgCategory: "",
    orgBehalfFilling: "false",
    lookingForRating: "",
    orgHeadquarter: {
      fullAddress: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
    },
    orgBranch: [],
    noOfEmployees: "",
    orgActiveSector: "",
    orgActiveSubCategory: "",
  };

  const formik = useFormik({
    initialValues: {
      _id: userDetails?.userId,
      trackingCode: userReduxDetails?.trackingCode,
      orgName: orgDetails?.orgName ?? "",
      orgWebsiteLink: orgDetails?.orgWebsiteLink ?? "",
      positionInOrg: orgDetails?.positionInOrg ?? "",
      orgCategory: orgDetails?.orgCategory ?? "",
      orgBehalfFilling: orgDetails?.orgBehalfFilling ?? "false",
      lookingForRating: orgDetails?.lookingForRating ?? "",
      orgHeadquarter: {
        fullAddress: orgDetails?.orgHeadquarter?.fullAddress ?? "",
        city: orgDetails?.orgHeadquarter?.city ?? "",
        country: orgDetails?.orgHeadquarter?.country ?? "",
        state: orgDetails?.orgHeadquarter?.state ?? "",
        zipCode: orgDetails?.orgHeadquarter?.zipCode ?? "",
      },
      orgBranch: orgDetails?.orgBranch?.map((branch) => ({
        fullAddress: branch?.fullAddress ?? "",
        city: branch?.city ?? "",
        country: branch?.country ?? "",
        state: branch?.state ?? "",
        zipCode: branch?.zipCode ?? "",
      })) || [
        { fullAddress: "", city: "", country: "", state: "", zipCode: "" },
      ],
      noOfEmployees: orgDetails?.noOfEmployees ?? "",
      orgActiveSector: orgDetails?.orgActiveSector ?? "",
      orgActiveSubCategory: orgDetails?.orgActiveSubCategory ?? "",
    },
    enableReinitialize: true,
    validationSchema: orgDetailsSchema,

    onSubmit: (values) => {
      const userTypes = ["organization", "auditor", "consultant"];
      const registerFunctions = [orgInfo, orgAudInfo, orgConsInfo];
      const organizationDetailValues = structuredClone(values);
      cleanObject(organizationDetailValues);

      const userTypeIndex = userTypes.indexOf(userDetails?.userType);
      if (userTypeIndex !== -1) {
        registerFunctions[userTypeIndex](organizationDetailValues)
          .unwrap()
          .then((res) => {
            // setFormNumber(3);
            navigate("/auth/registration/additionalDetails");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((e) => {
            dispatch(
              showError({
                message: e?.data?.message ?? "Something went wrong!",
              })
            );
          });
      }
    },
  });

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  const addBranch = () => {
    formik.setFieldValue("orgBranch", [
      ...formik.values.orgBranch,
      {
        fullAddress: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
      },
    ]);
  };

  const onRemoveBranch = ({ deleteIndex }) => {
    const updatedBranch = formik?.values?.orgBranch?.filter(
      (_, index) => index !== deleteIndex
    );
    formik.setFieldValue("orgBranch", updatedBranch);
  };

  console.log("formikOrganizationValues", formik?.values);
  console.log("formikOrganizationErrors", formik?.errors);

  return (
    <div className="container orgDetails_section">
      {(previewIsLoading ||
        previewConsIsLoading ||
        previewAudIsLoading ||
        organisationIsLoading ||
        auditorIsLoading ||
        consultantIsLoading) && <PageLoader />}
      <div className="orgDetailsSec_Col1">
        <img className="orgDetails_topBg" src={SignUpTopBg} />
        <img className="orgDetails_bottomBg" src={SignUpBottomBg} />
        <img className="orgDetails_leftBg" src={SignUpLeftBg} />

        <div className="orgDetails_timeLineContents">
          <div className="orgDetails_logoDiv">
            <img src={RegistrationLogo} />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>

      <div className="orgDetailsSec_Col2">
        <h1>Organization Details</h1>
        {/* <p className="mb-30">
          Lorem ipsum dolor sit amet consectetur. Dictum eget viverra at
          pellentesque consectetur fames ullamcorper. Aliquet volutpat cras
          cursus at morbi consectetur. Gravida nunc in sit porta.
        </p> */}
        <form noValidate onSubmit={formik.handleSubmit}>
          {/* {!(userType === "organization") && (
            <>
              <div className="form_inputHeading">
                <p>
                  Are you filling this in on behalf of an organization that is
                  wanting to receive a SEE rating?
                </p>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formik.values.orgBehalfFilling}
                    onChange={(e)=>{formik?.handleChange(e); formik?.setFieldValue("orgBehalfFilling", e.target.value)}}
                    className="wd-100"
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" />}
                      label="Yes"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 14,
                          fontFamily : "Uniform",
                          fontWeight: 400,
                        },
                      }}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" />}
                      label="No"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 14,
                          fontFamily : "Uniform",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </RadioGroup>
              </div>

              {formik.values.orgBehalfFilling==="false" && <div
                className="form_inputHeading"
                style={{ marginBottom: "15px" }}
              >
                <p>
                  Are you looking for your organization to receive a SEE rating
                  ?
                </p>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formik.values.lookingForRating}
                    onChange={(e)=>{formik?.handleChange(e); formik?.setFieldValue("lookingForRating", e.target.value)}}
                    className="wd-100"
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio size="small" />}
                      label="Yes"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 14,
                          fontFamily : "Uniform",
                          fontWeight: 400,
                        },
                      }}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio size="small" />}
                      label="No"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 14,
                          fontFamily : "Uniform",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </RadioGroup>
              </div>}
            </>
          )} */}

          <div className="form-row">
            <div className="wd-100 ">
              <InputBox
                headName={"Name of your Organization"}
                placeholder=" Enter a name"
                name="orgName"
                value={formik.values.orgName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.orgName && formik.errors.orgName}
                required
              />
            </div>
          </div>

          <div className="wd-100 titleRow">
            <InputBox
              headName={"Website Link:"}
              placeholder="www.website.com"
              name="orgWebsiteLink"
              value={formik.values.orgWebsiteLink}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik.touched.orgWebsiteLink && formik.errors.orgWebsiteLink
              }
              required
            />
          </div>

          {formik.values.orgBehalfFilling === "false" && (
            <div className="form-row">
              <div className="wd-50 titleRow">
                <InputBox
                  headName={"Designation / Position In The Organization"}
                  placeholder="Enter your position in the organization"
                  name="positionInOrg"
                  value={formik.values.positionInOrg}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.positionInOrg &&
                    formik.errors.positionInOrg
                  }
                  required
                />
              </div>
            </div>
          )}

          {/* <div className="form_inputHeading">
            <p>Please select the Category of your organization</p>
            <div className="form-row titleRow">
              <div className="wd-50">
                <SelectInputBox
                  data={orgCategoryList}
                  name="orgCategory"
                  value={formik.values.orgCategory}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  headName={"Category"}
                  selectPlaceholder={"Select"}
                  formik={formik}
                  error={
                    !!formik.touched.orgCategory && formik.errors.orgCategory
                  }
                  required
                />
              </div>
            </div>
          </div> */}

          <div className="form_inputHeading mt-28">
            <p>
              Please tell us the location of your organization’s headquarter and
              the number and location of the branches that you have (in each
              country of operation).
            </p>
          </div>

          <div className="headQuarter_section">
            <h3 className="branchSecHeading">Headquarters</h3>
            <div className="headSections">
              <div className="wd-70">
                <InputBox
                  headName={"Your Address"}
                  placeholder="Enter the address here"
                  name="orgHeadquarter.fullAddress"
                  value={formik?.values?.orgHeadquarter?.fullAddress}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.orgHeadquarter?.fullAddress &&
                    formik.errors.orgHeadquarter?.fullAddress
                  }
                  required
                />
              </div>
              <div className="orCityDiv">
                <InputBox
                  headName={"City / Town / Suburb"}
                  placeholder="Enter city / town / suburb"
                  name="orgHeadquarter.city"
                  value={formik?.values?.orgHeadquarter?.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.orgHeadquarter?.city &&
                    formik.errors.orgHeadquarter?.city
                  }
                  required
                />
              </div>
            </div>

            <div className="headSections">
              <div className="wd-33">
                <SelectCountryInputBox
                  data={countryStateMasterData}
                  headName={"Country"}
                  selectPlaceholder={"Enter Country"}
                  name="orgHeadquarter.country"
                  value={formik?.values?.orgHeadquarter?.country}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.orgHeadquarter?.country &&
                    formik.errors.orgHeadquarter?.country
                  }
                  required
                />
              </div>
              <div className="wd-33">
                <SelectStateInputBox
                  headName={"State / Region"}
                  selectPlaceholder={" Enter state / region"}
                  data={countryStateMasterData}
                  selectedData={formik?.values?.orgHeadquarter?.country}
                  name="orgHeadquarter.state"
                  value={formik?.values?.orgHeadquarter?.state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.orgHeadquarter?.state &&
                    formik.errors.orgHeadquarter?.state
                  }
                  required
                />
              </div>
              <div className="wd-33">
                <InputBox
                  headName={"Zipcode / Postal Code"}
                  placeholder="Enter zipcode / postal code"
                  name="orgHeadquarter.zipCode"
                  value={formik?.values?.orgHeadquarter?.zipCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.orgHeadquarter?.zipCode &&
                    formik.errors.orgHeadquarter?.zipCode
                  }
                  required
                />
              </div>
            </div>
          </div>

          {formik?.values?.orgBranch?.map((_, index) => (
            <div
              className="headQuarter_section"
              key={index}
              style={{ marginTop: "10px" }}
            >
              <div className="remove_div">
                <h3 className="branchSecHeading">Branch {index + 1} </h3>
                <p
                  className="remove cursorPointer"
                  onClick={onRemoveBranch.bind(null, {
                    deleteIndex: index,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faCircleMinus}
                    className="fontAwesomeIcon_img"
                    style={{ color: "#008BB2" }}
                  />
                  Remove
                </p>
              </div>

              {/* <div className=''> */}
              <div className="headSections">
                <div className="wd-70">
                  <InputBox
                    headName={"Your Address"}
                    placeholder="Enter the address here"
                    name={`orgBranch[${index}].fullAddress`}
                    value={formik?.values?.orgBranch?.[index]?.fullAddress}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.orgBranch?.[index]?.fullAddress &&
                      formik.errors.orgBranch?.[index]?.fullAddress
                    }
                    required
                  />
                </div>
                <div className="orCityDiv">
                  <InputBox
                    headName={"City / Town / Suburb"}
                    placeholder="Enter city / town / suburb"
                    name={`orgBranch[${index}].city`}
                    value={formik?.values?.orgBranch?.[index]?.city}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.orgBranch?.[index]?.city &&
                      formik.errors.orgBranch?.[index]?.city
                    }
                    required
                  />
                </div>
              </div>
              <div className="headSections">
                <div className="wd-33">
                  <SelectCountryInputBox
                    data={countryStateMasterData}
                    headName={"Country"}
                    selectPlaceholder={"Enter country"}
                    name={`orgBranch[${index}].country`}
                    value={formik?.values?.orgBranch?.[index]?.country}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    formik={formik}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.orgBranch?.[index]?.country &&
                      formik.errors.orgBranch?.[index]?.country
                    }
                    required
                  />
                </div>
                <div className="wd-33">
                  <SelectStateInputBox
                    headName={"State / Region"}
                    selectPlaceholder={"Enter state / region"}
                    data={countryStateMasterData}
                    name={`orgBranch[${index}].state`}
                    value={formik?.values?.orgBranch?.[index]?.state}
                    selectedData={formik?.values?.orgBranch?.[index]?.country}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    formik={formik}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.orgBranch?.[index]?.state &&
                      formik.errors.orgBranch?.[index]?.state
                    }
                    required
                  />
                </div>
                <div className="wd-33">
                  <InputBox
                    headName={"Zipcode / Postal Code"}
                    placeholder="Enter zipcode / postal code"
                    name={`orgBranch[${index}].zipCode`}
                    value={formik?.values?.orgBranch?.[index]?.zipCode}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.orgBranch?.[index]?.zipCode &&
                      formik.errors.orgBranch?.[index]?.zipCode
                    }
                    required
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
          ))}

          <div className="addBranch_div cursorPointer">
            <p className="addBranch" onClick={addBranch}>
              <FontAwesomeIcon
                icon={faCirclePlus}
                className="fontAwesomeIcon_img"
                style={{ color: "#008BB2" }}
              />
              Add a branch
            </p>
          </div>

          <div className="form-row">
            <div className="wd-50 titleRow mt-28">
              <SelectInputBox
                headName={"Number of Employees"}
                selectPlaceholder={"Select"}
                data={employeeList}
                name="noOfEmployees"
                value={formik?.values?.noOfEmployees}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                formik={formik}
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.noOfEmployees && formik.errors.noOfEmployees
                }
                required
              />
            </div>
          </div>

          <div className="form_inputHeading">
            <p>
              Please provide the sectors in which your organization is most
              active. We are using Global Industry Classification Standards
              (GICS)
            </p>
            <div className="wd-50">
              <SelectInputBox
                headName={"Sectors"}
                selectPlaceholder={"Select"}
                data={categories}
                name="orgActiveSector"
                value={formik?.values?.orgActiveSector}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleSectorChange(e.target.value);
                }}
                formik={formik}
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.orgActiveSector &&
                  formik.errors.orgActiveSector
                }
                required
              />
            </div>
          </div>

          <div className="form_inputHeading titleRow mt-28">
            <p>
              Please provide the GICS sub categories that your organization is
              most active in.
            </p>
            <div className="wd-50 mb-40">
              <SelecSubCatBox
                headName={"Sub - Category"}
                selectPlaceholder={"Select"}
                data={categories}
                category={formik?.values?.orgActiveSector}
                name="orgActiveSubCategory"
                value={formik?.values?.orgActiveSubCategory}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                formik={formik}
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.orgActiveSubCategory &&
                  formik.errors.orgActiveSubCategory
                }
                required
              />
            </div>
          </div>

          <div className="form-row od_BtnRow">
            <div
              className="cursorPointer"
              onClick={() => {
                navigate("/auth/registration/basicDetails");
              }}
            >
              <RegBackButton buttonName={"Back"} />
            </div>
            <div
              className=" cursorPointer cancelBtn_Row"
              // onClick={handleCancel}
            >
              <div
                onClick={() => {
                  formik.resetForm();
                  formik.setValues(emptyValues);
                }}
              >
                <RegCancelButton buttonName={"Cancel"} />
              </div>
              <div className=" NextBtn cursorPointer">
                <FormButton
                  buttonName={"Next"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"3px 12px"}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrganizationDetails;
