import React, { useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";

import "./style.css";

import DropDownIcon from "../../../assets/icons/selectDropDown.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";


// Default SelectBox
function SelectInputBox({
  headName,
  selectPlaceholder,
  data,
  name,
  value,
  onChange,
  defaultValue,
  errorIcon,
  error,
  formik,
  required,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(value || "");

  const handleOptionClick = (value) => {
    formik.setFieldValue(`${name}`, value?.value);
    setSelectedOption(value.name);
    formik.setFieldValue(`${name}`, value?.value);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="selectItem">
      <label className="input_label">
        {headName}
        {/* {required && <small className="error"> &nbsp;*</small>} */}
      </label>
      <FormControl sx={{ m: 0, minWidth: 120, width: "100%" }} size="small">
      {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
        Age
      </InputLabel> */}
        <Select
          displayEmpty
          labelId="demo-select-small"
          id="demo-select-small"
          value={value || ""}
          onChange={onChange}
          size="small"
          onBlur={formik?.handleBlur}
          name={name}
          className={`Home_selectBox ${error ? "error" : ""}`}
          sx={{ fontSize: 13, color: "#1D1D1B", padding: "2.8px 2.8px" }}
          renderValue={
            value !== ""
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    Select
                  </span>
                )
          }
        >
          {data?.map((item) => (
            <MenuItem
              value={item?.value}
              sx={{ fontSize: 13, color: "#5c6d8e" }}
            >
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && (
        <p
          error
          className="inputError"
          style={{ color: "#F45B69", fontWeight: "800", margin: 0 }}
        >
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
}
export default SelectInputBox;

// Conditional SelectBox
export const SelecSubCatBox = ({
  headName,
  selectPlaceholder,
  data,
  category,
  name,
  value,
  onChange,
  defaultValue,
  errorIcon,
  error,
  formik,
  required,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(value || "");

  const handleOptionClick = (value) => {
    formik.setFieldValue(`${name}`, value?.value);
    setSelectedOption(value.name);
    formik.setFieldValue(`${name}`, value?.value);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="selectItem">
      <label className="input_label">
        {headName}
        {required && <small className="error"> &nbsp;*</small>}
      </label>
      <FormControl sx={{ m: 0, minWidth: 120, width: "100%" }} size="small">
        <Select
          displayEmpty
          labelId="demo-select-small"
          id="demo-select-small"
          value={value || ""}
          onChange={onChange}
          size="small"
          onBlur={formik?.handleBlur}
          name={name}
          className={`Home_selectBox ${error ? "error" : ""}`}
          sx={{ fontSize: 13, color: "#1D1D1B", padding: "2.8px 2.8px" }}
          renderValue={
            value !== ""
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    Select
                  </span>
                )
          }
        >
          {data.find((cat) => cat.value === category)?.subcategories.map((subcategory) => (
            <MenuItem
            value={subcategory?.value}
            // sx={{ fontSize: 13, color: "#5c6d8e" }}
            className="drpDownText"
          >
            {subcategory?.name}
          </MenuItem>
            ))}
        </Select>
      </FormControl>
      {error && (
        <p
          error
          className="inputError"
          style={{ color: "#F45B69", fontWeight: "800", margin: 0 }}
        >
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
};

export const SelectCountryInputBox = ({
  headName,
  selectPlaceholder,
  data,
  name,
  value,
  onChange,
  defaultValue,
  errorIcon,
  error,
  formik,
  required,
  ...props
}) => {

  return (
    <div className="selectItem">
      <label className="input_label">
        {headName}
        {required && <small className="error"> &nbsp;*</small>}
      </label>
      <FormControl sx={{ m: 0, minWidth: 120, width: "100%" }} size="small">
        <Select
          displayEmpty
          labelId="demo-select-small"
          id="demo-select-small"
          value={value || ""}
          onChange={onChange}
          size="small"
          onBlur={formik?.handleBlur}
          name={name}
          className={`Home_selectBox ${error ? "error" : ""}`}
          sx={{ fontSize: 13, color: "#1D1D1B", padding: "2.8px 2.8px" }}
          renderValue={
            value !== ""
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    Select
                  </span>
                )
          }
        >

          {Object.keys(data)?.map((item)=>(
            <MenuItem
            value={item}
            sx={{ fontSize: 13, color: "#5c6d8e" }}
          >
            {item}
          </MenuItem>
          ))}

        </Select>
      </FormControl>
      {error && (
        <p
          error
          className="inputError"
          style={{ color: "#F45B69", fontWeight: "800", margin: 0 }}
        >
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
};

export const SelectStateInputBox = ({
  headName,
  selectPlaceholder,
  data,
  selectedData,
  name,
  value,
  onChange,
  defaultValue,
  errorIcon,
  error,
  formik,
  required,
  ...props
}) => {

  return (
    <div className="selectItem">
      <label className="input_label">
        {headName}
        {required && <small className="error"> &nbsp;*</small>}
      </label>
      <FormControl sx={{ m: 0, minWidth: 120, width: "100%" }} size="small">
        <Select
          displayEmpty
          labelId="demo-select-small"
          id="demo-select-small"
          value={value || ""}
          onChange={onChange}
          size="small"
          onBlur={formik?.handleBlur}
          name={name}
          className={`Home_selectBox ${error ? "error" : ""}`}
          sx={{ fontSize: 13, color: "#1D1D1B", padding: "2.8px 2.8px" }}
          renderValue={
            value !== ""
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    Select
                  </span>
                )
          }
        >

          {data[selectedData]?.map((item)=>(
            <MenuItem
            value={item}
            sx={{ fontSize: 13, color: "#5c6d8e" }}
          >
            {item}
          </MenuItem>
          ))}

        </Select>
      </FormControl>
      {error && (
        <p
          error
          className="inputError"
          style={{ color: "#F45B69", fontWeight: "800", margin: 0 }}
        >
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
};