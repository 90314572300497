import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import EmissionComments from "../../../chats/EmissionComments";

function AuditMethoFactors({ findOneData }) {
  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>METHODOLOGIES AND EMISSION FACTORS</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditMethodoCalContainer">
            <div className="auditMethoUsedToSec">
              <div className="auditNonGHGRow">
                <div className="auditNonGHGCol1">
                  <h1>
                    Methodologies used to calculate or measure emissions other
                    than those provided by the GHG Protocol. (Provide a
                    reference or link to any non-GHG Protocol calculation tools
                    used)
                  </h1>
                </div>
                <div className="auditNonGHGCol2">
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Metus at maecenas
                    quis scelerisque pharetra consequat sit. Sollicitudin amet
                    magnis proin vel turpis quis aliquam pretium. Dignissim arcu
                    adipiscing neque nulla tristique.
                  </p>
                </div>
              </div>
              <div className="auditNonGHGRow">
                <div className="auditNonGHGCol1">
                  <h1>Link</h1>
                </div>
                <div className="auditNonGHGCol2">
                  <p>www.websitelinkhere.com</p>
                </div>
              </div>
              <div className="addCmtRow">
                <EmissionComments
                  className="addCmtRow"
                  mapping="methodologiesAndEmissionFactors"
                  issueId={
                    findOneData?.data?.declaration
                      ?.methodologiesAndEmissionFactors?.issueId
                  }
                  declarationId={findOneData?.data?.declaration?._id}
                  secName={"Methodologies and Emission Factors"}
                  question={
                    "Methodologies used to calculate or measure emissions other than those provided by the GHG Protocol. (Provide a reference or link to any non-GHG Protocol calculation tools used)"
                  }
                  answer={`Description : ${findOneData?.data?.declaration?.methodologiesAndEmissionFactors?.description}, Reference Link : ${findOneData?.data?.declaration?.methodologiesAndEmissionFactors?.referenceLink}`}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditMethoFactors;
