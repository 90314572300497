import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import PdfUpload from "../../../pdfUpload/PdfUpload";
import EmissDisaggreType from "../../stepOneView/emissDisaggreType/EmissDisaggreType";
import EmissionComments from "../../../chats/EmissionComments";

function AuditInfoOnEmissionPart2({ findOneData }) {
  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>INFORMATION ON EMISSIONS 2</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditInfoEmiss2Container">
            <h2>Emissions disaggregated by source types</h2>
            <EmissDisaggreType findOneData={findOneData} />

            <div className="auditDiaggreFacilSec">
              <h1>
                Emissions disaggregated by facility (recommended for individual
                facilities with stationary combustion emissions over 10,000
                mtCO2e)
              </h1>
              {findOneData?.data?.declaration?.infoOnEmission2
                ?.emissionsDisaggregatedByFacility?.aggregationResponse ===
              "recorded"
                ? findOneData?.data?.declaration?.infoOnEmission2?.emissionsDisaggregatedByFacility?.facilityData?.map(
                    (item, index) => (
                      <div className="auditEmisViewFacility1">
                        <div className="auditEmissScope2row">
                          <div className="auditEmissScope2Col1">
                            <h1 className="facility2Font">Facility 1</h1>
                          </div>
                          <div className="addCmtRow">
                            <EmissionComments
                              className="addCmtRow"
                              mapping="infoOnEmission2.emissionsDisaggregatedByFacility.facilityData"
                              secName="INFORMATION ON EMISSIONS 2"
                              question={`Facility ${index + 1}`}
                              issueId={item?.issueId}
                              arrayId={item?._id}
                              declarationId={
                                findOneData?.data?.declaration?._id
                              }
                            />
                          </div>
                        </div>
                        <div className="auditEmisFacilRow">
                          <div className="auditEmisFacilCol1">
                            <h1>Facility</h1>
                          </div>
                          <div className="auditEmisFacilCol2">
                            <p>Name Here</p>
                          </div>
                        </div>
                        <div className="auditEmisFacilRow">
                          <div className="auditEmisFacilCol1">
                            <h1>Scope 1 Emissions</h1>
                          </div>
                          <div className="auditEmisFacilCol2">
                            <p>Value Here</p>
                          </div>
                        </div>
                      </div>
                    )
                  )
                : findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsDisaggregatedByFacility?.aggregationResponse}
            </div>

            <div className="auditDiaggreFacilSec">
              <h1>Emissions disaggregated by country</h1>
              {findOneData?.data?.declaration?.infoOnEmission2
                ?.emissionsDisaggregatedByCountry?.aggregationResponse ===
              "recorded"
                ? findOneData?.data?.declaration?.infoOnEmission2?.emissionsDisaggregatedByCountry?.countryData.map(
                    (item, index) => (
                      <div className="auditEmisViewFacility1">
                        <div className="auditEmissScope2row">
                          <div className="auditEmissScope2Col1">
                            <h1 className="facility2Font">Country 1</h1>
                          </div>
                          <div className="addCmtRow">
                            <EmissionComments
                              className="addCmtRow"
                              mapping="infoOnEmission2.emissionsDisaggregatedByCountry.countryData"
                              secName={"Information on Emission 2"}
                              question={`Country ${index + 1}`}
                              issueId={item?.issueId}
                              arrayId={item?._id}
                              declarationId={
                                findOneData?.data?.declaration?._id
                              }
                            />
                          </div>
                        </div>
                        <div className="auditEmisFacilRow">
                          <div className="auditEmisFacilCol1">
                            <h1>Country</h1>
                          </div>
                          <div className="auditEmisFacilCol2">
                            <p>Name Here</p>
                          </div>
                        </div>
                        <div className="auditEmisFacilRow">
                          <div className="auditEmisFacilCol1">
                            <h1>Scope 1 Emissions</h1>
                          </div>
                          <div className="auditEmisFacilCol2">
                            <p>Value Here</p>
                          </div>
                        </div>
                      </div>
                    )
                  )
                : findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsDisaggregatedByCountry?.aggregationResponse}
            </div>
            <div className="auditOwnAttributSec">
              <h1>
                Emissions attributable to own generation of electricity, heat,
                or stem that is sold or transferred to another organization
              </h1>
              <p>Value Here</p>
              <EmissionComments
                className="addCmtRow"
                mapping="infoOnEmission2.emissionsFromSoldOrTransferredEnergy"
                secName={"Information on Emission 2"}
                question={
                  "Emissions attributable to own generation of electricity, heat, or stem that is sold or transferred to another organization"
                }
                answer={
                  findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsFromSoldOrTransferredEnergy?.value
                }
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsFromSoldOrTransferredEnergy?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
              />
            </div>

            <div className="auditOwnAttributSec">
              <h1>
                Emissions attributable to the generation of electricity, heat or
                steam that is purchased for re-sale to non-end users
              </h1>
              <p>Value Here</p>
              <EmissionComments
                className="addCmtRow"
                mapping="infoOnEmission2.emissionsFromPurchasedForResaleEnergy"
                secName={"Information on Emission 2"}
                question={
                  "Emissions attributable to the generation of electricity, heat or steam that is purchased for re-sale to non-end users"
                }
                answer={
                  findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsFromPurchasedForResaleEnergy?.value
                }
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsFromPurchasedForResaleEnergy?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
              />
            </div>

            <div className="auditOwnAttributSec">
              <h1>
                Emissions from GHGs not covered by the Kyoto Protocol (e.g.,
                CFCs, NOx,)
              </h1>
              <p>Value Here</p>
              <EmissionComments
                className="addCmtRow"
                mapping="infoOnEmission2.emissionsFromNonKyotoGHGs"
                secName={"Information on Emission 2"}
                question={
                  "Emissions from GHGs not covered by the Kyoto Protocol (e.g., CFCs, NOx,)"
                }
                answer={
                  findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsFromNonKyotoGHGs?.value
                }
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission2
                    ?.emissionsFromNonKyotoGHGs?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
              />
            </div>

            <div className="auditOwnAttributSec">
              <h1>
                Relevant ratio performance indicators (e.g. emissions per
                kilowatt-hour generated, sales, etc.)
              </h1>
              {findOneData?.data?.declaration?.infoOnEmission2?.performanceIndicatorsFile?.map(
                (item, index) => (
                  <div>
                    <PdfUpload />
                    <EmissionComments
                      className="addCmtRow"
                      mapping="infoOnEmission2.performanceIndicatorsFile"
                      secName={"Information on Emission 2"}
                      question={
                        "Relevant ratio performance indicators (e.g. emissions per kilowatt-hour generated, sales, etc.)"
                      }
                      issueId={item?.issueId}
                      arrayId={item?._id}
                      declarationId={findOneData?.data?.declaration?._id}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditInfoOnEmissionPart2;
