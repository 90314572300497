import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormButton from "../../../button/FormButton";

import "./style.css";

import AcceptImage from "../../../../assets/icons/acceptImage.svg";
import Button from "../../../button/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AcceptProfilePopUp({ open, onClose, title, onAccept }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="acceptTitleBox">
          <div className="acceptTitleDiv">
            <img src={AcceptImage} alt="acceptImage" />
            <h1>Are you sure you want to accept this application?</h1>
            <p>
              The user will be notified of the successful registration. They can
              now login and start the rating process
            </p>
          </div>

          <div className="acceptBtnRow">
            <button
              type="cancel"
              className="acceptGoBackBtn"
              onClick={handleClose}
            >
              <p>No, Go back</p>
            </button>
            <div className="proAcceptBtn cursorPointer" onClick={onAccept}>
              <Button buttonName={"Yes, Accept"} />
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default AcceptProfilePopUp;
