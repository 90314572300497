import React from 'react';


import "./style.css";

import RightArrow from "../../../../assets/icons/Arrow_Right_LG.svg";
import YearQuarterCard from '../../../emissionComponents/step4/yearQuarterCard/YearQuarterCard';


function YearQuarterTimeline() {
  return (
    <div className='timelineYearChatCon'>
        <div className="timelineYearChat">
            <YearQuarterCard />
            <img src={RightArrow} alt='RightArrow'/>
            <YearQuarterCard />
        </div>
        <div className="timelineYearChat">
            <YearQuarterCard />
            <img src={RightArrow} alt='RightArrow'/>
            <YearQuarterCard />
        </div>
    </div>
  )
}

export default YearQuarterTimeline;