import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import ScopEmissonTable from "../../stepOneView/scopEmissonTable/ScopEmissonTable";
import EmissionComments from "../../../chats/EmissionComments";

function AuditInfoOnEmissionPart1({ findOneData }) {
  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>INFORMATION ON EMISSIONS - Part 1</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditInfoPartOneContainer">
            <div className="auditEmissPartOneSec">
              <h1>
                The table below refers to emissions independent of any GHG
                trades such as sales, purchases, transfers, or banking of
                allowances
              </h1>
              <ScopEmissonTable
                scopeTitle="Scope 1 Emissions"
                mapping="infoOnEmission1.scope1Emissions"
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.scope1Emissions?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
                secName="Scope 1 Emissions"
                question="TOTAL (MTCO2E)"
                // answer={`${
                //   findOneData?.data?.declaration?.infoOnEmission1
                //     ?.scope1Emissions?.co2?.notRecorded
                //     ? `Not Recorded, Reason :  ${findOneData?.data?.declaration?.infoOnEmission1?.scope1Emissions?.co2?.specify}`
                //     : findOneData?.data?.declaration?.infoOnEmission1
                //         ?.scope1Emissions?.co2?.value
                // }`}
                answer={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.scope1Emissions?.total
                }
              />
              <ScopEmissonTable
                scopeTitle="Scope 2 Emissions"
                mapping="infoOnEmission1.scope2Emissions"
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.scope2Emissions?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
                secName="Scope 2 Emissions"
                question="TOTAL (MTCO2E)"
                // answer={`${
                //   findOneData?.data?.declaration?.infoOnEmission1
                //     ?.scope2Emissions?.co2?.notRecorded
                //     ? `Not Recorded, Reason :  ${findOneData?.data?.declaration?.infoOnEmission1?.scope2Emissions?.co2?.specify}`
                //     : findOneData?.data?.declaration?.infoOnEmission1
                //         ?.scope2Emissions?.co2?.value
                // }`}
                answer={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.scope2Emissions?.total
                }
              />
              <ScopEmissonTable
                scopeTitle="Scope 3 Emissions (Applicable only if Scope 3 is included)"
                mapping="infoOnEmission1.scope3Emissions"
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.scope3Emissions?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
                secName="Scope 3 Emissions"
                question="TOTAL (MTCO2E)"
                // answer={`${
                //   findOneData?.data?.declaration?.infoOnEmission1
                //     ?.scope3Emissions?.co2?.notRecorded
                //     ? `Not Recorded, Reason :  ${findOneData?.data?.declaration?.infoOnEmission1?.scope3Emissions?.co2?.specify}`
                //     : findOneData?.data?.declaration?.infoOnEmission1
                //         ?.scope3Emissions?.co2?.value
                // }`}
                answer={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.scope3Emissions?.total
                }
              />
            </div>
            <div className="auditInfoPartDirectRow">
              <h1>Direct CO2 emissions from Biogenic combustion (mtCO2)</h1>
              <p>1234567</p>
            </div>
            <div className="addCmtRow">
              <EmissionComments
                className="addCmtRow"
                mapping="infoOnEmission1.directCo2Emission"
                issueId={
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.directCo2Emission?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
                secName={"Information On Emission - Part1"}
                question={
                  "Direct CO2 emissions from Biogenic combustion (mtCO2)"
                }
                answer={`${
                  findOneData?.data?.declaration?.infoOnEmission1
                    ?.directCo2Emission?.notRecorded
                    ? `Not Recorded, Reason :  ${findOneData?.data?.declaration?.infoOnEmission1?.directCo2Emission?.specify}`
                    : findOneData?.data?.declaration?.infoOnEmission1
                        ?.directCo2Emission?.value
                }`}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditInfoOnEmissionPart1;
