import React from 'react';

import "./style.css";

function UserProgressCard({
    ratingCardIcon,
    ratingCardTitle,
    ratingValue,
    ratingGrowIcon,
    ratingPercentage
}) {
  return (
    <div className="reprtedCardCon">
        <img src={ratingCardIcon} alt='BIRegisteredIcon' className='cardTypeIcon' />
        <div className='userProgressCardCol'>
            <span>{ratingCardTitle}</span>
            <div className='usersValueRow'>
                <h1>{ratingValue}</h1>
                <div className='progressUpDownRow'>
                    <img src={ratingGrowIcon} alt='ProgressUp' className=''/>
                    <h3>{ratingPercentage}</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UserProgressCard