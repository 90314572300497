import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentMedical } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import NewCommentsBtn from "../../../button/newCommentsBtn/NewCommentsBtn";
import EditButton from "../../../button/editButton/EditButton";

function GenInfoView({ formik, data }) {
  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="typographyRow">
            <Typography>
              <div className="GenrlInfo">
                <h3>General Information</h3>
              </div>
            </Typography>
            <div className="editBtnRow">
              <NewCommentsBtn btnTitle={"2 New Issue"} />
              <EditButton />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="haveAnyFacSec">
            <div className="genInfoRowSec">
              <div className="genInfoRow">
                <div className="genInfoRowCol1">
                  <h2>
                    Have any facilities, operations and/or emissions sources
                    been excuded from this inventory? If yes, please specify
                  </h2>
                </div>
                <div className="genInfoRowCol2">
                  <p>
                    {data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.isExcluded
                      ? "Yes"
                      : "No"}
                    ,{" "}
                    {
                      data?.data?.declaration?.generalInformation
                        ?.facilitiesExcluded?.exclusionDetails
                    }
                  </p>
                </div>
              </div>
              <div className="viewCmtRow">
                <ViewCommentsBtn
                  mapping="generalInformation.facilitiesExcluded"
                  issueId={
                    data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.issueId
                  }
                  declarationId={data?.data?.declaration?._id}
                  secName="General Information"
                  question="Have any facilities, operations and/or emissions sources been excluded from this inventory? If yes, please specify"
                  answer={`${
                    data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.isExcluded
                      ? "Yes"
                      : "No"
                  }, ${
                    data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.exclusionDetails
                  }`}
                />
              </div>
            </div>
            <div className="genInfoRowSec">
              <div className="genInfoRow">
                <div className="genInfoRowCol1">
                  <h2>Reporting period covered by this inventory</h2>
                </div>
                <div className="genInfoRowCol2">
                  <p>07/01/2023 to 07/01/2023</p>
                </div>
              </div>
              <div className="viewCmtRow">
                <ViewCommentsBtn
                  secName={"General Information"}
                  question={"Reporting period covered by this inventory"}
                  answer={"07/01/2023  to  07/01/2023"}
                />
              </div>
            </div>
            <div className="genInfoRowSec">
              <div className="genInfoRow">
                <div className="genInfoRowCol1">
                  <h2>MOnitoring & Reporting Frequency</h2>
                </div>
                <div className="genInfoRowCol2">
                  <p>Quarterly</p>
                </div>
              </div>
              <div className="viewCmtRow">
                <ViewCommentsBtn
                  secName={"General Information"}
                  question={"MOnitoring & Reporting Frequency"}
                  answer={"Quarterly"}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GenInfoView;
