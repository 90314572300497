import react from "react";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import Location from "../../../assets/icons/location.svg";
import BellIcon from "../../../assets/icons/bell.svg";
import ProfileIcon from "../../../assets/icons/profileImg.svg";
import MyRatingHeader from "../../../components/ratingComponents/myRatingHeader/MyRatingHeader";
import MyRatingSection from "../../../components/ratingComponents/myRatingSection/MyRatingSection";
import RatingTimeline from "../../../components/ratingComponents/ratingTimeline/RatingTimeline";
import PeopleInvolved from "../../../components/ratingComponents/peopleInvolved/PeopleInvolved";
import { useGetDeclarationInfoQuery } from "../../../features/steps/declerationApiSlice";

function OrganizationRating() {
  const {
    data: declarationInfo,
    error: declarationInfoError,
    isLoading: declarationInfoLoading,
    isSuccess: declarationInfoSuccess,
  } = useGetDeclarationInfoQuery({});

  console.log("declarationInfo", declarationInfo);

  return (
    <div className="orgRatingSec">
      <MyRatingHeader />

      <MyRatingSection rating={declarationInfo?.data?.rating} />

      <div className="orgRatingInnerCon">
        <div className="orgRatingCol1">
          <RatingTimeline data={declarationInfo?.data?.declarationHistory} />
        </div>
        <div className="orgRatingCol2">
          <PeopleInvolved data={declarationInfo?.data?.declarationMembers} />
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default OrganizationRating;
