import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./style.css";
import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';

function AdditionalInfoView({formik}) {
  
  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7a-content"
        id="panel7a-header"
      >
        <Typography>
        <div className='GenrlInfo'>
          <h3></h3>
        </div>
        </Typography>
        <div className='typographyRow'>
          <Typography>
          <div className='GenrlInfo'>
              <h3>ADDITIONAL INFORMATION</h3>
          </div>
          </Typography>
          <div className='editBtnRow'>
              <NewCommentsBtn btnTitle={"2 New Comments"} />
              <EditButton />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='addContracContainer'>
            <div className='addContractRow'>
                <div className='addContractCol1'>
                    <h1>
                        Information on any contractual provisions 
                        addressing GHG-related risks and obligations
                    </h1>
                </div>
                <div className='addContractCol2'>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Metus at 
                        maecenas quis scelerisque pharetra consequat sit. 
                        Sollicitudin amet magnis proin vel turpis quis aliquam 
                        pretium. Dignissim arcu adipiscing neque nulla tristique.
                    </p>
                </div>
            </div>
              <div className='viewCmtRow'>
                <ViewCommentsBtn
                  secName={"Additional Information"} 
                  question={"Information on any contractual provisions addressing GHG-related risks and obligations"} 
                  answer={" Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra consequat sit."} 
                />
              </div>

            <div className='addContractRow'>
                <div className='addContractCol1'>
                    <h1>
                        Information on any GHG sequestration
                    </h1>
                </div>
                <div className='addContractCol2'>
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Metus at 
                        maecenas quis scelerisque pharetra consequat sit. 
                        Sollicitudin amet magnis proin vel turpis quis aliquam 
                        pretium. Dignissim arcu adipiscing neque nulla tristique.
                    </p>
                </div>
            </div>
            <div className='viewCmtRow'>
                <ViewCommentsBtn
                  secName={"Additional Information"} 
                  question={"Information on any GHG sequestration"} 
                  answer={" Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis scelerisque pharetra consequat sit."} 
                />
              </div>
        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default AdditionalInfoView;