import React, { useState } from "react";
import ChatBoxIcon from "../../../../assets/icons/Chat_Circle_Dots.svg";
import ListIcon from "../../../../assets/icons/List_Unordered.svg";
import ChatBoxLayout from "../../../chatBox/chatBoxLayout/ChatBoxLayout";

import "./style.css";
import {
  useAddChatMutation,
  useLazyGetChatsQuery,
  useLazyGetIssueQuery,
} from "../../../../features/steps/declerationApiSlice";
import {
  showError,
  showSuccess,
} from "../../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";

function ViewCommentsBtn({
  secName,
  question,
  answer,
  issueId,
  declarationId,
  mapping,
}) {
  const [commentBox, setCommentBox] = useState(false);
  const [description, setDescription] = useState();

  const commentChatBoxOpen = () => {
    setCommentBox(true);
  };
  const commentChatBoxClose = () => {
    setCommentBox(false);
  };
  const [getIssues, results] = useLazyGetIssueQuery();
  const [getChats, chats] = useLazyGetChatsQuery();
  const dispatch = useDispatch();

  const [
    createChat,
    {
      data: chatData,
      isLoading: chatIsLoading,
      isFetching: chatIsFetching,
      isSuccess: issueIsSuccess,
      error: chatError,
      isError: chatIsError,
    },
  ] = useAddChatMutation();

  const handleAddChat = () => {
    createChat({
      declarationId: declarationId,
      issueId: issueId,
      message: description,
      // fileUri: "https://filelink.pdf",
    })
      .unwrap()
      .then(() => {
        setDescription("");
        dispatch(showSuccess({ message: `Chat Added` }));
      })
      .catch((e) => {
        dispatch(
          showError({
            message: e?.data?.message ?? "Something went wrong",
          })
        );
      });
  };

  return (
    <div className="viewCommentContainer">
      <div className="viewCommentCol1 cursorPointer">
        <img src={ChatBoxIcon} alt="chatBoxIcon" />
        <p
          onClick={() => {
            commentChatBoxOpen();
            getIssues({
              issueId: issueId,
              declarationId: declarationId,
            });
            getChats({
              issueId: issueId,
              declarationId: declarationId,
            });
          }}
        >
          View Comment
        </p>
      </div>
      <div className="viewCommentCol2 cursorPointer">
        <img src={ListIcon} alt="listIcon" />
        <p>View Edits</p>
      </div>
      <ChatBoxLayout
        data={results}
        chats={chats}
        declarationId={declarationId}
        issueId={issueId}
        mapping={mapping}
        isOpen={commentBox}
        onClose={commentChatBoxClose}
        secName={secName}
        question={question}
        answer={answer}
        description={description}
        setDescription={setDescription}
        handleCreateIssue={handleAddChat}
        // handleCreateIssue={handleAddChat}
        chatIsLoading={chatIsLoading}
        chatIsFetching={chatIsFetching}
      />
    </div>
  );
}

export default ViewCommentsBtn;
