import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Switch } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminPagination from "../pagination/AdminPagination";
import { useUserStatusChangeMutation } from "../../../features/admin/adminApiSlice";
import {
    showError,
    showSuccess,
} from "../../../features/snackbar/snackbarAction";
import PageLoader from "../../Loader/PageLoader";

import "./style.css";

import ImpactOnWater from "../../../assets/homeImages/ImpactOnwater.svg";
import UserProfileImg from "../../../assets/images/userImg.png";
import VerticalMenu from "../../../assets/icons/VerticalMenu.svg";
import AppleIcon from "../../../assets/icons/appleIcon.svg";

const ratingList = [
  {
    companyName: "Apple",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "Google Inc",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "Microsoft Inc.",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "Atlassian",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "Airtable  ",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "PayPal",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "Apple",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
  {
    companyName: "Apple",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Advisory Board",
    joinDate: "5/12/2023",
    netZero: "2030",
    gap: "123",
    rating: "EEE+",
    status: "active",
  },
];

const OrgsRatingDetails = ({
  isLoading,
  isFetching,
  error,
  list,
  selectedDates,
  changeRowsPerPage,
  rowsPerPage,
  changePage,
  page,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const [
    changeUserStatus,
    {
      isLoading: userStatusIsLoading,
      isSuccess: userStatusIsSuccess,
      error: userStatusError,
      isError: userStatusIsError,
    },
  ] = useUserStatusChangeMutation();


//   const handleChange = (item, event) => {
//     const { userType, _id } = item?.user || {};
//     const status = event.target.checked ? "active" : "inactive";

//     changeUserStatus({ userType, status, userId: _id })
//       .unwrap()
//       .then(() => {
//         dispatch(showSuccess({ message: `Status updated successfully` }));
//       })
//       .catch((e) => {
//         dispatch(
//           showError({
//             message: e?.data?.message ?? "Something went wrong",
//           })
//         );
//       });
//   };

const handleChange = () => {
    setChecked(!checked);
  };


  const rateDetailFunc = () => {
     navigate("/adminRatingDetails")
  }

  return (
    <div className="userDetailsContainer">
      {(userStatusIsLoading || isLoading || isFetching) && <PageLoader />}
      <table className="orgRatingTable">
        <tr className="userDetailTitle">
          <th className="heading">Company</th>
          <th className="heading">Project Manager</th>
          <th className="heading">Registered On</th>
          <th className="heading">Net Zero Target</th>
          <th className="heading">Gap</th>
          <th className="heading">Rating</th>
          <th className="heading">Action</th>
          <th className="heading"></th>
        </tr>
        {ratingList.map((item, index) => (
          <tr className="adminUserList" key={index}>
            <td>
              <div className="listedUser cursorPointer">
                <div className="">
                    <img src={AppleIcon} alt="ProfileIcon" />
                </div>
                <div className="userGmailData">
                  <h1>{item.companyName}</h1>
                </div>
              </div>
            </td>
            <td className="orgProManager cursorPointer" onClick={rateDetailFunc}>
                <h1>{item?.userName}</h1>
                <h3>{item?.emailId}</h3>
            </td>
            <td>
                <p>{moment(item?.user?.joiningDate).format("DD/MM/YYYY")}</p>
            </td>
            <td>
              <p>{item.netZero}</p>
            </td>
            <td>
              <p>{item.gap}</p>
            </td>
            <td>
                <p>{item.rating}</p>
            </td>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            {/* {
              <td className="">
                {item?.user?.status !== "rejected" && (
                  <Switch
                    checked={item?.user?.status === "active"}
                    onChange={(event) => {
                      handleChange(item, event);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
              </td>
            } */}
          </tr>
        ))}
      </table>
      <AdminPagination
        changeRowsPerPage={changeRowsPerPage}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        page={page}
        totalCount={totalCount}
      />
    </div>
  );
};

export default OrgsRatingDetails;
