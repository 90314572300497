import apiSlice from "../../app/api/apiSlice";

export const quarterlyReportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    quarterlyReportFindOne: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/quaterly-reports/find-one${queryString}`,
        };
      },
      // providesTags: ["findOne"],
    }),
    quarterlyReportGetLatest: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/quaterly-reports/get-latest${queryString}`,
        };
      },
      // providesTags: ["findOne"],
    }),
    quarterlyReportSubmit: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/quaterly-reports/submit",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    quarterlyReportAudit: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/quaterly-reports/audit",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
  }),
});

export const {
  useQuarterlyReportFindOneQuery,
  useQuarterlyReportGetLatestQuery,
  useQuarterlyReportSubmitMutation,
  useQuarterlyReportAuditMutation,
} = quarterlyReportApiSlice;
