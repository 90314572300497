import React, { useEffect, useRef, useState } from 'react';

import AreaChart from '../../charts/AreaChart';

import "./style.css";

function AverageRatingTime() {
   
  return (
    <div className='chartLayoutSection'>
        <div className="chartLayoutTextSec">
            <div className='totalEmissDeclareRow'>
                <div className='totalEmissDeclareCol'>
                    <span>Average Time For Rating</span>
                    <h1>4.67</h1>
                    <p>Days</p>
                </div>
            </div>
        </div>
        <div className='' style={{width:"30vw"}}>
            <AreaChart />
        </div>
    </div>
  )
}

export default AverageRatingTime;