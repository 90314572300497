import react, { useEffect, useRef, useState } from "react";
import { authActions } from "../../../features/auth/authSlice";
import { removeAuthFromLocal } from "../../../utils/storage";
import { useDispatch } from "react-redux";

import RatingSelectProfile from "../../ratingComponents/ratingSelectProfile/RatingSelectProfile";

import "./style.css";

import NotificationIconn from "../../../assets/icons/notificationIcon.svg";
import UserProfileImg from "../../../assets/images/userImg.png";
import ProfileIcon from "../../../assets/icons/ProfileUser.svg";
import LogOutIcon from "../../../assets/icons/logOutIcon.svg";
import LogOutPopUp from "../profileApprovePopUp/logOutPopUp/LogOutPopUp";

const userName = [
  { name: "Mohamed Irfan", value: "" },
  { name: "Irfan", value: 1 },
  { name: "Sunita", value: 2 },
  { name: "Suni", value: 3 },
  { name: "Tani", value: 4 },
];

function AdminDashHeader() {
  const [logOutPopUp, setLogOutPopUp] = useState(false);
  const [logOut, setLogOut] = useState(false);
  const [profileName, setProfileName] = useState();
  const menuRef = useRef(null);
  const dispatch = useDispatch();


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setLogOutPopUp(false);
      }
  };

  const handleChange = (event) => {
    setProfileName(event.target.value);
  };

  const toggleLogOut = () => {
    setLogOutPopUp(!logOutPopUp);
  };

  const logOutOpen = () => {
    setLogOut(true);
  };

  const logOutClose = () => {
    setLogOut(false);
  };


  return (
    <header className="adminHead">
      <div className="adminTopBar">
        <div>
          <img src={NotificationIconn} alt="bellIcon" />
          {/* <span className="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger"><span className="notification-badge">4</span><span className="visually-hidden">unread messages</span></span> */}
        </div>
        <div className="rightBorder"></div>
        <div className="profileImg cursorPointer">
          <div onClick={toggleLogOut}>
            <img src={UserProfileImg} alt="UserProfileImg" />
          </div>
            <RatingSelectProfile 
              data={userName}
              value={profileName}
              onChange={handleChange}
              defaultValue={userName[0].value} 
            />
        </div>
      </div>
      {logOutPopUp && (
        <div className="adminLogOutContainer" ref={menuRef}>
          <div
            className="logoutInner cursorPointer"
            onClick={() => {
              removeAuthFromLocal();
              dispatch(authActions.logout());
            }}
          >
            <div className="logoutTextRow">
              <img src={ProfileIcon} alt="ProfileIcon" />
              <p className="cursor-pointer"> My Profile </p>
            </div>
            <div className="logoutTextRow" onClick={logOutOpen}>
              <img src={LogOutIcon} alt="LogOutIcon" />
              <p className="cursor-pointer"> Logout </p>
            </div>
          </div>
        </div>
      )}
      {/* Accept Profile */}
      <LogOutPopUp
        open={logOut}
        onClose={logOutClose}
      />
    </header>
  );
}

export default AdminDashHeader;
