import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import NewCommentsBtn from "../../../button/newCommentsBtn/NewCommentsBtn";
import EditButton from "../../../button/editButton/EditButton";

import "./style.css";
import Initiative1Preview from "../initiative1Preview/Initiative1Preview";
// import AuditAddComments from "../../step2Components/auditAddComments/AuditAddComments";
import AddComments from "../../../chats/AddComments/AddComments";
import Comments from "../../../chats/Comments";

function PriorityPillarPreview({ value, headName, priorityPillarIndex, data }) {
  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="typographyRow">
            <Typography>
              <div className="GenrlInfo">
                <h3>{headName}</h3>
              </div>
            </Typography>
            <div className="editBtnRow">
              <NewCommentsBtn btnTitle={"2 New Issue"} />
              <EditButton />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="priorityPreCon">
            <div className="pillarActionPreCon">
              <div className="priorityPreRow">
                <div className="priorityPreCol1">
                  <h2>Priority Pillar for Action</h2>
                </div>
                <div className="priorityPreCol2">
                  <p>{value?.objective}</p>
                </div>
              </div>
              {/* {value?.issueId ? (
                <ViewCommentsBtn
                  mapping="priorityPillars"
                  issueId={value?.issueId}
                  declarationId={data?.data?.declaration?._id}
                  secName={`Priority Pillar ${priorityPillarIndex + 1}`}
                  question="Priority Pillar for Action"
                  answer={value?.objective}
                />
              ) : (
                <div className="viewCmtRow">
                  <AddComments
                    mapping="priorityPillars"
                    issueId={value?.issueId}
                    declarationId={data?.data?.declaration?._id}
                    secName={`Priority Pillar ${priorityPillarIndex + 1}`}
                    question="Priority Pillar for Action"
                    answer={value?.objective}
                  />
                </div>
              )} */}
            </div>
            <div className="pillarActionPreCon">
              <div className="priorityPreRow">
                <div className="priorityPreCol1">
                  <h2>Description</h2>
                </div>
                <div className="priorityPreCol2">
                  <p>{value?.description}</p>
                </div>
              </div>
              {
                <Comments
                  className="addCmtRow"
                  mapping="priorityPillars"
                  issueId={value?.issueId}
                  priorityPillarId={value?._id}
                  declarationId={data?.data?.declaration?._id}
                  secName={`Priority Pillar ${priorityPillarIndex + 1}`}
                  question="Priority Pillar for Action"
                  answer={value?.objective}
                />
              }
            </div>
            {value?.initiatives?.map((item, initiativeIndex) => (
              <Initiative1Preview
                key={initiativeIndex}
                initiativeIndex={initiativeIndex}
                headName={`Initiative ${priorityPillarIndex + 1}.${
                  initiativeIndex + 1
                }`}
                value={item}
                priorityPillarId={value?._id}
                declarationId={data?.data?.declaration?._id}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PriorityPillarPreview;
