import React from 'react';

import "./style.css";

function EditButton() {
  return (
    <div className='previewEditBtn cursorPointer'>
      <p>Edited</p>
    </div>
  )
}

export default EditButton;