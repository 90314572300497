import React, { useEffect, useRef, useState } from 'react';

import TimlineChart from '../../charts/StackedBarChart';

import "./style.css";
import RatingSelectProfile from '../../ratingComponents/ratingSelectProfile/RatingSelectProfile';
import StackedBarChart from '../../charts/StackedBarChart';

const allSectorData = [
  { name: "All Sectors", value: "" },
  { name: "Materials", value: 1 },
  { name: "Industrials", value: 2 },
  { name: "Consumer Discretionary", value: 3 },
  { name: "Consumer Staples", value: 4 },
];


function PerSector() {
  const [sectorName, setSectorName] = useState();

    const handleChange = (event) => {
        setSectorName(event.target.value);
    };
   
  return (
    <div className='chartLayoutSection'>
      <div className="chartLayoutTextSec">
          <div className='totalEmissDeclareRow'>
              <div className='totalEmissDeclareCol'>
                  <span>B&I  Per Sector</span>
                  <h1>11,345K</h1>
              </div>
              <RatingSelectProfile
                  data={allSectorData}
                  value={sectorName}
                  onChange={handleChange}
                  defaultValue={allSectorData[0].value} 
              />
          </div>
      </div>
      <div className='timelineChartSec' style={{width:"100%"}}>
          {/* <TimlineChart /> */}
          <StackedBarChart />
      </div>
    </div>
  )
}

export default PerSector;