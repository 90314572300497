import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import EmissionComments from "../../../chats/EmissionComments";

function AuditOperaBoundaries({ findOneData }) {
  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>Operational Boundaries</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditOperaInvenContainer">
            <div className="auditOperatScopeRow">
              <div className="auditOperatScopeCol1">
                <h1>Are Scope 3 emissions included in this inventory?</h1>
              </div>
              <div className="auditOperatScopeCol2">
                <p>Yes</p>
              </div>
            </div>
            <div className="auditOperatScopeRow">
              <div className="auditOperatScopeCol1">
                <h1>
                  If yes, which types of activities are included in Scope 3
                  emissions?
                </h1>
              </div>
              <div className="auditOperatScopeCol2">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Metus at maecenas quis
                  scelerisque pharetra consequat sit. Sollicitudin amet magnis
                  proin vel turpis quis aliquam pretium. Dignissim arcu
                  adipiscing neque nulla tristique.
                </p>
              </div>
            </div>
            <div className="addCmtRow">
              <EmissionComments
                className="addCmtRow"
                mapping="operationalBoundaries"
                issueId={
                  findOneData?.data?.declaration?.operationalBoundaries?.issueId
                }
                declarationId={findOneData?.data?.declaration?._id}
                secName={"Operational Boundaries"}
                question={
                  "If yes, which types of activities are included in Scope 3 emissions?"
                }
                answer={`${
                  findOneData?.data?.declaration?.operationalBoundaries
                    ?.includeScope3
                    ? "Yes"
                    : "No"
                }, ${
                  findOneData?.data?.declaration?.operationalBoundaries
                    ?.scope3ActivityTypes
                }`}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditOperaBoundaries;
