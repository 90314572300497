import React, { useState} from "react";
import moment from "moment";
import { useUserStatusChangeMutation } from "../../features/admin/adminApiSlice";

import "./style.css";

import ViewFeedback from "../drawer/registrationLog/ViewFeedback";

const feedbackList = [
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio.",
    subject: "Emissions Declaration",
    sendDate: "5/12/2023",
    answeredOn : "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio.",
    subject: "Monitoring & Reporting",
    sendDate: "5/12/2023",
    answeredOn : "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Changing Consultant",
    sendDate: "5/12/2023",
    answeredOn : "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "General",
    sendDate: "5/12/2023",
    answeredOn : "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio.",
    subject: "General",
    sendDate: "5/12/2023",
    answeredOn : "5/12/2023",
    action: "View Feedback",
  },
];

const PreviousFeedback = () => {
  const [checked, setChecked] = useState(true);
  const [viewFeedback, setViewFeedback] = useState(false);

  const feedbackOpen = () => {
    setViewFeedback(true);
  };
  const feedbackClose = () => {
    setViewFeedback(false);
  };

  const [
    changeUserStatus,
    {
      isLoading: userStatusIsLoading,
      isSuccess: userStatusIsSuccess,
      error: userStatusError,
      isError: userStatusIsError,
    },
  ] = useUserStatusChangeMutation();

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="userDetailsContainer feedbacktable">
      <table className="">
        <tr className="">
          <th className="heading">Feedback</th>
          <th className="heading">Subject</th>
          <th className="heading">Sent On</th>
          <th className="heading">Answered On</th>
          <th className="heading">Actions</th>
        </tr>
        {feedbackList.map((item, index) => (
          <tr className="adminUserList" key={index}>
            <td style={{width: "500px"}}>
              <div className="listedUser cursorPointer">
                <div className="admFeedbackTitle">
                    <p style={{width:"100%"}}>{item.feedback}</p>
                </div>
              </div>
            </td>
            <td className="">
               <p>{item.subject}</p>
            </td>
            <td>
                <p>{moment(item?.user?.joiningDate).format("DD/MM/YYYY")}</p>
            </td>
            <td>
                <p>{moment(item?.user?.joiningDate).format("DD/MM/YYYY")}</p>
            </td>
            <td className="feedbackAction cursorPointer" onClick={feedbackOpen}>
                <p>{item.action}</p>
            </td>
          </tr>
        ))}
      </table>

      <ViewFeedback 
       isOpen={viewFeedback} 
       onClose={feedbackClose} 
      />

    </div>
  );
};

export default PreviousFeedback;
