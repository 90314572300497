import react, { useEffect, useReducer, useState } from "react";
import { useFormik } from "formik";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import AdminOrgTitle from "../../../components/adminComponents/adminOrgTitle/AdminOrgTitle";
import AdminBasicDetails from "../../../components/adminComponents/addAdminData/AdminBasicDetails";

import "./style.css";
import AdminWorkInfo from "../../../components/adminComponents/addAdminData/AdminWorkInfo";
import AdminLogCreds from "../../../components/adminComponents/addAdminData/AdminLogCreds";
import FormButton from "../../../components/button/FormButton";

function AddAdmin() {

    const formik = useFormik({
        initialValues: {
        },
        enableReinitialize: true,
    
        onSubmit: (values) => {
        },
      });
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <AdminOrgTitle />
          <AdminBasicDetails  formik={formik}/>
          <AdminWorkInfo formik={formik} />
          <AdminLogCreds formik={formik} />
          <div className="form-row saveBtnRow">
            <div className=" cursorPointer">
              <FormButton
                buttonName={"Cancel"}
                buttonText={"#1D1D1B"}
                backgroundColor={"transparent"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className=" NextBtn cursorPointer">
              <FormButton
                buttonName={"Save & Add"}
                backgroundColor={"#008BB2"}
                buttonText={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AddAdmin;
