// import React from "react";

// import AuditorTextWithPdf from "../commonComponents/auditorTextWithPdf/AuditorTextWithPdf";
// import AuditorChatTextOnly from "../commonComponents/auditorChatTextOnly/AuditorChatTextOnly";
// import AuditorInfoPart1Scopes from "../infoOnEmssPart1Components/auditorInfoPart1Scopes/AuditorInfoPart1Scopes";
// import TitleWithValue from "../commonComponents/titleWithValue/TitleWithValue";

// import "./style.css";
// import AuditorInfoPart2Scopes from "../infoOnEmissPart2Components/auditorInfoPart2Scopes/AuditorInfoPart2Scopes";
// import RecieverChatBox from "../recieverChatBox/RecieverChatBox";
// import { getUserFromLocal } from "../../../../utils/storage";

// function MessageEditChatBox({
//   item,
//   index,
//   text,
//   pdf,
//   pdfData,
//   reciever = false,
//   infoPart1,
//   scope1,
//   equity,
//   infoPart2,
// }) {
//   const userDetails = getUserFromLocal();
//   return (
//     <div className="auditChatBox">
//       <div className="auditChatBoxCon">
//         <p className="auditChatTitle">
//           An edit made on the response to this question
//         </p>
//         {/* {pdfData ? (
//           <AuditorTextWithPdf pdfData={pdfData} />
//         ) : text ? (
//           <AuditorChatTextOnly text={text} />
//         ) : infoPart1 ? (
//           <AuditorInfoPart1Scopes />
//         ) : equity ? (
//           <TitleWithValue />
//         ) : infoPart2 ? (
//           <AuditorInfoPart2Scopes />
//         ) : (
//           ""
//         )} */}

//         {item.mapping === "generalInformation.facilitiesExcluded" ? (
//           <RecieverChatBox
//             key={index}
//             reciever={
//               userDetails?.userType === "auditor" &&
//               item?.senderUserType === "auditor"
//                 ? false
//                 : userDetails?.userType === "consultant" &&
//                     item?.senderUserType === "consultant"
//                   ? false
//                   : true
//             }
//             summaryText={""}
//             text={
//               (item?.updateDeclaration?.isExcluded ? "Yes" : "No") +
//               ", " +
//               item?.updateDeclaration?.exclusionDetails
//             }
//             isSummary={false}
//           />
//         ) : null}
//       </div>
//     </div>
//   );
// }

// export default MessageEditChatBox;
import React, { useState } from "react";

import AuditorTextWithPdf from "../commonComponents/auditorTextWithPdf/AuditorTextWithPdf";
import AuditorChatTextOnly from "../commonComponents/auditorChatTextOnly/AuditorChatTextOnly";
import AuditorInfoPart1Scopes from "../infoOnEmssPart1Components/auditorInfoPart1Scopes/AuditorInfoPart1Scopes";
import TitleWithValue from "../commonComponents/titleWithValue/TitleWithValue";

import "./style.css";
import AuditorInfoPart2Scopes from "../infoOnEmissPart2Components/auditorInfoPart2Scopes/AuditorInfoPart2Scopes";
import RecieverChatBox from "../recieverChatBox/RecieverChatBox";
import { getUserFromLocal } from "../../../../utils/storage";
import moment from "moment";

function MessageEditChatBox({
  item,
  index,
  text,
  pdf,
  pdfData,
  reciever = false,
  infoPart1,
  scope1,
  equity,
  infoPart2,
}) {
  const userDetails = getUserFromLocal();
  console.log(
    "check",
    (userDetails?.userType === "auditor" &&
      item?.senderUserType === "auditor") ||
      (userDetails?.userType === "consultant" &&
        item?.senderUserType === "consultant")
      ? false
      : true
  );

  const [editMessage, setEditMessage] = useState(true);

  let chatBoxContent = null;
  if (item.mapping === "generalInformation.facilitiesExcluded") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={false}
        summaryText={""}
        text={
          (item?.updateDeclaration?.isExcluded ? "Yes" : "No") +
          ", " +
          item?.updateDeclaration?.exclusionDetails
        }
        isSummary={false}
        item={item}
      />
    );
  } else if (item.mapping === "generalInformation.reportingPeriod") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={`${moment(item?.updateDeclaration?.from).format(
          "MM/DD/YYYY"
        )} to ${moment(item?.updateDeclaration?.to).format("MM/DD/YYYY")}`}
        isSummary={false}
        item={item}
      />
    );
  } else if (
    item.mapping === "organizationalBoundaries.consolidationApproach"
  ) {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={[
          item?.updateDeclaration?.equityShare && "Equity Share",
          item?.updateDeclaration?.financialControl && "Financial Control",
          item?.updateDeclaration?.operationalControl && "Operational Control",
        ]
          .filter(Boolean)
          .join(", ")}
        isSummary={false}
        item={item}
      />
    );
  } else if (item.mapping === "organizationalBoundaries.entities") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={[
          item?.updateDeclaration?.legalEntityOrFacility,
          item?.updateDeclaration?.percentageEquityShare,
          item?.updateDeclaration?.isCompanyHasFinancialControl ? "Yes" : "No",
          item?.updateDeclaration?.isCompanyHasOperationalControl
            ? "Yes"
            : "No",
        ].join(", ")}
        isSummary={false}
        item={item}
      />
    );
  } else if (item.mapping === "organizationalBoundaries.orgDiagramFile") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={item?.updateDeclaration?.fileName}
        pdfData={item?.updateDeclaration?.fileName}
        isSummary={false}
        item={item}
      />
    );
  }

  return (
    <div className={reciever ? "auditChatBox" : "auditRightChatBox"}>
      <div className="auditChatBoxCon">
        <p className="auditChatTitle">
          An edit made on the response to this question
        </p>
        <p style={{ overflowWrap: "anywhere", margin: "0px" }}>
          {chatBoxContent}
        </p>
      </div>
    </div>
  );
}

export default MessageEditChatBox;
