import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import UserProgressCard from "../../../components/adminComponents/progressDashboardComponents/UserProgressCard";
import TotalEmissionDeclareGraph from "../../../components/adminComponents/progressDashboardComponents/TotalEmissionDeclareGraph";
import BestRatedBI from "../../../components/adminComponents/progressDashboardComponents/BestRatedBI";
import DashboardRating from "../../../components/adminComponents/progressDashboardComponents/DashboardRating";
import ReportedProgressCard from "../../../components/adminComponents/progressDashboardComponents/ReportedProgressCard";

import "./style.css";

import ProgressUp from "../../../assets/icons/progressUpGreen.svg";
import ProgressDown from "../../../assets/icons/progressDownGreen.svg";
import BIRegisteredIcon from "../../../assets/icons/BIRegistered.svg";
import RatingQuarter from "../../../assets/icons/ratingQuarter.svg";
import UserProgress from "../../../assets/icons/userProgress.svg";
import Feedback from "../../../assets/icons/feedbackProgress.svg";
import AuditReported from "../../../assets/icons/auditReportedIssue.svg";
import BoardMember from "../../../assets/icons/advBoardMember.svg";
import EditPerQuarter from "../../../assets/icons/editPerQuarter.svg";
import CountryIcon from "../../../assets/icons/countryIcon.svg";
import AverageRatingTime from "../../../components/adminComponents/progressDashboardComponents/AverageRatingTime";
import PerSector from "../../../components/adminComponents/progressDashboardComponents/PerSector";
import OrganizationCurrentRating from "../../../components/dashboardComponents/OrganizationCurrentRating";
import OrganizationEmissionsDeclared from "../../../components/dashboardComponents/OrganizationEmissionsDeclared";


function AdminProgressDashboard() {
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu  progressDashboardMenu={"progressDashboardMenu"} />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
            <div className="userProgressRow" style={{width:"100%"}}>
                <UserProgressCard
                    ratingCardIcon={BIRegisteredIcon}
                    ratingCardTitle={"B&I Registered"}
                    ratingValue={"11K"}
                    ratingGrowIcon={ProgressUp}
                    ratingPercentage={"2.4%"}
                 />
                <UserProgressCard
                    ratingCardIcon={RatingQuarter}
                    ratingCardTitle={"Ratings This Quarter"}
                    ratingValue={"9.4K"}
                    ratingGrowIcon={ProgressUp}
                    ratingPercentage={"00%"}
                 />
                <UserProgressCard
                    ratingCardIcon={UserProgress}
                    ratingCardTitle={"Users"}
                    ratingValue={"44K"}
                    ratingGrowIcon={ProgressDown}
                    ratingPercentage={"1.1%"}
                 />
                <UserProgressCard
                    ratingCardIcon={Feedback}
                    ratingCardTitle={"Feedbacks"}
                    ratingValue={"145"}
                    ratingGrowIcon={ProgressUp}
                    ratingPercentage={"7.1%"}
                 />
            </div>
            <div className="userProgressRow">
                <div className="userProgressCol1">
                    <TotalEmissionDeclareGraph />
                </div>
                <div className="userProgressCol2">
                    <BestRatedBI />
                </div>
            </div>
            <div className="userProgressRow">
                <div className="dashboardRatingCol1">
                    <DashboardRating />
                </div>
                <div className="dashboardRatingCol2">
                    <div className="repotedCardSec">
                        <ReportedProgressCard
                            reportCardIcon={AuditReported}
                            reportCardTitle={"Auditor Reported Issues"}
                            reportValue={"1.3K"}
                            reportGrowIcon={ProgressDown}
                            reportPercentage={"2.4%"}
                        />
                        <ReportedProgressCard
                            reportCardIcon={BoardMember}
                            reportCardTitle={"Advisory Board Members"}
                            reportValue={"345K"}
                            reportGrowIcon={ProgressUp}
                            reportPercentage={"2.4%"}
                        />
                        <ReportedProgressCard
                            reportCardIcon={EditPerQuarter}
                            reportCardTitle={"Edits Per Quarter"}
                            reportValue={"3.4K"}
                            reportGrowIcon={ProgressUp}
                            reportPercentage={"2.4%"}
                        />
                        <ReportedProgressCard
                            reportCardIcon={CountryIcon}
                            reportCardTitle={"Countries / Territories"}
                            reportValue={"155K"}
                            reportGrowIcon={ProgressUp}
                            reportPercentage={"2.4%"}
                        />
                    </div>
                </div>
            </div>
            <div className="userProgressRow">
                <div className="avgRatingTimeCol1">
                    <AverageRatingTime />
                </div>
                <div className="avgRatingTimeCol2">
                    <PerSector />
                </div>
            </div>


            <div style={{display:'flex'}}>
                <OrganizationCurrentRating />
                <OrganizationEmissionsDeclared />
            </div>
        </div>
      </div>
    </div>
  );
}

export default AdminProgressDashboard;
