import React from 'react';
import Chart from "react-apexcharts"; 

function DonutChart() {
    const series = [8, 25, 40, 10, 34, 20 ]

    const options = { 
        labels : ['EEE+', 'EEE', 'EEE-', 'EE+', 'EE', 'EE-'],
        // title:{
        //     text : "Donut Title"
        // },
        fill: {
            type: 'gradient',
        },
        colors: ['#0300B2', '#A20074', '#008BB2', '#2BA84A', '#F45B69', '#F3A738'],

        plotOptions:{
            pie:{
                startAngle: -90,
                endAngle: 270,
                donut:{
                    labels:{
                        show:true,
                        total:{
                            // show:true,
                        }
                    }
                }
            }
        },
        dataLabels: {
          enabled: false
      },
    } 
    
    const responsive = [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]


  return (
    <Chart
        type='donut'
        // width={500}
        height={400}
        series={series}
        options={options}
        responsive={responsive}
        style={{minWidth:"200px", width:"30vw"}}
    />
  )
}

export default DonutChart