import React from 'react';

import SelectInputBox, { SelectCountryInputBox, SelectStateInputBox } from '../../InputBox/selectBox/SelectInputBox';
import InputBox from '../../InputBox/InputBox';

import PhoneInputBox from '../../InputBox/phoneInputBox/PhoneInputBox';

import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import ImgUploadIcon from "../../../assets/images/uploadImg.svg";
import ImgUploadBtn from '../../button/imgUploadBtn/ImgUploadBtn';


const countryStateMasterData = {
    India: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
    UAE: [
      "Abu Dhabi",
      "Ajman",
      "Dubai",
      "Fujairah",
      "Ras Al Khaimah",
      "Sharjah",
      "Umm Al Quwain",
    ],
    "Saudi Arabia": [
      "'Asir",
      "Al Bahah",
      "Al Jawf",
      "Al Madinah",
      "Al Qasim",
      "Eastern Province",
      "Ha'il",
      "Jazan",
      "Mecca",
      "Najran",
      "Northern Borders",
      "Riyadh",
      "Tabuk",
    ],
  };

const titleList = [
    { value: "Mr", name: "Mr." },
    { value: "Mrs", name: "Mrs." },
    { value: "Ms", name: "Ms." },
  ];

function AdminWorkInfo({formik}) {
    
    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      };

  return (
    <div className='adminBasicCon'>    
        <h1>Work Information</h1>
        <div className='adminBasicInfoSec'>
        <form
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={onKeyDown}
            className='adminBsForm'
          >
            <div className="adminBsInputRow">
              <div className="adminBsInpCols">
                <InputBox
                  headName={"Name of Organization"}
                  placeholder="SEE Rating"
                  name="orgName"
                  value={formik.values.orgName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.orgName && formik.errors.orgName}
                  required
                />
              </div>
              <div className="adminBsInpCols">
                <InputBox
                  headName={"Designation"}
                  placeholder="Type here..."
                  name="designation"
                  value={formik.values.designation}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.designation && formik.errors.designation}
                  required
                />
              </div>
              <div className="adminBsInpCols">
                <InputBox
                  headName={"Employee ID"}
                  placeholder="Type here..."
                  name="empId"
                  value={formik.values.empId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.empId && formik.errors.empId}
                  required
                />
              </div>
            </div>

            <div className='adminHorizontalLine'/>

            <div className="adminBsInputRow">
              <div className="adminBsAddress">
                <InputBox
                  headName={"Office Address"}
                  placeholder="Enter your address here"
                  name="address.fullAddress"
                  value={formik.values?.address?.fullAddress}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.fullAddress &&
                    formik.errors.address?.fullAddress
                  }
                  required
                />
              </div>
            </div>

             <div className="adminBsInputRow">
             <div className="adminBsInpCols">
                <InputBox
                  headName={"City / Town / Suburb"}
                  placeholder="Enter City / Town / Suburb"
                  name="address.city"
                  value={formik.values?.address?.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.city &&
                    formik.errors.address?.city
                  }
                  required
                />
              </div>
              <div className="adminBsInpCols">
                <SelectCountryInputBox
                  headName={"Country"}
                  selectPlaceholder={"Country"}
                  data={countryStateMasterData}
                  name="address.country"
                  value={formik?.values?.address?.country}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik?.handleChange(e);
                    formik.setFieldValue("address.state", "");
                    formik.setFieldValue("address.zipCode", "");
                  }}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.country &&
                    formik.errors.address?.country
                  }
                  required
                />
              </div>
              <div className="adminBsInpCols">
                <SelectStateInputBox
                  headName={"State / Region"}
                  selectPlaceholder={"Select"}
                  data={countryStateMasterData}
                  selectedData={formik?.values?.address?.country}
                  name="address.state"
                  value={formik?.values?.address?.state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.state &&
                    formik.errors.address?.state
                  }
                  required
                />
              </div>
              
            </div>
            <div className="adminBsInputRow">
             <div className="adminBsInpCols">
                <InputBox
                  headName={"Zipcode / Postal Code"}
                  placeholder=" Enter Zipcode / postal code"
                  name="address.zipCode"
                  value={formik?.values?.address?.zipCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.zipCode &&
                    formik.errors.address?.zipCode
                  }
                  required
                />
              </div>
            </div>
            <div>
            </div>
          </form>
        </div>
    </div>
  )
}

export default AdminWorkInfo;