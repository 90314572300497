import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import moment from "moment";
import EmissionComments from "../../../chats/EmissionComments";

function AuditGenInfo({ data }) {
  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>General Information</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditHaveAnyFacSec">
            <div className="auditGenInfoRowSec">
              <div className="auditGenInfoRow">
                <div className="auditGenInfoRowCol1">
                  <h2>
                    Have any facilities, operations and/or emissions sources
                    been excuded from this inventory? If yes, please specify
                  </h2>
                </div>
                <div className="auditGenInfoRowCol2">
                  <p>
                    {data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.isExcluded
                      ? "Yes"
                      : "No"}
                    ,{" "}
                    {
                      data?.data?.declaration?.generalInformation
                        ?.facilitiesExcluded?.exclusionDetails
                    }
                  </p>
                </div>
              </div>
              <div className="addCmtRow">
                <EmissionComments
                  className="addCmtRow"
                  mapping="generalInformation.facilitiesExcluded"
                  issueId={
                    data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.issueId
                  }
                  declarationId={data?.data?.declaration?._id}
                  secName="General Information"
                  question="Have any facilities, operations and/or emissions sources been excluded from this inventory? If yes, please specify"
                  answer={`${
                    data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.isExcluded
                      ? "Yes"
                      : "No"
                  }, ${
                    data?.data?.declaration?.generalInformation
                      ?.facilitiesExcluded?.exclusionDetails
                  }`}
                />
              </div>
            </div>
            <div className="auditGenInfoRowSec">
              <div className="auditGenInfoRow">
                <div className="auditGenInfoRowCol1">
                  <h2>Reporting period covered by this inventory</h2>
                </div>
                <div className="auditGenInfoRowCol2">
                  <p>
                    {moment(
                      data?.data?.declaration?.generalInformation
                        ?.reportingPeriod?.from
                    ).format("MM/DD/YYYY")}{" "}
                    to{" "}
                    {moment(
                      data?.data?.declaration?.generalInformation
                        ?.reportingPeriod?.to
                    ).format("MM/DD/YYYY")}
                  </p>
                </div>
              </div>
              <div className="addCmtRow">
                <EmissionComments
                  className="addCmtRow"
                  mapping="generalInformation.reportingPeriod"
                  issueId={
                    data?.data?.declaration?.generalInformation?.reportingPeriod
                      ?.issueId
                  }
                  declarationId={data?.data?.declaration?._id}
                  secName="General Information"
                  question={"Reporting period covered by this inventory"}
                  answer={`${moment(
                    data?.data?.declaration?.generalInformation?.reportingPeriod
                      ?.from
                  ).format("MM/DD/YYYY")} to ${moment(
                    data?.data?.declaration?.generalInformation?.reportingPeriod
                      ?.to
                  ).format("MM/DD/YYYY")}`}
                  data={data}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditGenInfo;
