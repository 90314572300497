import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormButton from "../../../components/button/FormButton";
import EmisTitleSection from "../../../components/emissionComponents/emisTitleSection/EmisTitleSection";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";

import ApproveDecButton from "../../../components/button/approveButton/ApproveDecButton";
import AuditGenInfo from "../../../components/emissionComponents/step2Components/auditGenInfo/AuditGenInfo";
import AuditOrgBoundaries from "../../../components/emissionComponents/step2Components/auditOrgBoundaries/AuditOrgBoundaries";
import AuditOperaBoundaries from "../../../components/emissionComponents/step2Components/auditOperaBoundaries/AuditOperaBoundaries";
import AuditInfoOnEmissionPart1 from "../../../components/emissionComponents/step2Components/auditInfoOnEmissionPart1/AuditInfoOnEmissionPart1";
import AuditMethoFactors from "../../../components/emissionComponents/step2Components/auditMethoFactors/AuditMethoFactors";
import AuditInfoOnEmissionPart2 from "../../../components/emissionComponents/step2Components/auditInfoOnEmissionPart2/AuditInfoOnEmissionPart2";
import AuditAdditionalInfo from "../../../components/emissionComponents/step2Components/auditAdditionalInfo/AuditAdditionalInfo";
import AuditInfoOffset from "../../../components/emissionComponents/step2Components/auditInfoOffset/AuditInfoOffset";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import { getUserFromLocal } from "../../../utils/storage";
import { useNavigate } from "react-router-dom";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import {
  useEmissionApproveMutation,
  useEmissionRejectMutation,
  useEmissionRevertMutation,
} from "../../../features/steps/emissionApiSlice";
import PageLoader from "../../../components/Loader/PageLoader";

function EmissionStep2() {
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");

  const handleEditDecleration = () => {
    navigate("/emissionsDeclaration");
  };
  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({}, { refetchOnMountOrArgChange: true });

  const declerationId = findOneData?.data?.declaration?._id;

  const [
    emissionApprove,
    {
      isLoading: emissionApproveIsLoading,
      isSuccess: emissionApproveIsSuccess,
      error: emissionApproveIsError,
    },
  ] = useEmissionApproveMutation();

  const [
    emissionRevert,
    {
      isLoading: emissionRevertIsLoading,
      isSuccess: emissionRevertIsSuccess,
      error: emissionRevertIsError,
    },
  ] = useEmissionRevertMutation();

  const [
    emissionReject,
    {
      isLoading: emissionRejectIsLoading,
      isSuccess: emissionRejectIsSuccess,
      error: emissionRejectIsError,
    },
  ] = useEmissionRejectMutation();

  const handleEmissionSubmission = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "Approve Declaration") {
      emissionApprove({
        declarationId: declerationId,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Approved Successfully` }));
          console.log(res, "res");
          navigate("/dashboard");
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else if (event.target.value === "Revert with Comments") {
      emissionRevert({
        declarationId: declerationId,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Reverted Successfully` }));
          console.log(res, "res");
          navigate("/dashboard");
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else if (event.target.value === "Reject Roadmap") {
      emissionReject({
        declarationId: declerationId,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Rejected Successfully` }));
          console.log(res, "res");
          navigate("/dashboard");
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    }
  };

  const handleCancelAndExit = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container emisStep2Container">
      {(findOneIsLoading || findOneIsFetching) && <PageLoader />}
      <div className="emission2TitleDiv">
        <div className="">
          <img src={EmissionDecLogo} alt="" />
        </div>
        {userDetails?.userType === "auditor" && (
          <div className="emis2ButtonDiv">
            <div className="emis2CancelBtn" onClick={handleCancelAndExit}>
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="emis2SubmitBtn">
              <ApproveDecButton
                declerationId={findOneData?.data?.declaration?._id}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handleChange={handleEmissionSubmission}
              />
            </div>
          </div>
        )}

        {userDetails?.userType === "consultant" && (
          <div className="emis2ButtonDiv">
            <div className="emisCancelBtn" onClick={handleCancelAndExit}>
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="emisSaveBtn">
              <div
                className="form_SubmitBtn"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ffffff",
                  color: "#ffffff",
                  fontSize: "1rem",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
                onClick={handleEditDecleration}
              >
                Edit Declaration
              </div>
            </div>
            <div className="emisSubmitBtn">
              <FormButton
                buttonName={"Resubmit Decleration"}
                backgroundColor={"#00637E"}
                buttonText={"#ffffff"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
              />
            </div>
          </div>
        )}
      </div>

      <div className="emis2InnerContainer">
        <div className="emission2SecCol1">
          <div className="emis2NowNextStep">
            <div className="emis2leftTitle">
              <h1>Emission Declaration</h1>
              <div className="nextStep">
                <p>Next Step:</p>
                <h3>Assure/Audit Emissions</h3>
              </div>
            </div>
            <EmisTitleSection />
          </div>
        </div>

        <div className="emission2SecCol2">
          <div className="Emiss2CodewaveTitleDiv">
            <div className="emis2CodeCol1">
              <div className="emiss2InventoryYear">
                <div className="emis2CodwaveLogo">
                  <img src={CodeLogo} />
                  <h3>Codewave’s Greenhouse Gas Emissions Inventory</h3>
                </div>
                <div>
                  <p>Inventory Year: 2021</p>
                </div>
              </div>
            </div>
          </div>

          <div class="emiss2InnerMainBlock">
            <AuditGenInfo data={findOneData} />
            <AuditOrgBoundaries data={findOneData} />
            <AuditOperaBoundaries findOneData={findOneData} />
            <AuditInfoOnEmissionPart1 findOneData={findOneData} />
            <AuditMethoFactors findOneData={findOneData} />
            <AuditInfoOnEmissionPart2 findOneData={findOneData} />
            <AuditAdditionalInfo findOneData={findOneData} />
            <AuditInfoOffset findOneData={findOneData} />
          </div>

          {userDetails?.userType === "consultant" && (
            <form className="full_width">
              <div className="form-row bs_BtnRow">
                <div className=" cursorPointer">
                  <FormButton
                    buttonName={"Cancel & Exit"}
                    buttonText={"#1D1D1B"}
                    backgroundColor={"transparent"}
                    btnFont={"14px"}
                    btnPdd={"12px 20px"}
                  />
                </div>
                <div className="cancelBtn_Row">
                  <div className=" NextBtn cursorPointer">
                    <FormButton
                      buttonName={"Submit Declaration"}
                      backgroundColor={"#00637E"}
                      buttonText={"#ffffff"}
                      btnFont={"14px"}
                      btnPdd={"12px 20px"}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
          {userDetails?.userType === "auditor" && (
            <div className=" bottomSubmitRow">
              <div className="cursorPointer" onClick={handleCancelAndExit}>
                <FormButton
                  buttonName={"Cancel & Exit"}
                  border={"1px solid #008BB2"}
                  buttonText={"#008BB2"}
                  backgroundColor={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
              <div className="cancelBtn_Row">
                <ApproveDecButton
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  handleChange={handleEmissionSubmission}
                  declerationId={declerationId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default EmissionStep2;
