import react, { useState } from "react";
import { useFormik } from "formik";
import FormButton from "../../../components/button/FormButton";
import EmisGoodPractice from "../../../components/emissionComponents/emisGoodPractice/EmisGoodPractice";
import EmisChecklistSection from "../../../components/emissionComponents/emisChecklistSection/EmisChecklistSection";
import ApproveDecButton from "../../../components/button/approveButton/ApproveDecButton";
import * as Yup from "yup";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import QuarterTwo from "../../../components/emissionComponents/step6/quarterTwo/QuarterTwo";
import RatingCard from "../../../components/emissionComponents/step6/ratingCard/RatingCard";
import QuarterOne from "../../../components/emissionComponents/step6/quarterOne/QuarterOne";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import { SelectBox2 } from "../../../components/emissionComponents/step3/selectBox/SelectBox";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { cleanObject } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuarterlyReportSubmitMutation } from "../../../features/steps/quarterlyReportApiSlice";
import { useFormikToastError } from "../../../hooks/error";

function EmissionStep6() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({});

  const [
    quarterlyReportSubmit,
    {
      isLoading: quarterlyReportSubmitIsLoading,
      isSuccess: quarterlyReportSubmitIsSuccess,
      error: quarterlyReportSubmitIsError,
    },
  ] = useQuarterlyReportSubmitMutation();

  const fileValidationSchema = Yup.object()
    .shape({
      fileName: Yup.string()
        .trim()
        .min(5, "Atleast 5 characters")
        .max(500, "Atmost 500 characters")
        .required("Required"),
    })
    .required("Required");

  const explanatoryNotesValidationSchema = Yup.object()
    .shape({
      value: Yup.number()
        .min(0, "Minimum 0")
        .max(10000000, "Maximum 10000000")
        .required("Required")
        .typeError("Please enter a number"),
      explanatoryNotes: Yup.string()
        .trim()
        .min(10, "Atleat 10 character")
        .max(500, "Atmost 500 characters")
        .required("Required"),
    })
    .required("Required");

  const scopeValidationSchema = Yup.object()
    .shape({
      scope1: explanatoryNotesValidationSchema,
      scope2: explanatoryNotesValidationSchema,
      scope3: explanatoryNotesValidationSchema,
    })
    .required("Required");

  const offsetsAndCreditsValidationSchema = Yup.object()
    .shape({
      outsideTheValueChain: explanatoryNotesValidationSchema,
      netGHGEmission: explanatoryNotesValidationSchema,
      expectedGHGReductionPercentage: explanatoryNotesValidationSchema,
      achievedGHGReductionPercentage: explanatoryNotesValidationSchema,
      gapForSEERatingTarget: explanatoryNotesValidationSchema,
    })
    .required();

  const quaterlyReportsValidationSchema = Yup.object().shape({
    // declarationId: Yup.string().required("Required"),
    updatedNetZeroTarget: Yup.number()
      .oneOf([2030, 2034, 2038, 2042, 2046, 2050], "Invalid Quarter")
      .required("Required")
      .typeError("Please enter a number"),
    isTargetYearChanged: Yup.boolean().required("Target Year is required"),
    ghgEmissionsIncrease: scopeValidationSchema,
    ghgEmissionsReductions: scopeValidationSchema,
    removals: Yup.object().shape({
      withinBoundaries: explanatoryNotesValidationSchema,
      inTheWiderValueChain: explanatoryNotesValidationSchema,
      outsideTheValueChain: explanatoryNotesValidationSchema,
    }),
    offsetsAndCredits: offsetsAndCreditsValidationSchema,
    relatedDocuments: Yup.array()
      .of(fileValidationSchema)
      .min(1, "Atleast one document is required")
      .max(15, "Maximum 10 documents")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      declarationId: findOneData?.data?.declaration?._id,
      updatedNetZeroTarget: null,
      isTargetYearChanged: true,
      ghgEmissionsIncrease: {
        scope1: {
          value: null,
          explanatoryNotes: "",
        },
        scope2: {
          value: null,
          explanatoryNotes: "",
        },
        scope3: {
          value: null,
          explanatoryNotes: "",
        },
      },
      ghgEmissionsReductions: {
        scope1: {
          value: null,
          explanatoryNotes: "",
        },
        scope2: {
          value: null,
          explanatoryNotes: "",
        },
        scope3: {
          value: null,
          explanatoryNotes: "",
        },
      },
      removals: {
        withinBoundaries: {
          value: null,
          explanatoryNotes: "",
        },
        inTheWiderValueChain: {
          value: null,
          explanatoryNotes: "",
        },
        outsideTheValueChain: {
          value: null,
          explanatoryNotes: "",
        },
      },
      offsetsAndCredits: {
        outsideTheValueChain: {
          value: null,
          explanatoryNotes: "",
        },
        netGHGEmission: {
          value: null,
          explanatoryNotes: "",
        },
        expectedGHGReductionPercentage: {
          value: null,
          explanatoryNotes: "",
        },
        achievedGHGReductionPercentage: {
          value: null,
          explanatoryNotes: "",
        },
        gapForSEERatingTarget: {
          value: null,
          explanatoryNotes: "",
        },
      },
      relatedDocuments: [],
    },
    enableReinitialize: true,
    validationSchema: quaterlyReportsValidationSchema,
    onSubmit: (values) => {
      const data = structuredClone(values);
      cleanObject(data);
      quarterlyReportSubmit(data)
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Submitted Successfully` }));
          navigate("/dashboard");
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    },
  });

  const handleCancleAndExit = () => {
    navigate("/dashboard");
  };

  useFormikToastError(formik);

  console.log("FormikValuesStep6", formik?.values);
  console.log("FormikErrorsStep6", formik?.errors);

  return (
    <form className="emis6Section" noValidate onSubmit={formik.handleSubmit}>
      <div className="emis6TitleHeader">
        <div className="">
          <img src={EmissionDecLogo} alt="EmissionDecLogo" />
        </div>
        <div className="emis6LeftDiv">
          <div className="emis6CancelBtn">
            <FormButton
              type="button"
              buttonName={"Cancel & Exit"}
              buttonText={"#ffffff"}
              backgroundColor={"transparent"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
          <div className="emisSubmitBtn">
            <FormButton
              buttonName={"Submit for Auditing"}
              backgroundColor={"#00637E"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </div>
      </div>
      <div className="emis6InnerCon">
        <div className="emis6SecCol1">
          <div className="roadmapTitle">
            <h1>Monitoring & Reporting</h1>
            <div className="stepsNext">
              <p> Frequency: </p>
              <span> Quarterly </span>
            </div>
          </div>
          <RatingCard />
          <EmisChecklistSection />
          <EmisGoodPractice />
        </div>
        <div className="emis6SecCol2">
          <div className="net6YearRow">
            <div className="stepsCodewaveLogo">
              <img src={CodeLogo} />
              <h3>Codewave’s Roadmap To NetZero Emissions</h3>
            </div>
            <div className="step6NetZeroValue">
              <h1>2024</h1>
              <div className="zeroValueBorder"></div>
              <h1>Quarter 2</h1>
            </div>
          </div>

          <QuarterTwo formik={formik} />
          {/* <QuarterOne /> */}

          <div className="bottomSubmitRow">
            <div className="cursorPointer">
              <FormButton
                type="button"
                buttonName={"Cancel & Exit"}
                border={"1px solid #008BB2"}
                buttonText={"#008BB2"}
                backgroundColor={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="cancelBtn_Row">
              <div className="NextBtn cursorPointer">
                <FormButton
                  buttonName={"Submit for Auditing"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </form>
  );
}

export default EmissionStep6;
