import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import NewCommentsBtn from "../../../button/newCommentsBtn/NewCommentsBtn";
import EditButton from "../../../button/editButton/EditButton";

import "./style.css";
import GHGEmission from "../ghgEmission/GHGEmission";
import OffsetsAndCredits from "../offsetsAndCredits/OffsetsAndCredits";
import GHGEmissionPreview from "../ghgEmissionPreview/GHGEmissionPreview";
import OffsetsAndCreditsPrev from "../offsetsAndCreditsPrev/OffsetsAndCreditsPrev";


function QuarterTwoPreview( ) {
  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>
            <div className="GenrlInfo">
            <h3>2024  |  Quarter 2</h3>
            </div>
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className='quarterTwoCon'>
                <GHGEmissionPreview 
                    resultHeaderName={"GHG Emissions Increases"}
                    resultTitle1={"Scope 1"}
                    resultTitle2={"Scope 2"}
                    resultTitle3={"Scope 3"}
                />
                <GHGEmissionPreview 
                     resultHeaderName={"GHG Emissions Reductions"}
                     resultTitle1={"Scope 1"}
                     resultTitle2={"Scope 2"}
                     resultTitle3={"Scope 3"}
                />
                <GHGEmissionPreview 
                    resultHeaderName={"Removals"}
                    resultTitle1={"Within Its Boundaries"}
                    resultTitle2={"In The Wider Value Chain"}
                    resultTitle3={"Outside The Value Chain"}
                />
                <OffsetsAndCreditsPrev
                    resultHeaderName={"Offsets and credits outside the value chain"}
                 />
                <OffsetsAndCreditsPrev
                   resultHeaderName={"Net GHG Emissions"}
                 />
                <OffsetsAndCreditsPrev
                   resultHeaderName={"Expected GHG Reduction Percentage"}
                 />
                <OffsetsAndCreditsPrev
                    resultHeaderName={"Achieved GHG Reduction Percentage"}
                 />
                 <OffsetsAndCreditsPrev
                    resultHeaderName={"Gap for SEE Rating Target"}
                    gapForSee={true}
                 />
           
            </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default QuarterTwoPreview;
