import react, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import ChatBoxHeader from "../chatBoxComponents/commonComponents/chatBoxHeader/ChatBoxHeader";
import ChatBoxQuestionComponent from "../chatBoxComponents/commonComponents/chatBoxQuestionComponent/ChatBoxQuestionComponent";
import RecieverChatBox from "../chatBoxComponents/recieverChatBox/RecieverChatBox";
import PdfDocuments from "../../pdfDocuments/PdfDocuments";
import PdfUpload from "../../pdfUpload/PdfUpload";
import ChatBoxFooter from "../chatBoxComponents/commonComponents/chatBoxFooter/ChatBoxFooter";
import ChatBoxFooter2 from "../chatBoxComponents/commonComponents/chatBoxFooter/ChatBoxFooter2";

import "./chatBoxLayout.css";
import MessageEditChatBox from "../chatBoxComponents/messageEditChatBox/MessageEditChatBox";
import SenderChatBox from "../chatBoxComponents/commonComponents/senderChatBox.js/SenderChatBox";
import { getUserFromLocal } from "../../../utils/storage";
import ChangeIssueType from "../chatBoxComponents/commonComponents/chatBoxQuestionComponent/IssueStatusChange";
import IssueStatusChange from "../chatBoxComponents/commonComponents/chatBoxQuestionComponent/IssueStatusChange";

function ChatBoxLayout({
  isOpen,
  onClose,
  secName,
  question,
  answer,
  mapping,
  issueId,
  declarationId,
  data,
  chats,
  arrayId,
  type,
  setType,
  summary,
  setSummary,
  description,
  setDescription,
  handleCreateIssue,
  chatIsLoading,
  chatIsFetching,
  issueIsLoading,
  issueIsFetching,
}) {
  const userDetails = getUserFromLocal();
  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  const [state, setState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  console.log(
    "jhjgfdfzsxgcvhbjnkmjkhgfgdfzsddxfcgvhbjn",
    data?.data?.data?.summary
  );

  console.log("fefef32f32f32f32", data?.data?.data);

  const reversedChats = chats?.data?.data?.slice().reverse();
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="commentDrawContainer">
        <ChatBoxHeader handleClose={handleClose} />
        <div className="chatBoxInnerSec">
          <ChatBoxQuestionComponent
            secName={secName}
            question={question}
            answer={answer}
          />
          <div className="cmntChatSection">
            {issueId && userDetails?.userType === "auditor" ? (
              <RecieverChatBox
                reciever={false}
                summaryText={data?.data?.data?.summary}
                text={data?.data?.data?.description}
                isSummary={true}
                item={data?.data?.data}
              />
            ) : issueId && userDetails?.userType === "consultant" ? (
              <RecieverChatBox
                reciever={true}
                summaryText={data?.data?.data?.summary}
                text={data?.data?.data?.description}
                isSummary={true}
                item={data?.data?.data}
              />
            ) : null}
            {reversedChats?.map((item, index) =>
              item?.type === "updateDeclaration" ? (
                <MessageEditChatBox
                  item={item}
                  index={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                />
              ) : item?.type === "issueTypeChange" ? (
                <IssueStatusChange
                  item={item}
                  index={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                />
              ) : item?.type === "issueStatusChange" ? (
                <IssueStatusChange
                  item={item}
                  index={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                />
              ) : (
                <RecieverChatBox
                  key={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                  summaryText={""}
                  text={item?.message}
                  isSummary={false}
                  item={item}
                />
              )
            )}
          </div>
        </div>
        {userDetails?.userType === "consultant" ? (
          <ChatBoxFooter2
            mapping={mapping}
            declarationId={declarationId}
            issueId={issueId}
            arrayId={arrayId}
            description={description}
            setDescription={setDescription}
            handleCreateIssue={handleCreateIssue}
          />
        ) : (
          <ChatBoxFooter
            mapping={mapping}
            declarationId={declarationId}
            issueId={issueId}
            arrayId={arrayId}
            type={type}
            setType={setType}
            summary={summary}
            setSummary={setSummary}
            description={description}
            setDescription={setDescription}
            handleCreateIssue={handleCreateIssue}
          />
        )}
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={onClose}
            className="cmntDrawer"
            ModalProps={{ onBackdropClick: handleClose }}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}

export default ChatBoxLayout;
