import React from 'react';
import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';

import "./inventBound.css";

function InventoryBoundaries({AddOffsetTitle}) {
    
  return (
    <div className='InveBoundariesCon'>
        <h1>{AddOffsetTitle}s</h1>
        <div className='invBoundInnerCon'>
            <div className='emissOffset1row'>
                <div className='emissOffset1Col1'>
                    <h1>Offset 1</h1>
                </div>
                <div className='emissOffset1Col2'>
                    <ViewCommentsBtn
                    secName={"Information on Offsets"}
                    question={"Offset 1"} 
                    answer={"Value Here, Value , Yes"} 
                    />
                </div>
            </div>
            <div className='invBoundRow'>
                <div className='invBoundCol1'>
                    <h1>Quantity of GHGs (mtCO2e)</h1>
                </div>
                <div className='invBoundCol2'>
                    <p>Value Here</p>
                </div>
            </div>
            <div className='invBoundRow'>
                <div className='invBoundCol1'>
                    <h1>Type of offset project</h1>
                </div>
                <div className='invBoundCol2'>
                    <p>Value Here</p>
                </div>
            </div>
            <div className='invBoundRow'>
                <div className='invBoundCol1'>
                    <h1>Were the offsets verified/certified and/or approved by an external GHG program (e.g., CDM)</h1>
                </div>
                <div className='invBoundCol2'>
                    <p>Yes</p>
                </div>
            </div>

        </div>

    </div>
  )
}

export default InventoryBoundaries