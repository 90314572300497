import React, { useState } from "react";

import ChatCommentInputBox from "../../../../InputBox/chatCommentInputBox/ChatCommentInputBox";
import ChatBoxSubmitBtn from "../../../../button/chatBoxSubmitBtn/ChatBoxSubmitBtn";
import SummarizeIssueInput from "../../../../InputBox/summarizeIssueInput/SummarizeIssueInput";
import RecommendedOptions from "../recommendedOptions/RecommendedOptions";

import "./style.css";

import ThreeDotsIcon from "../../../../../assets/icons/threeDotsIcon.svg";
import { useCreateIssueMutation } from "../../../../../features/steps/emissionApiSlice";
import { useAddChatMutation } from "../../../../../features/steps/declerationApiSlice";

function ChatBoxFooter2({
  mapping,
  declarationId,
  issueId,
  description,
  setDescription,
  handleCreateIssue,
}) {
  return (
    <footer className="chatBoxFootContainer">
      <div className="chatBoxSubmitBtnRow">
        {/* <div className="cursorPointer">
          <img src={ThreeDotsIcon} alt="ThreeDotsIcon" />
        </div> */}
        <ChatCommentInputBox
          description={description}
          setDescription={setDescription}
        />
        <ChatBoxSubmitBtn handleCreateIssue={handleCreateIssue} />
      </div>
    </footer>
  );
}

export default ChatBoxFooter2;
