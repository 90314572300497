import react from "react";
import { useNavigate } from "react-router-dom";

import './style.css';

import AdminLogo from "../../../assets/logo/adminDashLogo.svg";
import MenuIcon from "../../../assets/icons/adminDashMenuIcon.svg";
import ArrowRight from "../../../assets/icons/activeMenuArrow.svg";
// InactiveIcons
import InDashIcon from "../../../assets/icons/InactDashIcon.svg";
import IntLeafIcon from "../../../assets/icons/inactLeaf.svg";
import InUserManage from "../../../assets/icons/userManagement.svg";
import InRoleManage from "../../../assets/icons/InRoleManage.svg";
import InFeedback from "../../../assets/icons/inFeedbackIcon.svg";
import InHelpSupport from "../../../assets/icons/inHelpSupport.svg";

// Active Icons
import DashboardIcon from "../../../assets/icons/adminTitleMenuIcon.svg";
import LeafIcon from "../../../assets/icons/Leaf.svg";
import UserManage from "../../../assets/icons/Users_Group.svg";
import RoleManage from "../../../assets/icons/roleManageIcon.svg";
import FeedBackIcon from "../../../assets/icons/feedbackIcon.svg";
import HelpAndSupport from "../../../assets/icons/helpAndSupport.svg";


function AdminSideMenu({
    progressDashboardMenu,
    orgsRatingMenu,
    feedbackMenu,
    roleMangeMenu,
}) {
    const navigate = useNavigate();

    const progressDashboardFun = () =>{
        navigate("/adminProgressDashboard")
    }
    const orgsRatingFun = () =>{
        navigate("/adminOrgsRating")
    }
    const adminDashFun = () =>{
        navigate("/adminDashboard")
    }
    const admFeedbackFun = () =>{
        navigate("/AdminFeedback")
    }
    const roleManageFun = () =>{
        navigate("/adminRoleManage")
    }


  return (
    <div className="sideMenuContainer">
     <div className='adminLogoSec'>
        <img src={AdminLogo} alt="adminLogo"/>
        <img src={MenuIcon} alt="menuIcon"/>
     </div>

     <div className="menuTitleContainer">

        <div className="sideMenuCol">
            <div className="menuTitles" onClick={progressDashboardFun}>
                <div className="adminMenuTitle">
                    <img src={DashboardIcon} alt="titleIcon" className="dashImag" style={{filter: progressDashboardMenu === "progressDashboardMenu" ? "" : "contrast(0.5)"}}/>
                    <h1 className={progressDashboardMenu === "progressDashboardMenu" ? "admMenuActive" : "admMenuInactive"}>Dashboard</h1>
                </div>
                <div>
                    <img src={ArrowRight} alt="ArrowRight"/>
                </div>
            </div>
            <div className="menuTitles" onClick={orgsRatingFun}>
                <div className="adminMenuTitle">
                    <img src={LeafIcon} alt="LeafIcon" style={{filter: orgsRatingMenu === "orgsRatingMenu" ? "" : "contrast(0.5)"}} />
                    <h1 className={orgsRatingMenu === "orgsRatingMenu" ? "admMenuActive" : "admMenuInactive"}>Orgs & Ratings</h1>
                </div>
                <div>
                    <img src={ArrowRight} alt="ArrowRight"/>
                </div>
            </div>
        </div>

        <div className='menuHorizontalLine'></div>
        
        <div className="sideMenuCol">
            <h3>Admin Actions</h3>
            <div className="menuTitles" 
                // onClick={adminDashFun}
            >
                <div className="adminMenuTitle">
                    <img src={UserManage} alt="titleIcon"/>
                    <h1 className="admMenuInactive">User Management</h1>
                </div>
                <div>
                    <img src={ArrowRight} alt="ArrowRight"/>
                </div>
            </div>
            <div className="menuTitles" onClick={roleManageFun}>
                <div className="adminMenuTitle">
                    <img src={RoleManage} alt="titleIcon" className="roleMImg" style={{filter: roleMangeMenu === "roleMangeMenu" ? "" : "contrast(0.5)"}}/>
                    <h1 className={roleMangeMenu === "roleMangeMenu" ? "admMenuActive" : "admMenuInactive"}>Role Management</h1>
                </div>
                <div>
                    <img src={ArrowRight} alt="ArrowRight"/>
                </div>
            </div>
        </div>

        <div className="sideMenuCol">
            <h3>Other</h3>
            <div className="menuTitles" onClick={admFeedbackFun}>
                <div className="adminMenuTitle">
                    <img src={FeedBackIcon} alt="titleIcon" className="feedBackImg" style={{filter: feedbackMenu === "feedbackMenu" ? "" : "contrast(0.5)"}}/>
                    <h1 className={feedbackMenu === "feedbackMenu" ? "admMenuActive" : "admMenuInactive"}>Feedbacks</h1>
                </div>
                <div>
                    <img src={ArrowRight} alt="ArrowRight"/>
                </div>
            </div>
            <div className="menuTitles">
                <div className="adminMenuTitle">
                    <img src={HelpAndSupport} alt="titleIcon" className="feedBackImg"/>
                    <h1 className="admMenuInactive">Help & Support</h1>
                </div>
                <div>
                    <img src={ArrowRight} alt="ArrowRight"/>
                </div>
            </div>
        </div>


     </div>
    </div>
  );
}

export default AdminSideMenu;