import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentMedical} from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import ViewCommentsBtn from '../../viewComments/viewCommentsBtn/ViewCommentsBtn';
import PdfUpload from '../../../pdfUpload/PdfUpload';
import NewCommentsBtn from '../../../button/newCommentsBtn/NewCommentsBtn';
import EditButton from '../../../button/editButton/EditButton';


function OrgBoundariesView({formik}) {

  return (
    <div className="emisSectionGap">
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <div className='typographyRow'>
            <Typography>
            <div className='GenrlInfo'>
                <h3>ORGANIZATIONAL BOUNDARIES</h3>
            </div>
            </Typography>
            <div className='editBtnRow'>
                <NewCommentsBtn btnTitle={"2 New Comments"} />
                <EditButton />
            </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='orgBoundries'>
            <div className="orgBounInfoSec">
              <div className='whichConsiText'>
                <h2>
                  Which consolidation approach was chosen (check each
                  consolidation approach for which your company is reporting
                  emissions.) If your company is reporting according to more
                  than one consolidation approach, please complete and attach an
                  additional completed reporting template that provides your
                  company’s emissions data following the other consolidation
                  approach(es).
                </h2>
              </div>
              <div>
                <p className='orgEquityShare'>Equity Share, Financial Control, Operational Control</p>
              </div>
                <div className='viewCmtRow'>
                    <ViewCommentsBtn 
                        secName={"Organizational Boundaries"} 
                        question={"Which consolidation approach was chosen (check each consolidation approach for which your company is reporting emissions.) If your company is reporting according to more than one consolidation approach, please complete and attach an additional completed reporting template that provides your company’s emissions data following the other consolidation approach(es)."} 
                        answer={"Equity Share, Financial Control, Operational Control"} 
                    />
                </div>
            </div>

            <div className='orgListDownSec'>
                <h1>
                    List down all legal entities or facilities over which reporting 
                    company has equity share, financial control or operational control
                </h1>

                <div className='orgcontrolSec'>
                    <h2>Equity Share, Financial & Operation Control Information</h2>
                    <div className='orgFacilityRow'>
                        <div className='orgFacilityCol1'>
                            <h1>Legal Entity or Facility</h1>
                        </div>
                        <div className='orgFacilityCol2'>
                            <p>Name Here</p>
                        </div>
                    </div>
                    <div className='orgPerInSec'>
                        <div className='orgPerInCol1'>
                            <div className='perCol1'>
                                <h1>Percentage equity share in legal entity</h1>
                            </div>
                            <div className='perCol2'>
                                <p>100%</p>
                            </div>
                        </div>
                        <div className='orgPerInCol2'>
                            <div className='perCol1'>
                                <h1>Does reporting company have financial control?</h1>
                            </div>
                            <div className='perCol2'>
                                <p>Yes</p>
                            </div>
                        </div>
                    </div>
                    <div className='orgFacilityRow'>
                        <div className='orgFacilityCol1'>
                            <h1>Does reporting company have operational control?</h1>
                        </div>
                        <div className='orgFacilityCol2'>
                            <p>Yes</p>
                        </div>
                    </div>
                </div>
                <div className='viewCmtRow'>
                    <ViewCommentsBtn 
                        secName={"Organizational Boundaries"} 
                        question={"List down all legal entities or facilities over which reporting company has equity share, financial control or operational control"} 
                        answer={"Name Here, 100%, yes, yes"} 
                    />
                </div>
            </div>
            <div className='repoCmpnySec'>
                <div>
                    <h1>
                        If the reporting company’s parent company does not report emissions, 
                        include an organizational diagram that clearly defines relationship of 
                        the reporting subsidiary as well as other subsidiaries
                    </h1>
                </div>
                <div>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
                <div>
                <div className='viewCmtRow'>
                    <ViewCommentsBtn 
                        secName={"Organizational Boundaries"} 
                        question={" If the reporting company’s parent company does not report emissions, include an organizational diagram that clearly defines relationship of the reporting subsidiary as well as other subsidiaries"} 
                        answer={"pdf File"} 
                    />
                </div>
                </div>
            </div>

        </div>
      </AccordionDetails>
    </Accordion>
   </div>
  )
}

export default OrgBoundariesView;