import React from "react";

import "./style.css";
import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import EmissionComments from "../../../chats/EmissionComments";

function ScopEmissonTable({
  scopeTitle,
  mapping,
  issueId,
  declarationId,
  secName,
  question,
  answer,
}) {
  const ghgGasData = [
    {
      index: "1",
      name: "CO2 (mt)",
      value: "123445",
    },
    {
      index: "2",
      name: "CH2 (mt)",
      value: "123445",
    },
    {
      index: "3",
      name: "N2O(mt)",
      value: "123445",
    },
    {
      index: "4",
      name: "HFCs (mt)",
      value: "123445",
    },
    {
      index: "5",
      name: "PFCs (mt)",
      value: "123445",
    },
    {
      index: "6",
      name: "SF6 (mt)",
      value: "123445",
    },
  ];

  return (
    <div className="infoScopOneSec">
      <div className="scope1Row">
        <h1 className="emScopePart1Ques">{scopeTitle}</h1>
        <div className="addCmtRow">
          <EmissionComments
            className="addCmtRow"
            mapping={mapping}
            issueId={issueId}
            declarationId={declarationId}
            secName={secName}
            question={question}
            answer={answer}
          />
        </div>
      </div>
      <div className="info1GasRow">
        <div className="info1GasCol1">
          {ghgGasData?.map((item, index) => (
            <div className="info1GasData">
              <div>
                <h1>{item.name}</h1>
                <p>{item.value}</p>
              </div>
              <div>
                <h1>Reason</h1>
                <p>Lorem ipsum dolor consequat at proin tincidunt.</p>
              </div>
            </div>
          ))}
        </div>
        <div className="scopeRightBorder"></div>
        <div className="info1GasCol2">
          <h1>Total (mtCO2e)</h1>
          <p>123414323</p>
        </div>
      </div>
    </div>
  );
}

export default ScopEmissonTable;
